import { Stack } from '@mui/material';
import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/MoreResources.svg';
import { Link } from './Link';

export function MoreResources(): JSX.Element {
  return (
    <ResourceGroup titleId="content.resources.more_resources.title" Icon={Icon}>
      <Stack paddingLeft={4}>
        <Link strings="content.resources.more_resources.sites.parkinson_canada" />
        <Link strings="content.resources.more_resources.sites.parkinson_foundation" />
      </Stack>
    </ResourceGroup>
  );
}
