import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  Stack,
  Typography,
  SvgIcon,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  CardActions,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CareTeamMember, formatPhoneNumber } from 'ecarepd-shared-utilities';
import { PropsWithRef, useState } from 'react';
import TextsmsIcon from '@mui/icons-material/Textsms';
import _ from 'lodash';
import { CareTeamDialog, CareTeamDialogMode } from './CareTeamDialog';

interface CardItemProps {
  schema: string;
  value?: string;
  Icon: typeof SvgIcon;
  formatter?: (value: string) => string;
}

function CardItem({
  schema,
  value,
  Icon,
  formatter = _.identity,
}: PropsWithRef<CardItemProps>): JSX.Element | null {
  if (!value) {
    return null;
  }

  return (
    <a href={`${schema}${value}`}>
      <Stack direction="row" spacing={2}>
        <Icon fontSize="small" color="secondary" />
        <Typography variant="body2">{formatter(value)}</Typography>
      </Stack>
    </a>
  );
}

export interface CareTeamMemberCardProps {
  careTeamMember: CareTeamMember;
  actions?: JSX.Element;
}

export function CareTeamMemberCard({
  careTeamMember,
  actions,
}: PropsWithRef<CareTeamMemberCardProps>): JSX.Element {
  const [showEditDialog, setShowEditDialog] = useState(false);

  return (
    <>
      <CareTeamDialog
        mode={CareTeamDialogMode.Edit}
        careTeamMember={careTeamMember}
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        careTeamMemberType={careTeamMember.properties.careTeamMemberType}
      />

      <Card variant="elevation" className="care-team-card">
        <CardHeader
          title={careTeamMember.properties.name}
          subheader={careTeamMember.properties.jobTitle}
          action={
            <IconButton onClick={() => setShowEditDialog(true)}>
              <EditIcon />
            </IconButton>
          }
        />

        <CardContent>
          {careTeamMember.properties.location && (
            <Typography variant="subtitle1">
              {careTeamMember.properties.location}
            </Typography>
          )}

          <CardItem
            schema="mailto:"
            value={careTeamMember.properties.email}
            Icon={AlternateEmailIcon}
          />

          <CardItem
            schema="tel:"
            value={careTeamMember.properties.callNumber}
            Icon={PhoneIcon}
            formatter={formatPhoneNumber}
          />

          <CardItem
            schema="sms:"
            value={careTeamMember.properties.messagingNumber}
            Icon={TextsmsIcon}
            formatter={formatPhoneNumber}
          />
        </CardContent>

        <CardActions>{actions}</CardActions>
      </Card>
    </>
  );
}
