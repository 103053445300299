var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useMemo, useState } from 'react';
import { useConnection, useInstancesByClassName, useInstancesByQuery, } from 'hive-react-utils';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { useSymptomPrioritiesById } from './useSymptomPrioritiesById';
export function useMyPatientPriorities(patient) {
    const connection = useConnection();
    // A patient will have only one instance, so get all instances and return the firsst
    const rawPatientPriorities = useInstancesByClassName('ecarepd', 'patientPriority', {});
    const patientPriorities = useMemo(() => _.first(rawPatientPriorities), [rawPatientPriorities]);
    // Get the single latest updated priority response from this patient. This will
    // tell us whether we need to pull the information again or not
    const latestResponseArray = useInstancesByQuery('ecarepd', 'getLatestModifiedPatientPriorityResponses', 'patientPriorityResponse', {}, (patient === null || patient === void 0 ? void 0 : patient.properties.patientID) || '', 1);
    const latestResponse = useMemo(() => _.first(latestResponseArray), [latestResponseArray]);
    // Fetch the latest responses for each of the user's selected priorities.
    const [responsesById, setResponsesById] = useState({});
    useEffect(() => {
        const fn = () => __awaiter(this, void 0, void 0, function* () {
            if (!connection || !connection.bee || !patientPriorities) {
                return;
            }
            const responses = {};
            for (const priorityID of patientPriorities.properties.priorityIDs) {
                // If we already have the latest one in latestResponse, use it
                if ((latestResponse === null || latestResponse === void 0 ? void 0 : latestResponse.properties.priorityID) === priorityID) {
                    responses[priorityID] = latestResponse;
                }
                else {
                    const latest = (yield connection.bee.storage.executeQuery('ecarepd', 'getPatientPriorityResponseByPriorityIDAndPatientID', [priorityID, patientPriorities.properties.patientID, 1]));
                    if (latest && latest.length > 0) {
                        responses[priorityID] = latest[0];
                    }
                }
            }
            setResponsesById(responses);
        });
        fn().catch(console.error);
    }, [connection, latestResponse, patientPriorities]);
    const symptomPrioritiesById = useSymptomPrioritiesById();
    // Collate the symptoms and responses into the returned objects
    return useMemo(() => {
        if (!patientPriorities) {
            return patientPriorities;
        }
        const symptoms = _fp.flow([
            _fp.pick(patientPriorities.properties.priorityIDs),
            _fp.mapValues((p) => (Object.assign(Object.assign({}, p), { properties: Object.assign(Object.assign({}, p.properties), { latestResponse: _.get(responsesById, p.id) }) }))),
        ])(symptomPrioritiesById);
        return Object.assign(Object.assign({}, patientPriorities), { properties: Object.assign(Object.assign({}, patientPriorities.properties), { priorities: symptoms }) });
    }, [patientPriorities, responsesById, symptomPrioritiesById]);
}
