import { ComponentsOverrides } from '@mui/material';
import { spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiList'] = {
  root: {
    '.patient-symptom-details-content &': {
      padding: 0,
    },
  },
};

export const MuiList = {
  styleOverrides,
};

const itemStyleOverrides: ComponentsOverrides['MuiListItem'] = {
  root: {
    '.patient-symptom-details-content &': {
      margin: 0,
      paddingTop: `${spacing}px`,
      // padding: 0,
      paddingBottom: `${spacing * 4}px`,
    },
  },
};

export const MuiListItem = {
  styleOverrides: itemStyleOverrides,
};
