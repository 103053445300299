import { Stack, FormControlLabel, Switch, TextField } from '@mui/material';
import { useCallback, PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PatternFormat } from 'react-number-format';
import TabPanel from './TabPanel';
import InputField from './InputField';
import { ReactComponent as About } from '../../assets/FTE3.svg';

export interface Tab3Props {
  tabValue: number;
  hasErrors: boolean;
  firstname: string;
  setFirstname: (v: string) => void;
  lastname: string;
  setLastname: (v: string) => void;
  phone: string;
  setPhone: (v: string) => void;
  allowContact: boolean;
  setAllowContact: (v: boolean) => void;
}

export default function Tab3({
  tabValue,
  hasErrors,
  firstname,
  setFirstname,
  lastname,
  setLastname,
  phone,
  setPhone,
  allowContact,
  setAllowContact,
}: PropsWithRef<Tab3Props>): JSX.Element {
  const { t } = useTranslation();

  return (
    <TabPanel value={tabValue} index={2}>
      <Stack display="flex" flexDirection="row">
        <Stack
          flexDirection="column"
          flexBasis={0}
          flexGrow={2}
          justifyContent="space-evenly"
          paddingTop={10}
        >
          <InputField
            autoComplete="given-name"
            placeholder="content.tutorial.first_name_placeholder"
            label="content.tutorial.first_name"
            required
            name="firstname"
            error={hasErrors && !firstname}
            setValue={setFirstname}
            value={firstname}
          />
          <InputField
            autoComplete="family-name"
            setValue={setLastname}
            value={lastname}
            name="lastname"
            required
            error={hasErrors && !lastname}
            placeholder="content.tutorial.last_name_placeholder"
            label="content.tutorial.last_name"
          />
          <PatternFormat
            onValueChange={(values) => {
              setPhone(values.value);
            }}
            customInput={TextField}
            type="tel"
            mask=" "
            name="telephone"
            format="(###) ###-####"
            valueIsNumericString
            required
            error={hasErrors && !phone}
            label={t('content.tutorial.telephone')}
            placeholder={t('content.tutorial.telephone_placeholder')}
            value={phone}
          />
          <FormControlLabel
            control={
              <Switch
                checked={allowContact}
                onChange={useCallback(
                  () => setAllowContact(!allowContact),
                  [setAllowContact, allowContact]
                )}
              />
            }
            label={t('content.tutorial.allow_contact')}
          />
        </Stack>
        <About />
      </Stack>
    </TabPanel>
  );
}
