import { ComponentsOverrides } from '@mui/material';

const styleOverrides: ComponentsOverrides['MuiTableCell'] = {
  root: {
    '.highlighted&': {
      backgroundColor: 'pink',
    },
  },
};

export const MuiTableCell = {
  styleOverrides: styleOverrides,
};
