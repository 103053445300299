import { Fragment, PropsWithRef } from 'react';
import _ from 'lodash';
import { Stack, Typography, Card, Button, Link, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLOURS } from 'ecarepd-shared-utilities';
import { ReactComponent as HomeLocationIcon } from '../../assets/location_icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/Location.svg';
import MapItem from './MapItem';

export interface CareFinderSearchResultCardProps {
  location: any;
  index: string;
}

function CareFinderSearchResultCard({
  location,
  index,
}: PropsWithRef<CareFinderSearchResultCardProps>): JSX.Element {
  const { t } = useTranslation();
  const isHomeService = _.isEmpty(location.properties.coords);

  return (
    <Card className="care-finder-result" key={index}>
      <Stack margin={2} marginBottom={2} flexDirection="row">
        {isHomeService ? (
          <HomeLocationIcon height={40} width={40} />
        ) : (
          <Stack position="relative" marginLeft={5} marginRight={3}>
            <MapItem index={index} />
          </Stack>
        )}
        <Typography
          variant="h6"
          fontWeight="bold"
          color="primary"
          marginLeft={4}
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {location.properties?.facility}
          {isHomeService && (
            <Box fontSize="small" color="primary">
              <i>{t('content.care_finder.in_home_service')}</i>
            </Box>
          )}
        </Typography>
      </Stack>
      <Stack
        marginBottom={2}
        marginTop={2}
        marginLeft={15}
        display="flex"
        alignItems="center"
        flexDirection="row"
      >
        <LocationIcon width={20} />
        <Typography paddingLeft={1}>{location.properties?.address}</Typography>
      </Stack>
      <Typography marginBottom={2} marginTop={2} marginLeft={20}>
        {location.properties?.phoneNumber.replaceAll(';', '\n')}
      </Typography>
      {location.properties?.websiteUrl && (
        <Link
          marginLeft={20}
          target="_blank"
          href={location.properties?.websiteUrl}
        >
          {location.properties?.websiteUrl}
        </Link>
      )}

      <Typography
        marginTop={4}
        paddingTop={4}
        marginLeft={15}
        borderTop={`1px solid ${COLOURS.Divider}`}
        fontStyle="italic"
      >
        {t('content.care_finder.services_offered')}
      </Typography>
      {_.map(location.properties?.serviceType, (service) => (
        <Typography
          marginBottom={2}
          marginTop={2}
          marginLeft={20}
          key={service}
        >
          &#8226; {_.startCase(_.toLower(service))}
        </Typography>
      ))}
    </Card>
  );
}

export interface CareFinderSearchResultsProps {
  sortedLocations: any; //TODO: set proper type
  selectedServices: {};
  onClick: () => void;
  allSelected: boolean;
  address: string;
  distance: number;
  refs: any[];
}

export default function CareFinderSearchResults({
  sortedLocations,
  onClick,
  selectedServices,
  allSelected,
  distance,
  address,
  refs,
}: PropsWithRef<CareFinderSearchResultsProps>): JSX.Element {
  const { t } = useTranslation();
  const resultCount = _.size(sortedLocations);

  return (
    <Stack flexBasis="100%" height="100%" maxWidth="40%" marginRight={8}>
      <Stack margin={2} overflow="hidden">
        <Typography marginTop={2} variant="h5">
          {t('content.care_finder.search_results_title')}
        </Typography>
        <Stack
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
          padding={5}
        >
          {address && (
            <Fragment>
              <Card className="care-finder-service">
                <Typography padding={2}>
                  <b>{address}</b>
                </Typography>
              </Card>
              <Card className="care-finder-service">
                <Typography padding={2}>
                  <b>{`<= ${t('content.care_finder.km_display', {
                    value: distance,
                  })}`}</b>
                </Typography>
              </Card>
            </Fragment>
          )}
          {allSelected ? (
            <Card className="care-finder-service">
              <Typography padding={2}>
                <b>{t('content.care_finder.all_selected')}</b>
              </Typography>
            </Card>
          ) : (
            _.map(selectedServices, (service) => (
              <Card key={service} className="care-finder-service">
                <Typography padding={2}>
                  <b>{_.startCase(_.toLower(service))}</b>
                </Typography>
              </Card>
            ))
          )}
        </Stack>

        <Stack flexDirection="row" marginBottom={5} alignItems="center">
          <Button onClick={onClick} variant="outlined">
            {t('content.care_finder.modify')}
          </Button>
          <Typography marginLeft={5} fontStyle="italic">
            {t('content.care_finder.result_count', { resultCount })}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        flexDirection="column"
        height="80vh"
        className="care-finder-results"
      >
        {_.map(sortedLocations, (location, index) => (
          <Stack key={index} ref={_.get(refs, index)}>
            <CareFinderSearchResultCard location={location} index={index} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
