import { Button, CircularProgress, Stack } from '@mui/material';
import {
  Patient,
  SondeService,
  useFeatureFlags,
} from 'ecarepd-shared-utilities';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import { useAudioRecorder } from 'react-audio-voice-recorder';

import StopIcon from '@mui/icons-material/MicOff';
import StartIcon from '@mui/icons-material/Mic';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsService,
  Operation,
  OperationEvents,
} from 'hive-analytics-react';
import { useCurrentLanguage } from 'hive-react-utils';

const OPERATION_RECORD: OperationEvents = {
  start: 'RECORDING_AUDIO_JOURNAL_START',
  end: 'RECORDING_AUDIO_JOURNAL_END',
  error: 'RECORDING_AUDIO_JOURNAL_ERROR',
};

const OPERATION_ADDING: OperationEvents = {
  start: 'ADDING_AUDIO_JOURNAL',
  end: 'ADDED_AUDIO_JOURNAL',
  error: 'AUDIO_JOURNAL_ERROR',
};

export interface AudioJournalProps {
  patient?: Patient;
}

function AudioJournalInternal({ patient }: AudioJournalProps): JSX.Element {
  const { t } = useTranslation();
  const {
    startRecording,
    stopRecording,
    isRecording,
    recordingBlob,
    recordingTime,
  } = useAudioRecorder();

  const [cancelled, setCancelled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const language = useCurrentLanguage();

  const onRecordingComplete = useCallback(
    async (p: Patient, blob: Blob) => {
      const op = AnalyticsService.startOperation(
        OPERATION_ADDING,
        await SondeService.getBlobMetadata(blob)
      );

      setProcessing(true);
      SondeService.uploadFile(p, blob, language)
        .then(async (convertedBlob: Blob) =>
          op.end(await SondeService.getBlobMetadata(convertedBlob))
        )
        .catch((error) => op.error(error))
        .finally(() => setProcessing(false));
    },
    [language]
  );

  const previousRecordingBlob = useRef<Blob | undefined>(recordingBlob);
  useEffect(() => {
    if (
      processing ||
      isRecording ||
      !patient ||
      !recordingBlob ||
      previousRecordingBlob.current === recordingBlob
    ) {
      return;
    }

    previousRecordingBlob.current = recordingBlob;
    if (cancelled) {
      return;
    }

    onRecordingComplete(patient, recordingBlob);
  }, [
    processing,
    isRecording,
    cancelled,
    patient,
    recordingBlob,
    previousRecordingBlob,
    onRecordingComplete,
  ]);

  const time = useMemo(
    () => moment.utc(recordingTime * 1000).format('mm:ss'),
    [recordingTime]
  );

  const recordingOp = useRef<Operation | undefined>();

  const onStart = useCallback(() => {
    setCancelled(false);
    startRecording();
    recordingOp.current = AnalyticsService.startOperation(OPERATION_RECORD);
  }, [startRecording]);

  const onStop = useCallback(() => {
    setCancelled(false);
    stopRecording();
    recordingOp.current?.end({
      cancelled: false,
    });
    recordingOp.current = undefined;
  }, [stopRecording]);

  const onCancel = useCallback(() => {
    setCancelled(true);
    stopRecording();
    recordingOp.current?.end({
      cancelled: true,
    });
    recordingOp.current = undefined;
  }, [stopRecording]);

  return (
    <>
      {processing && (
        <Button
          variant="outlined"
          disabled={true}
          onClick={stopRecording}
          startIcon={<CircularProgress size="20px" color="inherit" />}
        >
          {t('home.journal.audio.processing')}
        </Button>
      )}

      {isRecording && !processing && (
        <Stack>
          <Button variant="outlined" onClick={onStop} startIcon={<StopIcon />}>
            {time}
          </Button>

          <Button variant="text" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
        </Stack>
      )}

      {!isRecording && !processing && (
        <Button variant="outlined" onClick={onStart} startIcon={<StartIcon />}>
          {t('home.journal.audio.record')}
        </Button>
      )}
    </>
  );
}

export function AudioJournal({ patient }: AudioJournalProps): JSX.Element {
  const featureFlags = useFeatureFlags();

  if (!featureFlags.SONDE) {
    return <></>;
  }

  return <AudioJournalInternal patient={patient} />;
}
