import _ from 'lodash';
import _fp from 'lodash/fp';
import { HappyIcon, HappyIconHighlight, MehHighlightIcon, MehIcon, SadIcon, SadIconHighlight, VeryHappyHighlightIcon, VeryHappyIcon, VerySadHighlightIcon, VerySadIcon, } from './icons';
export var ScaleValueResponses;
(function (ScaleValueResponses) {
    ScaleValueResponses[ScaleValueResponses["VerySad"] = 0] = "VerySad";
    ScaleValueResponses[ScaleValueResponses["Sad"] = 1] = "Sad";
    ScaleValueResponses[ScaleValueResponses["Meh"] = 2] = "Meh";
    ScaleValueResponses[ScaleValueResponses["Happy"] = 3] = "Happy";
    ScaleValueResponses[ScaleValueResponses["VeryHappy"] = 4] = "VeryHappy";
})(ScaleValueResponses || (ScaleValueResponses = {}));
export var ScaleValueNames;
(function (ScaleValueNames) {
    ScaleValueNames["VerySad"] = "very_sad";
    ScaleValueNames["Sad"] = "sad";
    ScaleValueNames["Meh"] = "meh";
    ScaleValueNames["Happy"] = "happy";
    ScaleValueNames["VeryHappy"] = "very_happy";
})(ScaleValueNames || (ScaleValueNames = {}));
export const ScaleValues = [
    {
        name: ScaleValueNames.VerySad,
        response: ScaleValueResponses.VerySad,
        icon: VerySadIcon,
        hightlightIcon: VerySadHighlightIcon,
    },
    {
        name: ScaleValueNames.Sad,
        response: ScaleValueResponses.Sad,
        icon: SadIcon,
        hightlightIcon: SadIconHighlight,
    },
    {
        name: ScaleValueNames.Meh,
        response: ScaleValueResponses.Meh,
        icon: MehIcon,
        hightlightIcon: MehHighlightIcon,
    },
    {
        name: ScaleValueNames.Happy,
        response: ScaleValueResponses.Happy,
        icon: HappyIcon,
        hightlightIcon: HappyIconHighlight,
    },
    {
        name: ScaleValueNames.VeryHappy,
        response: ScaleValueResponses.VeryHappy,
        icon: VeryHappyIcon,
        hightlightIcon: VeryHappyHighlightIcon,
    },
];
const ScaleValuesByResponse = _fp.flow([
    _fp.groupBy('response'),
    _fp.mapValues(_fp.first),
])(ScaleValues);
const ScaleValuesByName = _fp.flow([
    _fp.groupBy('name'),
    _fp.mapValues(_fp.first),
])(ScaleValues);
export function scaleValueByResponse(response) {
    return _.get(ScaleValuesByResponse, response);
}
export function scaleValueByName(name) {
    return _.get(ScaleValuesByName, name);
}
