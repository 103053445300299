import { Box, Button, DialogContent, Tab, Tabs } from '@mui/material';
import {
  useState,
  PropsWithRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogTitleWithClose,
  Patient,
  PatientService,
  UpdatePatientProps,
  useApiCallWithMessageBar,
  ValidationService,
  CommonDataContext,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';
import { Circle } from '@mui/icons-material';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';
import Tab5 from './Tab5';
import Tab6 from './Tab6';
import { LoggedDialog } from 'hive-analytics-react';

export interface TutorialProps {
  open: boolean;
  onClose: () => void;
  openCarePriorities: () => any;
}

enum TabValues {
  Intro,
  InformationIntro,
  ContactForm,
  YearsForm,
  PrioritiesIntro,
}

enum TabValuesWithWearable {
  Intro,
  InformationIntro,
  ContactForm,
  YearsForm,
  PrioritiesIntro,
  SkippedPriorities,
}

export default function Tutorial({
  onClose,
  open,
  openCarePriorities,
}: PropsWithRef<TutorialProps>): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState<'F' | 'M' | ''>('');
  const [yearOfBirth, setYearOfBirth] = useState('');
  const [diagnosticYear, setDiagnosticYear] = useState('');
  const [allowContact, setAllowContact] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const hasWearable = featureFlags.FITBIT || featureFlags.GARMIN;
  const tabValues = hasWearable ? TabValuesWithWearable : TabValues;
  const [value, setValue] = useState(tabValues.Intro);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (p: Patient, patientData: UpdatePatientProps) =>
      PatientService.updatePatient(p, patientData),
    success: {
      message: t('content.tutorial.success_submit'),
    },
    failure: {
      message: t('content.tutorial.failure_submit'),
    },
  });

  useEffect(() => {
    if (patient?.properties) {
      setFirstname(patient.properties.firstname);
      setLastname(patient.properties.lastname);
    }
  }, [patient]);

  const handleSubmit = useCallback(() => {
    if (!patient?.id) {
      return;
    }

    if (gender !== 'M' && gender !== 'F') {
      return;
    }

    const patientData: UpdatePatientProps = {
      firstname,
      lastname,
      phone,
      gender,
      dateOfBirth: new Date(Number(yearOfBirth), 0, 1).toISOString(),
      diagnosticDate: new Date(Number(diagnosticYear), 0, 1).toISOString(),
      allowContact,
      showTutorial: false,
    };

    onSubmit(patient, patientData).then(onClose);
  }, [
    onClose,
    onSubmit,
    firstname,
    lastname,
    phone,
    gender,
    yearOfBirth,
    diagnosticYear,
    allowContact,
    patient,
  ]);

  const onTabChange = (_event: any, newValue: string | number) => {
    setValue(Number(newValue));
  };

  const handleClose = () => {
    onClose();
  };

  const onTab5Change = () => {
    if (hasWearable) {
      onTabChange(null, value + 1);
    } else {
      handleSubmit();
    }
  };

  const onButtonClick = () => {
    setHasErrors(false);

    if (value === tabValues.PrioritiesIntro) {
      if (hasWearable) {
        setValue(value + 1);
        openCarePriorities();
      } else {
        openCarePriorities().then(handleSubmit());
      }
    } else if (
      hasWearable &&
      tabValues === TabValuesWithWearable &&
      value === tabValues.SkippedPriorities
    ) {
      handleSubmit();
    } else if (
      value === tabValues.ContactForm &&
      (!firstname || !lastname || !phone)
    ) {
      setHasErrors(true);
    } else if (
      value === tabValues.YearsForm &&
      (!yearOfBirth ||
        !diagnosticYear ||
        !gender ||
        !ValidationService.validateDiagnosticsYear(diagnosticYear, {
          yearOfBirth,
        }))
    ) {
      setHasErrors(true);
    } else {
      setValue(value + 1);
    }
  };

  return (
    <LoggedDialog
      dialogName="tutorial-dialog"
      onClose={handleClose}
      open={open}
      className="patient-tutorial"
    >
      <DialogTitleWithClose onClose={handleClose}>
        {t('content.tutorial.header')}
      </DialogTitleWithClose>

      <DialogContent>
        <Tab1 value={value} />
        <Tab2 value={value} />
        <Tab3
          tabValue={value}
          firstname={firstname}
          setFirstname={setFirstname}
          lastname={lastname}
          setLastname={setLastname}
          phone={phone}
          hasErrors={hasErrors}
          setPhone={setPhone}
          allowContact={allowContact}
          setAllowContact={setAllowContact}
        />
        <Tab4
          tabValue={value}
          gender={gender}
          setGender={setGender}
          hasErrors={hasErrors}
          diagnosticYear={diagnosticYear}
          setDiagnosticYear={setDiagnosticYear}
          yearOfBirth={yearOfBirth}
          setYearOfBirth={setYearOfBirth}
        />
        <Tab5 value={value} onChange={onTab5Change} />
        {hasWearable && <Tab6 value={value} />}
      </DialogContent>

      <Box display="flex">
        <Tabs onChange={onTabChange} value={value} aria-label="tabs">
          <Tab icon={<Circle />} value={tabValues.Intro} />
          <Tab
            disabled={value < tabValues.InformationIntro}
            icon={<Circle />}
            value={tabValues.InformationIntro}
          />
          <Tab
            disabled={value < tabValues.ContactForm}
            icon={<Circle />}
            value={tabValues.ContactForm}
          />
          <Tab
            disabled={value < tabValues.YearsForm}
            icon={<Circle />}
            value={tabValues.YearsForm}
          />
          <Tab
            disabled={value < tabValues.PrioritiesIntro}
            icon={<Circle />}
            value={tabValues.PrioritiesIntro}
          />
          {hasWearable && (
            <Tab
              disabled={value < TabValuesWithWearable.SkippedPriorities}
              icon={<Circle />}
              value={TabValuesWithWearable.SkippedPriorities}
            />
          )}
        </Tabs>
        <Button onClick={onButtonClick}>{t('common.continue')}</Button>
      </Box>
    </LoggedDialog>
  );
}
