import { jsx as _jsx } from "react/jsx-runtime";
import { Fragment, useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import _ from 'lodash';
import { useCurrentLanguage, useLanguages, changeCurrentLanguage, } from 'hive-react-utils';
import { AnalyticsService } from 'hive-analytics-react';
function LanguageButton({ language, current, }) {
    const changeLanguage = useCallback(() => {
        AnalyticsService.logClick('LANGUAGE', { language });
        changeCurrentLanguage(language);
    }, [language]);
    const variant = language === current ? 'selectableSelected' : 'selectable';
    return (_jsx(Button, Object.assign({ variant: variant, size: "small", color: "inherit", "aria-label": `language-button-${language}`, onClick: changeLanguage }, { children: language })));
}
export function LanguageToggle() {
    const current = useCurrentLanguage();
    const languages = useLanguages();
    if (_.size(languages) < 2) {
        return null;
    }
    return (_jsx(Stack, Object.assign({ direction: "row", spacing: 2, justifyContent: "end" }, { children: _.map(languages, (l) => (_jsx(Fragment, { children: _jsx(LanguageButton, { language: l, current: current }) }, l))) })));
}
