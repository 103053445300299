const styleOverrides = {
    root: {
        'header &': {
            // Eye-balled to make the header menu icon button align with the right of the language selector
            justifyContent: 'end',
            padding: '0px',
            marginRight: '-4px',
        },
    },
};
export const MuiIconButton = {
    styleOverrides,
};
