import _ from 'lodash';
import { useMemo } from 'react';
export function useIsDirty(current, original, options) {
    return useMemo(() => {
        if (!original || !current) {
            return !!(options === null || options === void 0 ? void 0 : options.dirtyOnNull);
        }
        const c = _.isEmpty(options === null || options === void 0 ? void 0 : options.fields)
            ? current
            : _.pick(current, options === null || options === void 0 ? void 0 : options.fields);
        const o = _.isEmpty(options === null || options === void 0 ? void 0 : options.fields)
            ? original
            : _.pick(original, options === null || options === void 0 ? void 0 : options.fields);
        return !_.isEqual(c, o);
    }, [current, original, options]);
}
