import { useMemo } from 'react';
import { useConnectionState } from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
export function useIsConnected() {
    const connectionState = useConnectionState();
    const isConnected = useMemo(() => {
        return (connectionState === ConnectionService.ConnectionState.CONNECTED ||
            connectionState === ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN);
    }, [connectionState]);
    return isConnected;
}
