import { elevation, spacing } from './constants';
const styleOverrides = {
    root: {
        'footer & a > svg': {
            width: 'auto',
            height: '22px',
        },
        svg: {
            pointerEvents: 'none',
        },
        header: {
            '& a': {
                display: 'flex',
                pointerEvents: 'all',
                '> svg': {
                    marginTop: `${spacing}px`,
                    marginBottom: `${spacing}px`,
                    padding: '0',
                    alignSelf: 'center',
                    pointerEvents: 'none',
                },
            },
            '@media (max-height: 900px)': {
                maxHeight: '72px',
                '& a': {
                    '> svg': {
                        maxHeight: '68px',
                    },
                    '> svg:nth-of-type(2)': {
                        maxHeight: '32px',
                        maxWidth: 'fit-content',
                    },
                },
            },
        },
        '.recharts-tooltip-wrapper &': {
            padding: `${spacing * 2}px`,
        },
    },
};
const defaultProps = {
    elevation,
};
export const MuiPaper = {
    styleOverrides,
    defaultProps,
};
