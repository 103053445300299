import { spacing } from './constants';
const styleOverrides = {
    root: {
        '.patient-priority-survey &': {
            border: 'unset',
            padding: spacing,
        },
        '.patient-priority-survey &.Mui-selected': {
            backgroundColor: 'unset',
        },
    },
};
export const MuiToggleButton = {
    styleOverrides,
};
