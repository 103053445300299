import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiButton'] = {
  outlined: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: 'large',
    boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.5)',
    border: `2px solid ${COLOURS.Primary}`,
    width: 'fit-content',
    alignSelf: 'center',
    minWidth: '250px',
    ':disabled': {
      border: `2px solid ${COLOURS.Disabled}`,
    },
    ':hover': {
      border: `2px solid ${COLOURS.Primary}`,
    },
    '.patient-resource-card &': {
      justifyContent: 'center',
    },
  },
  root: {
    '.patient-resources &': {
      justifyContent: 'left',
      textAlign: 'left',
      fontStyle: 'italic',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '23px',
      color: COLOURS.RoyalBlue,
      textTransform: 'none',
    },
    '.survey-popover &': {
      padding: `${spacing * 0.5}px ${spacing * 3}px`,
      borderRadius: `${spacing * 2.5}px ${spacing * 2.5}px 0 0`,
    },

    '&.audio-journal-button': {
      minWidth: 'unset',
      padding: `${spacing}px`,
    },
  },
  startIcon: {
    '.care-team &': {
      marginRight: 0,
    },
  },
};

export const MuiButton = {
  styleOverrides: styleOverrides,
};
