const variants = [
    {
        props: { multiline: true },
        style: {
            height: '100%',
            '.MuiInputBase-multiline': {
                height: '100%',
                '.MuiInputBase-inputMultiline': {
                    // TODO: the textarea component gets rerender at every keystroke and is applied by inline styling, thus the only way to beat it
                    height: '100% !important',
                },
            },
        },
    },
];
export const MuiTextField = {
    variants,
};
