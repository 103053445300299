import { useCallback, useRef, useState } from 'react';
import { IconButton } from '@mui/material';
import {
  Journal,
  SondeService,
  useFeatureFlags,
} from 'ecarepd-shared-utilities';

import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import StopIcon from '@mui/icons-material/Stop';
import {
  AnalyticsService,
  Operation,
  OperationEvents,
} from 'hive-analytics-react';

const OPERATION_PLAY: OperationEvents = {
  start: 'PLAYING_AUDIO_JOURNAL_START',
  end: 'PLAYING_AUDIO_JOURNAL_END',
  error: 'PLAYING_AUDIO_JOURNAL_ERROR',
};

export interface AudioJournalPlayProps {
  journal?: Journal;
}

function AudioJournalPlayInternal({
  journal,
}: AudioJournalPlayProps): JSX.Element {
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>();
  const recordingOp = useRef<Operation | undefined>();

  const startPlaying = useCallback((j: Journal, a?: HTMLAudioElement) => {
    setPlaying(true);
    setAudio(a);

    if (a) {
      recordingOp.current = AnalyticsService.startOperation(OPERATION_PLAY, {
        blobId: j.properties.audioBlobId,
      });

      a?.play();
    }
  }, []);

  const stopPlaying = useCallback((reason: string, error?: any) => {
    setPlaying(false);
    setAudio(undefined);

    if (error) {
      console.error(error);
      recordingOp.current?.error(error);
    } else {
      recordingOp.current?.end({ reason });
    }
  }, []);

  const onPlay = useCallback(() => {
    if (!journal?.properties.audioBlobId) {
      return;
    }

    startPlaying(journal);

    SondeService.downloadBlob(journal).then((blob) => {
      const url = URL.createObjectURL(blob);

      const a = new Audio(url);
      a.onended = () => stopPlaying('ended');
      a.onpause = () => stopPlaying('canceled');
      a.onerror = (error) => stopPlaying('error', error);

      startPlaying(journal, a);
    });
  }, [journal, startPlaying, stopPlaying]);

  const onStop = useCallback(() => {
    if (!audio) {
      return;
    }

    // onended will call stopPlaying
    audio.pause();
    audio.currentTime = 0;
  }, [audio]);

  return (
    <IconButton
      className="audio-journal-button"
      onClick={playing ? onStop : onPlay}
      color="primary"
    >
      {playing ? <StopIcon /> : <RecordVoiceOverIcon />}
    </IconButton>
  );
}

export function AudioJournalPlay({
  journal,
}: AudioJournalPlayProps): JSX.Element {
  const featureFlags = useFeatureFlags();

  if (!featureFlags.SONDE || !journal?.properties.audioBlobId) {
    return <></>;
  }

  return <AudioJournalPlayInternal journal={journal} />;
}
