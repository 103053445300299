import { createTheme } from '@mui/material/styles';
import { spacing, palette } from './constants';
import { MuiAccordion, MuiAccordionDetails, MuiAccordionSummary, } from './accordion';
import { MuiAppBar } from './appBar';
import { MuiButton } from './button';
import { MuiCard } from './card';
import { MuiDialog, MuiDialogActions, MuiDialogContent, MuiDialogTitle, } from './dialog';
import { MuiIconButton } from './iconButton';
import { MuiInputBase } from './input';
import { MuiMenuItem } from './menuItem';
import { MuiPaper } from './paper';
import { MuiPopover } from './popover';
import { MuiTabs } from './tabs';
import { MuiTextField } from './textfield';
import { MuiToggleButton } from './toggleButton';
import { MuiSelect } from './select';
import { MuiSnackbar } from './snackbar';
import { typography, MuiTypography } from './typography';
export const theme = createTheme({
    spacing,
    palette,
    typography,
    components: {
        MuiAccordion,
        MuiAccordionDetails,
        MuiAccordionSummary,
        MuiAppBar,
        MuiButton,
        MuiCard,
        MuiDialog,
        MuiDialogActions,
        MuiDialogContent,
        MuiDialogTitle,
        MuiIconButton,
        MuiInputBase,
        MuiMenuItem,
        MuiPaper,
        MuiPopover,
        MuiSelect,
        MuiSnackbar,
        MuiTabs,
        MuiTextField,
        MuiToggleButton,
        MuiTypography,
    },
});
