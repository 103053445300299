import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Typography,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  useConnection,
  useConnectionState,
  useLoginCallback,
} from 'hive-react-utils';
import { ConnectionService } from 'hive-client-utils';
import _ from 'lodash';
import {
  CommonDataContext,
  CommonDataContextProps,
  LanguageToggle,
  Logo,
  LogoTitle,
  useIsConnected,
} from 'ecarepd-shared-utilities';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AnalyticsService,
  LoggedConnectionService,
} from 'hive-analytics-react';

import { PATH_SETTINGS, PATH_SURVEY } from '../content/constants';

const OIDC_PATH =
  process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;

function HeaderMenu({
  anchorEl,
  onClose,
}: {
  anchorEl: Element | null;
  onClose: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const connectionState = useConnectionState();
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);

  const handleSettings = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_SETTINGS');
    navigate(PATH_SETTINGS);
    onClose();
  }, [navigate, onClose]);

  const handlePHQ9Survey = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_PHQ9_SURVEY');
    navigate(PATH_SURVEY);
  }, [navigate]);

  const handleLoginInternal = useLoginCallback(OIDC_PATH, onClose);
  const handleLogin = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGIN');
    handleLoginInternal();
  }, [handleLoginInternal]);

  const handleLogout = useCallback(() => {
    AnalyticsService.logClick('HEADER_MENU_LOGOUT');
    LoggedConnectionService.releaseBee('HEADER_MENU');
    onClose();
  }, [onClose]);

  const items = useMemo(() => {
    switch (connectionState) {
      case ConnectionService.ConnectionState.DISCONNECTED:
        return [
          <MenuItem key="sign-in" onClick={handleLogin}>
            <>{t('header.login')}</>
          </MenuItem>,
        ];

      case ConnectionService.ConnectionState.CONNECTED:
      case ConnectionService.ConnectionState.CONNECTED_FROM_TOKEN:
        const connectedMenuItems = [
          <MenuItem key="settings" onClick={handleSettings}>
            <>{t('header.settings')}</>
          </MenuItem>,
          <MenuItem key="sign-out" onClick={handleLogout}>
            <>{t('header.logout')}</>
          </MenuItem>,
        ];

        if (featureFlags.DEPRESSION_SURVEY) {
          connectedMenuItems.unshift(
            <MenuItem key="phq9-survey" onClick={handlePHQ9Survey}>
              {t('header.depression_survey')}
            </MenuItem>
          );
        }

        return connectedMenuItems;
    }

    return [];
  }, [
    connectionState,
    t,
    handleLogin,
    handleLogout,
    handleSettings,
    handlePHQ9Survey,
    featureFlags,
  ]);

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={!!anchorEl}
      onClose={onClose}
    >
      {items}
    </Menu>
  );
}

function MenuAndLanguage(): JSX.Element {
  const connection = useConnection();
  const username = _.get(connection, 'username', '');
  const isConnected = useIsConnected();

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);

  const handleOpenMenu = useCallback((event: any) => {
    AnalyticsService.logClick('HEADER_MENU_MENU');
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  if (!isConnected) {
    return <LanguageToggle />;
  }

  return (
    <>
      <HeaderMenu anchorEl={menuAnchor} onClose={handleCloseMenu} />

      <Stack direction="column" spacing={0}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleOpenMenu}
        >
          <Stack direction="row" spacing={1}>
            <Typography>{username}</Typography>
            <MenuIcon />
          </Stack>
        </IconButton>

        <LanguageToggle />
      </Stack>
    </>
  );
}

export default function Header(): JSX.Element {
  return (
    <header>
      <Paper>
        <AppBar position="static">
          <Toolbar>
            <RouterLink to="/">
              <Logo />
              <LogoTitle />
            </RouterLink>
            <Container />
            <MenuAndLanguage />
          </Toolbar>
        </AppBar>
      </Paper>
    </header>
  );
}
