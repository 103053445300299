import { Button, Stack, Typography } from '@mui/material';
import { PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';
import htmlReactParser from 'html-react-parser';
import { COLOURS } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import TabPanel from '../tutorial/TabPanel';
import { COMPONENTS } from './components';

export interface CarePrioritiesHomeProps {
  tabValue: number;
  alphabeticalGroupings: any;
  handleClick: (v: number) => void;
}

export default function CarePrioritiesHome({
  tabValue,
  alphabeticalGroupings,
  handleClick,
}: PropsWithRef<CarePrioritiesHomeProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <TabPanel value={tabValue} index={0}>
      <Typography paddingTop={4}>
        {htmlReactParser(t('content.care_priorities.subheader'))}
      </Typography>

      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-evenly"
        padding={5}
      >
        {alphabeticalGroupings.map((group: any, idx: number) => {
          const componentName = _.get(
            group,
            '0.properties.categoryName',
            'default'
          ).replace(/ /g, '');
          const ImageComponent = _.get(COMPONENTS, componentName);
          return (
            <Stack key={componentName} alignItems="center">
              <ImageComponent />

              <Typography variant="h6" padding={2} color={COLOURS.Primary}>
                <b>{t(`content.care_priorities.${componentName}`)}</b>
              </Typography>
              <Button variant="outlined" onClick={() => handleClick(idx)}>
                {t('content.care_priorities.select')}
              </Button>
            </Stack>
          );
        })}
      </Stack>
    </TabPanel>
  );
}
