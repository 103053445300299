import { useContext } from 'react';
import _ from 'lodash';
import { Box, Stack } from '@mui/material';
import { DataContext } from '../../contexts/DataContext';
import { DeleteAccount } from './DeleteAccount';
import { ResetPassword } from './ResetPassword';
import { YourInfo } from './YourInfo';
import { DeleteWearable } from './DeleteWearable';
import {
  CommonDataContext,
  CommonDataContextProps,
  useWearables,
} from 'ecarepd-shared-utilities';

export function Settings(): JSX.Element {
  const { patient } = useContext(DataContext);
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const patientID = patient?.properties.patientID || '';
  const wearables = useWearables(patientID);
  const wearable = _.first(wearables);

  if (patient) {
    return (
      <Stack
        className="settings"
        flexGrow={1}
        spacing={2}
        padding={2}
        maxWidth="500px"
        justifyContent="space-between"
      >
        <Box flexGrow={1}>
          <YourInfo />
        </Box>
        <Stack spacing={4} padding={4}>
          <Box>
            <ResetPassword />
          </Box>
          <Stack spacing={4} alignSelf="baseline">
            {featureFlags.FITBIT &&
              (!wearable ||
                wearable?.properties?.device?.toLowerCase() === 'fitbit') && (
                <DeleteWearable wearableType="fitbit" />
              )}
            {featureFlags.GARMIN &&
              (!wearable ||
                wearable?.properties?.device?.toLowerCase() === 'garmin') && (
                <DeleteWearable wearableType="garmin" />
              )}
          </Stack>
          <Box>
            <DeleteAccount />
          </Box>
        </Stack>
      </Stack>
    );
  }
  return <></>;
}
