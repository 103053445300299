import { PropsWithRef, useMemo } from 'react';
import { Stack } from '@mui/material';
import {
  Activity,
  Patient,
  PatientChart,
  PatientPriorityResponse,
  Sleep,
} from 'ecarepd-shared-utilities';
import { useInstancesByQuery } from 'hive-react-utils';
import _ from 'lodash';

export interface CarePriorityHistoryChartProps {
  patient?: Patient;
  entryCount: number;
}

export function CarePriorityHistoryChart({
  patient,
  entryCount,
}: PropsWithRef<CarePriorityHistoryChartProps>): JSX.Element | null {
  const responses = useInstancesByQuery<PatientPriorityResponse>(
    'ecarepd',
    'getLatestPatientPriorityResponses',
    'patientPriorityResponse',
    { debounce: true, debounceWait: 1000 },
    patient?.properties?.patientID || '',
    entryCount
  );
  const activities = useInstancesByQuery<Activity>(
    'ecarepd',
    'activityByPatientIdLatest',
    'activity',
    {},
    patient?.properties.patientID || '',
    entryCount
  );
  const sleeps = useInstancesByQuery<Sleep>(
    'ecarepd',
    'sleepByPatientIdLatest',
    'sleep',
    {},
    patient?.properties.patientID || '',
    entryCount
  );

  const processedResponses = useMemo<PatientPriorityResponse[]>(() => {
    if (_.get(responses, 'status') !== 'done') {
      return [];
    }

    const newResponses: any =
      _.sortBy(responses, (r: PatientPriorityResponse) => r.properties.date2) ||
      [];
    newResponses.status = 'done';
    return newResponses;
  }, [responses]);

  return (
    <Stack flexGrow="1">
      <PatientChart
        patient={patient}
        responses={processedResponses}
        activities={activities}
        sleeps={sleeps}
      />
    </Stack>
  );
}
