import { ComponentsVariants } from '@mui/material';

const variants: ComponentsVariants['MuiTextField'] = [
  {
    props: { multiline: true },
    style: {
      height: '100%',
      '&.home-page-journal': {
        '.MuiInputBase-multiline': {
          height: '100%',
          '.MuiInputBase-inputMultiline': {
            // the textarea component gets rerender at every keystroke and is applied by inline styling, this the only way to beat it
            height: '100% !important',
            overflow: 'auto !important', // same reason as above
          },
        },
      },
      '&.edit-journal': {
        '.MuiInputBase-multiline .MuiInputBase-inputMultiline': {
          maxHeight: '80vh',
        },
      },
    },
  },
];

export const MuiTextField = {
  variants,
};
