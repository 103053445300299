import {
  useCallback,
  useEffect,
  useState,
  useContext,
  PropsWithRef,
  useMemo,
} from 'react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Button,
  Typography,
  TextFieldProps,
  Grid,
} from '@mui/material';
import {
  CareTeamMember,
  CareTeamMemberProperties,
  CareTeamMemberType,
  DialogTitleWithClose,
  PatientService,
  useApiCallWithMessageBar,
  useIsDirty,
  ValidationService,
} from 'ecarepd-shared-utilities';
import { PatternFormat } from 'react-number-format';

import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';
import _ from 'lodash';
import { useConfirm } from 'material-ui-confirm';

const FIELDS_FOR_DIRTY = [
  'name',
  'jobTitle',
  'address',
  'location',
  'callNumber',
  'messagingNumber',
  'email',
];

export enum CareTeamDialogMode {
  Add,
  Edit,
}

export interface CareTeamDialogProperties {
  mode: CareTeamDialogMode;
  careTeamMember?: CareTeamMember;
  open: boolean;
  onClose: () => void;
  careTeamMemberType: CareTeamMemberType;
}

export interface InputFieldProps {
  name: string;
  autoComplete: string;
  value: string;
  label: string;
  setValue: (v: string) => void;
}
function InputField({
  name,
  autoComplete,
  value,
  label,
  setValue,
  ...rest
}: PropsWithRef<InputFieldProps & TextFieldProps>): JSX.Element {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [setValue]
  );
  return (
    <TextField
      {...rest}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
      name={name}
      label={label}
      fullWidth
    />
  );
}

export function CareTeamDialog({
  mode,
  careTeamMember,
  open,
  onClose,
  careTeamMemberType,
}: CareTeamDialogProperties): JSX.Element {
  const { t } = useTranslation();

  const [name, setName] = useState(careTeamMember?.properties.name || '');
  const [jobTitle, setJobTitle] = useState(
    careTeamMember?.properties.jobTitle || ''
  );
  const [location, setLocation] = useState(
    careTeamMember?.properties.location || ''
  );
  const [address, setAddress] = useState(
    careTeamMember?.properties.address || ''
  );
  const [email, setEmail] = useState(careTeamMember?.properties.email || '');
  const [phoneNumber, setPhoneNumber] = useState(
    careTeamMember?.properties.callNumber || ''
  );
  const [textNumber, setTextNumber] = useState(
    careTeamMember?.properties.messagingNumber || ''
  );

  const [originalCareTeamMember, setOriginalCareTeamMember] = useState<
    CareTeamMember | undefined
  >();

  const currentValue = useMemo(
    () => ({
      name,
      jobTitle,
      address,
      location,
      callNumber: phoneNumber,
      messagingNumber: textNumber,
      email,
    }),
    [address, email, jobTitle, location, name, phoneNumber, textNumber]
  );

  const isDirty = useIsDirty(
    currentValue,
    originalCareTeamMember?.properties as object,
    {
      fields: FIELDS_FOR_DIRTY,
      dirtyOnNull: true,
    }
  );

  const { patient } = useContext(DataContext);

  useEffect(() => {
    setOriginalCareTeamMember(careTeamMember);
    setName(careTeamMember?.properties.name || '');
    setJobTitle(careTeamMember?.properties.jobTitle || '');
    setLocation(careTeamMember?.properties.location || '');
    setAddress(careTeamMember?.properties.address || '');
    setEmail(careTeamMember?.properties.email || '');
    setPhoneNumber(careTeamMember?.properties.callNumber || '');
    setTextNumber(careTeamMember?.properties.messagingNumber || '');
  }, [careTeamMember, open]);

  const { callback: addCareTeamMember, progress: addProgress } =
    useApiCallWithMessageBar({
      canExecute: () => !!patient,

      execute: () =>
        PatientService.addCareTeamMember(
          _.pickBy({
            name,
            jobTitle,
            careTeamMemberType,
            email: email || '',
            address: address || '',
            callNumber: phoneNumber || '',
            messagingNumber: textNumber || '',
            location: location || '',
          }) as unknown as CareTeamMemberProperties
        ).then(onClose),

      success: {
        message: t('content.care_team.success_submit'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },

      failure: {
        message: t('content.care_team.failure_submit'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },
    });

  const { callback: modifyCareTeamMember, progress: modifyProgress } =
    useApiCallWithMessageBar({
      canExecute: () =>
        !!careTeamMember?.id && mode === CareTeamDialogMode.Edit,

      execute: () =>
        PatientService.updateCareTeamMember(careTeamMember!.id, {
          name,
          jobTitle,
          careTeamMemberType,
          email,
          address,
          callNumber: phoneNumber,
          messagingNumber: textNumber,
          location,
        }).then(onClose),

      success: {
        message: t('content.care_team.success_submit'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },

      failure: {
        message: t('content.care_team.failure_submit'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },
    });

  const confirmFn = useConfirm();
  const { callback: removeCareTeamMember, progress: removeProgress } =
    useApiCallWithMessageBar({
      canExecute: () =>
        !!careTeamMember?.id && mode === CareTeamDialogMode.Edit,

      execute: () => PatientService.removeCareTeamMember(careTeamMember?.id!),

      confirmation: {
        enabled: true,
        title:
          t('content.care_team.dialog.remove_confirmation_title') || undefined,
        description:
          t('content.care_team.dialog.remove_confirmation_message') ||
          undefined,
        confirmationText: t('common.yes') || undefined,
        cancellationText: t('common.no') || undefined,
        confirmFn,
      },

      success: {
        message: t('content.care_team.success_submit'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },

      failure: {
        message: t('content.care_team.failure_submit'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },
    });

  const emailValid = useMemo(
    () => ValidationService.validateEmail(email, { ignoreEmpty: true }),
    [email]
  );

  const disabled =
    !isDirty ||
    !name ||
    !jobTitle ||
    !emailValid ||
    addProgress ||
    modifyProgress ||
    removeProgress;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      className="care-team-member-dialog"
    >
      <DialogTitleWithClose onClose={onClose}>
        {mode === CareTeamDialogMode.Add
          ? t('content.care_team.dialog.header.add')
          : t('content.care_team.dialog.header.edit')}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <Stack spacing={6}>
          <Typography>{t('content.care_team.dialog.subtitle')}</Typography>

          <form
            onSubmit={
              mode === CareTeamDialogMode.Add
                ? addCareTeamMember
                : modifyCareTeamMember
            }
          >
            <Grid container spacing={6}>
              <Grid item sm={6}>
                <InputField
                  required
                  name="name"
                  autoComplete="name"
                  label={t('content.care_team.dialog.name')}
                  value={name}
                  setValue={setName}
                  helperText={
                    !name && t('content.care_team.dialog.invalid_name')
                  }
                />
              </Grid>

              <Grid item sm={6}>
                <InputField
                  required
                  name="jobTitle"
                  autoComplete="jobTitle"
                  label={t('content.care_team.dialog.title')}
                  value={jobTitle}
                  setValue={setJobTitle}
                  helperText={
                    !jobTitle && t('content.care_team.dialog.invalid_title')
                  }
                />
              </Grid>

              <Grid item sm={6}>
                <InputField
                  name="location"
                  autoComplete="location"
                  label={t('content.care_team.dialog.location')}
                  value={location}
                  setValue={setLocation}
                />
              </Grid>

              <Grid item sm={6}>
                <InputField
                  name="address"
                  autoComplete="address"
                  label={t('content.care_team.dialog.address')}
                  value={address}
                  setValue={setAddress}
                />
              </Grid>

              <Grid item sm={6}>
                <PatternFormat
                  fullWidth
                  name="phoneNumber"
                  autoComplete="tel"
                  value={phoneNumber}
                  onValueChange={({ value }) => setPhoneNumber(value)}
                  customInput={TextField}
                  type="tel"
                  mask=" "
                  format="(###) ###-####"
                  valueIsNumericString
                  label={t('content.care_team.dialog.phone_number')}
                />
              </Grid>

              <Grid item sm={6}>
                <PatternFormat
                  fullWidth
                  name="textNumber"
                  autoComplete="tel"
                  value={textNumber}
                  onValueChange={({ value }) => setTextNumber(value)}
                  customInput={TextField}
                  type="tel"
                  mask=" "
                  format="(###) ###-####"
                  valueIsNumericString
                  label={t('content.care_team.dialog.text_number')}
                />
              </Grid>

              <Grid item sm={6}>
                <InputField
                  fullWidth
                  name="email"
                  autoComplete="email"
                  label={t('content.care_team.dialog.email')}
                  value={email}
                  setValue={setEmail}
                  helperText={
                    !emailValid && t('content.care_team.dialog.invalid_email')
                  }
                />
              </Grid>
            </Grid>
          </form>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={0}>
          {mode === CareTeamDialogMode.Edit && (
            <Button onClick={removeCareTeamMember}>
              {t('content.care_team.dialog.remove_from_care_team')}
            </Button>
          )}
        </Stack>

        <Stack direction="row" spacing={0}>
          <Button onClick={onClose}>{t('common.cancel')}</Button>

          {mode === CareTeamDialogMode.Add && (
            <Button disabled={disabled} onClick={addCareTeamMember}>
              {t('content.care_team.dialog.add_to_care_team')}
            </Button>
          )}

          {mode === CareTeamDialogMode.Edit && (
            <Button disabled={disabled} onClick={modifyCareTeamMember}>
              {t('content.care_team.dialog.update_care_team')}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
