import 'survey-core/modern.min.css';
import { Fragment, useMemo, useState } from 'react';

import {
  PatientService,
  useApiCallWithMessageBar,
  useLatestSurvey,
} from 'ecarepd-shared-utilities';

import { Model, StylesManager } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { LoggedDialog } from 'hive-analytics-react';

import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../contexts/DataContext';
import SurveyPopupButton from '../Survey/SurveyPopupButton';

StylesManager.applyTheme('modern');

const QUESTION_CSS = {
  question: {
    content: 'sd-question__content question_content_custom',
    titleOnAnswer: 'question-title-answered',
  },
};

export function SurveyApp(): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useDataContext();
  const latestSurvey = useLatestSurvey();
  const [isOpen, setIsOpen] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (sender: any) =>
      PatientService.addSurveyResults(
        sender.data,
        latestSurvey?.properties.version
          ? latestSurvey?.properties.version + 1
          : 1
      ),
    success: {
      message: t('home.survey.successSubmit'),
    },
    failure: {
      message: t('home.survey.failureSubmit'),
    },
  });

  const SurveyOne = useMemo(() => {
    if (!latestSurvey) {
      return;
    }
    const survey = new Model(latestSurvey.properties.body);
    survey.onComplete.add(onSubmit);
    survey.onComplete.add(() => setSurveyCompleted(true));

    return survey;
  }, [latestSurvey, onSubmit]);

  if (!latestSurvey) {
    return <></>;
  }

  return (
    <Fragment>
      <SurveyPopupButton
        onClick={() => setIsOpen(true)}
        surveyCompleted={surveyCompleted}
      />
      <LoggedDialog
        dialogName="invite-patient"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
      >
        <Survey model={SurveyOne} css={QUESTION_CSS} />
      </LoggedDialog>
    </Fragment>
  );
}
