import { Button, Stack, Typography } from '@mui/material';
import { AnalyticsService } from 'hive-analytics-react';
import { PropsWithRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SiteIcon } from '../../../assets/resources/Site.svg';

export interface LinkProps {
  strings: string;
}

export function Link({ strings }: PropsWithRef<LinkProps>): JSX.Element {
  const { t } = useTranslation();

  const openLink = useCallback(() => {
    const url = t(`${strings}.url`);
    AnalyticsService.logClick('RESOURCE-LINK', { url });
    window.open(url);
  }, [strings, t]);

  return (
    <Stack>
      <Button startIcon={<SiteIcon />} onClick={openLink}>
        {t(`${strings}.title`)}
      </Button>

      <Typography variant="body2">{t(`${strings}.text`)}</Typography>
    </Stack>
  );
}
