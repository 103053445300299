import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, PropsWithRef, useMemo } from 'react';
import TabPanel from './TabPanel';
import { ReactComponent as Diagnosis } from '../../assets/FTE4.svg';
import { ValidationService, YearSelector } from 'ecarepd-shared-utilities';
import moment from 'moment';

export interface Tab4Props {
  tabValue: number;
  hasErrors: boolean;
  gender: 'M' | 'F' | '';
  setGender: (v: 'M' | 'F' | '') => void;
  diagnosticYear: string;
  setDiagnosticYear: (v: string) => void;
  yearOfBirth: string;
  setYearOfBirth: (v: string) => void;
}

export default function Tab4({
  tabValue,
  hasErrors,
  gender,
  setGender,
  diagnosticYear,
  setDiagnosticYear,
  yearOfBirth,
  setYearOfBirth,
}: PropsWithRef<Tab4Props>): JSX.Element {
  const { t } = useTranslation();

  const onChangeGender = useCallback(
    (event: SelectChangeEvent<'M' | 'F' | ''>) => {
      if (!event?.target?.value) {
        return;
      }
      const newGender = event.target.value === 'F' ? 'F' : 'M';
      setGender(newGender);
    },
    [setGender]
  );

  const datesValid = useMemo(
    () =>
      ValidationService.validateDiagnosticsYear(diagnosticYear, {
        yearOfBirth,
        validIfNull: true,
      }),
    [yearOfBirth, diagnosticYear]
  );

  return (
    <TabPanel value={tabValue} index={3}>
      <Stack display="flex" flexDirection="row">
        <Stack
          flexDirection="column"
          flexBasis={0}
          flexGrow={2}
          justifyContent="space-evenly"
          paddingTop={10}
        >
          <Typography className="display-contents">
            {t('content.tutorial.tab4')}
          </Typography>

          <YearSelector
            label={t('content.tutorial.diagnosed_date')}
            placeholder={t('content.tutorial.diagnosed_date_placeholder')}
            value={
              diagnosticYear
                ? moment().year(parseInt(diagnosticYear)).startOf('year')
                : undefined
            }
            setValue={(newValue: moment.Moment) => {
              setDiagnosticYear(newValue.year().toString());
            }}
            helperText={
              !datesValid &&
              t('settings.your_info.errors.diagnostics_before_dob')
            }
          />

          <YearSelector
            label={t('content.tutorial.birth_date')}
            placeholder={t('content.tutorial.birth_date_placeholder')}
            value={
              yearOfBirth
                ? moment().year(parseInt(yearOfBirth)).startOf('year')
                : undefined
            }
            setValue={(newValue: moment.Moment) => {
              setYearOfBirth(newValue.year().toString());
            }}
          />

          <FormControl>
            <Select
              id="gender"
              value={gender}
              onChange={onChangeGender}
              required
              error={hasErrors && !gender}
            >
              <MenuItem value="F">{t('content.tutorial.female')}</MenuItem>
              <MenuItem value="M">{t('content.tutorial.male')}</MenuItem>
            </Select>
            <InputLabel id="demo-simple-select-helper-label">
              {t('content.tutorial.gender')}
            </InputLabel>
          </FormControl>
        </Stack>
        <Diagnosis />
      </Stack>
    </TabPanel>
  );
}
