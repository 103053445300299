import { Box, Button, Stack, Typography } from '@mui/material';
import { PatientService } from 'ecarepd-shared-utilities';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';
import { PATH_EDITINFO } from '../constants';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';

export function YourInfo(): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);
  const notAvailable = t('common.n_a');
  const navigate = useNavigate();

  const formatGender = (gender?: 'M' | 'F' | '') => {
    if (gender === 'M') {
      return t('content.tutorial.male');
    }

    if (gender === 'F') {
      return t('content.tutorial.female');
    }

    return undefined;
  };

  const onEditInfo = useCallback(() => {
    navigate(PATH_EDITINFO);
  }, [navigate]);

  return (
    <Stack>
      <Typography variant="h5">{t('settings.your_info.title')}</Typography>

      <Stack
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        spacing={4}
        alignItems="baseline"
        padding={4}
        paddingBottom={2}
      >
        <Typography variant="body1">{t('settings.your_info.name')}</Typography>
        <Typography variant="body2">
          {patient?.properties.name || notAvailable}
        </Typography>

        <Typography variant="body1">{t('settings.your_info.email')}</Typography>
        <Typography variant="body2">
          {patient?.properties.email || notAvailable}
        </Typography>

        <Typography variant="body1">
          {t('settings.your_info.edit_info.phone_number')}
        </Typography>
        <Typography variant="body2">
          {patient?.properties.phone ? (
            <PatternFormat
              value={patient?.properties.phone}
              displayType="text"
              type="tel"
              mask=" "
              name="telephone"
              format="(###) ###-####"
              valueIsNumericString
              prefix=""
            />
          ) : (
            notAvailable
          )}
        </Typography>

        <Typography variant="body1">
          {t('settings.your_info.diagnosis_date')}
        </Typography>
        <Typography variant="body2">
          {PatientService.formatDate(patient?.properties.diagnosticDate) ||
            notAvailable}
        </Typography>

        <Typography variant="body1">{t('settings.your_info.dob')}</Typography>
        <Typography variant="body2">
          {PatientService.formatDate(patient?.properties.dateOfBirth) ||
            notAvailable}
        </Typography>

        <Typography variant="body1">
          {t('settings.your_info.gender')}
        </Typography>
        <Typography variant="body2">
          {formatGender(patient?.properties.gender) || notAvailable}
        </Typography>
        <Box>
          <Button onClick={onEditInfo} variant="outlined">
            {t('settings.your_info.edit_info.button')}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}
