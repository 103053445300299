import _ from 'lodash';

// Converts numeric degrees to radians
const toRad = (Value: number) => {
  return (Value * Math.PI) / 180;
};

//This function takes in latitude and longitude of two location and returns the distance
//between them as the crow flies (in km)
const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var latRad = toRad(lat1);
  var latRad2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(latRad) *
      Math.cos(latRad2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

export const filterByNearest = (
  filteredLocations: any,
  showInHome: any,
  addressCoords: number[],
  distance: number
) => {
  let nearLocations: any[] = [];
  const inHomeLocations: any[] = [];
  _.map(filteredLocations, (filteredLocation) => {
    if (_.isEmpty(filteredLocation.properties.coords)) {
      if (showInHome) {
        inHomeLocations.push(filteredLocation);
      }
      return;
    }
    _.map(filteredLocation.properties.coords, (coords) => {
      const distanceApart = calcCrow(
        coords.lat,
        coords.long,
        addressCoords[0],
        addressCoords[1]
      );

      if (distanceApart <= distance) {
        const individualLocation = filteredLocation;
        individualLocation.distance = distanceApart;
        individualLocation.properties.coords = [coords];

        nearLocations.push(individualLocation);
      }
    });
  });
  return {
    near: nearLocations,
    inHome: inHomeLocations,
  };
};

export const filterByType = (selectedServices: any, locations: any) => {
  return _.reduce(
    selectedServices,
    (selected: any, a) => {
      const found: { properties: { serviceType: string | any[] } }[] = [];
      _.each(
        locations,
        (location: { properties: { serviceType: string | any[] } }) => {
          if (location.properties.serviceType.indexOf(a) !== -1) {
            found.push(location);
          }
        }
      );
      selected = [...selected, ...found];
      return selected;
    },
    []
  );
};

export const findServices = (locations: any) => {
  return _.reduce(
    locations,
    (group: any, a) => {
      _.each(a.properties.serviceType, (type) => {
        group[type] = group[type] || [];
        group[type].push(a);
      });
      return group;
    },
    {}
  );
};
