import { Button, Stack } from '@mui/material';
import { PropsWithRef } from 'react';
import {
  PatientService,
  TextDialog,
  useApiCallWithMessageBar,
  useWearables,
} from 'ecarepd-shared-utilities';
import { AnalyticsService } from 'hive-analytics-react';
import _ from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';

export interface DeleteWearableProps {
  wearableType: string;
}

export function DeleteWearable({
  wearableType,
}: PropsWithRef<DeleteWearableProps>): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);
  const patientID = patient?.properties.patientID || '';
  const wearables = useWearables(patientID);
  const wearable = _.first(wearables);
  const wearableID = wearable?.id || '';

  const [confirm1, setConfirm1] = useState(false);

  const onClose = useCallback(() => {
    setConfirm1(false);
  }, []);

  const openConfirm1 = useCallback(() => setConfirm1(true), []);

  const { progress: deleting, callback: onDeleteWearable } =
    useApiCallWithMessageBar({
      canExecute: () => true,
      execute: async () => {
        AnalyticsService.logClick(`${wearableType.toUpperCase()}_DISCONNECT`);
        await PatientService.revokeDevice(wearableType, patientID);
        return onClose();
      },
      success: {
        snackbarProps: {
          autoHideDuration: 2000,
        },
        message: t('common.success'),
      },
      failure: {
        message: t('common.failure'),
      },
    });

  const { progress: refreshing, callback: onRefreshWearable } =
    useApiCallWithMessageBar({
      canExecute: () => true,
      execute: async () => {
        AnalyticsService.logClick(`${wearableType.toUpperCase()}_REFRESH`);
        await PatientService.refreshData(wearableType);
        return onClose();
      },
      success: {
        snackbarProps: {
          autoHideDuration: 2000,
        },
        message: t('common.success'),
      },
      failure: {
        message: t('common.failure'),
      },
    });

  const handleConnect = () => {
    AnalyticsService.logClick(`${wearableType.toUpperCase()}_CONNECT`);
    PatientService.requestAuthUrl(wearableType).then((uri) =>
      window.location.assign(uri)
    );
  };

  return (
    <>
      {wearableID ? (
        <Stack spacing={4}>
          <Button
            variant="outlined"
            onClick={onRefreshWearable}
            disabled={refreshing}
          >
            {t(`settings.${wearableType}.refresh`)}
          </Button>
          <Button
            variant="outlined"
            onClick={openConfirm1}
            disabled={!wearables}
          >
            {t(`settings.${wearableType}.disconnect`)}
          </Button>
        </Stack>
      ) : (
        <Button
          variant="outlined"
          onClick={handleConnect}
          disabled={!wearables}
        >
          {t(`settings.${wearableType}.connect`)}
        </Button>
      )}

      <TextDialog
        open={confirm1}
        disabled={deleting}
        onClose={onClose}
        title={t(`settings.${wearableType}.title`)}
        text={t(`settings.${wearableType}.message1`)}
        actions={
          <>
            <Button disabled={deleting} onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button disabled={deleting} onClick={onDeleteWearable}>
              {t(`settings.${wearableType}.yes_delete`)}
            </Button>
          </>
        }
      />
    </>
  );
}
