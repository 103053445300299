import { Stack, Typography } from '@mui/material';
import {
  PatientPriorityResponse,
  PatientPriorityResponseProperties,
  ResponseCareTipProps,
  useMyPatientPriorityResponses,
} from 'ecarepd-shared-utilities';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';
import { Actions } from './Actions';
import { Priorities } from './Priorities';

import _ from 'lodash';
import _fp from 'lodash/fp';
import moment from 'moment';

export interface ResponseMap {
  [id: string]: PatientPriorityResponseProperties;
}

export default function Tracking(): JSX.Element {
  const { t } = useTranslation();

  const {
    patient,
    patientPriorities,
    responseCareTips: todayResponseCareTips,
  } = useContext(DataContext);
  const patientID = useMemo(
    () => patient?.properties?.patientID || '',
    [patient]
  );

  const todayResponses = useMyPatientPriorityResponses(
    patientID,
    moment().startOf('day').toISOString(),
    moment().endOf('day').toISOString()
  );

  const [responses, setResponses] = useState<ResponseMap>({});

  // Note: this effect works with the patientID only and not the patient.
  // Otherwise changes to the survey, changes the patient's recentStatus,
  // which causes this effect to run again with older survey values, which
  // causes jerkiness.
  useEffect(() => {
    const priorityIDs = patientPriorities?.properties?.priorityIDs;
    if (!patientID || !priorityIDs || !todayResponses) {
      return;
    }

    const newResponses = _fp.flow([
      _fp.map((priorityID: string): PatientPriorityResponseProperties => {
        const existing = _.find(
          todayResponses,
          (p: PatientPriorityResponse) =>
            p?.properties.priorityID === priorityID
        );
        if (existing) {
          return existing.properties;
        }

        return {
          patientID,
          priorityID,
          response: -1,
          note: '',
          date2: moment().toISOString(),
        };
      }),
      _fp.groupBy('priorityID'),
      _fp.mapValues(_fp.first),
    ])(priorityIDs);

    setResponses(newResponses);
  }, [patientID, patientPriorities, todayResponses]);

  const [responseCareTips, setResponseCareTips] = useState<
    ResponseCareTipProps[]
  >([]);
  useEffect(
    () => setResponseCareTips(_.map(todayResponseCareTips, 'properties')),
    [todayResponseCareTips]
  );

  const changeResponse = useCallback(
    (
      id: string,
      response?: number,
      note?: string,
      responseCareTip?: ResponseCareTipProps
    ) => {
      const newResponses = _.cloneDeep(responses);
      if (response != null) {
        _.set(newResponses, [id, 'response'], response);
      }
      if (note != null) {
        _.set(newResponses, [id, 'note'], note);
      }

      setResponses(newResponses);

      if (responseCareTip) {
        const careTips = _fp.flow([
          _fp.cloneDeep,
          _fp.remove((c: ResponseCareTipProps) => c.priorityID === id),
          _fp.concat(responseCareTip),
        ])(responseCareTips);
        setResponseCareTips(careTips);
      }
    },
    [responseCareTips, responses]
  );

  return (
    <Stack flexGrow="1" spacing={2}>
      <Typography variant="h4">{t('content.tracking.title')}</Typography>
      <Typography variant="body2">{t('content.tracking.sub_title')}</Typography>

      <Priorities responses={responses} changeResponse={changeResponse} />

      <Actions responses={responses} responseCareTips={responseCareTips} />
    </Stack>
  );
}
