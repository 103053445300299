import { COLOURS } from './colours';
import { spacing } from './constants';
const styleOverrides = {
    root: {
        boxShadow: 'inset 0px 1px 3px 0px rgba(0,0,0,0.5)',
        padding: `${spacing * 2.5}px`,
        backgroundColor: COLOURS.LightBlueBackground + '40',
        '.wearable-data-card &': {
            boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.5)',
            padding: 0,
            backgroundColor: 'white',
            '.MuiCardContent-root': {
                padding: '16px',
            },
        },
        '.wearable-data-card-steps &': {
            border: `2px solid ${COLOURS.Green}`,
            borderRadius: '4px',
            '.MuiCardContent-root': {
                padding: '8px',
            },
        },
        '.wearable-data-card-sleepQuality &': {
            border: `2px solid ${COLOURS.DarkBlue}`,
            borderRadius: '4px',
            '.MuiCardContent-root': {
                padding: '8px',
            },
        },
    },
};
export const MuiCard = {
    styleOverrides,
};
