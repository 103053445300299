import {
  Box,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  CommonDataContext,
  useMyJournalsByDate,
  useMyPatientPriorityResponses,
  useStartOfDay,
  PatientService,
  useApiCallWithMessageBar,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';
import {
  Formik,
  Form,
  FormikProps,
  FormikHelpers,
  Field,
  FieldProps,
} from 'formik';
import _ from 'lodash';
import { DataContext } from '../contexts/DataContext';
import { useTranslation } from 'react-i18next';
import { AudioJournalButtons } from '../components/Sonde/AudioJournalButtons';

export default function Journal(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const { patient } = useContext(DataContext);
  const { symptomPrioritiesById } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const startOfDay = useStartOfDay();
  const [date, setDate] = useState(startOfDay);
  const journals = useMyJournalsByDate(date, date);
  const journal = useMemo(() => _.first(journals), [journals]);
  const carePriorityResponses = useMyPatientPriorityResponses(
    patient?.properties.patientID || '',
    moment(date).startOf('day').toISOString(),
    moment(date).endOf('day').toISOString()
  );
  const hasCarePriorityResponseNotes = useMemo(
    () => !!_.compact(_.map(carePriorityResponses, 'properties.note')).length,
    [carePriorityResponses]
  );
  const formRef = useRef<
    FormikProps<{
      entry: string;
    }>
  >(null);

  useEffect(() => {
    formRef.current?.resetForm({
      values: {
        entry:
          journal?.properties.audioTranscript ||
          journal?.properties.entry ||
          '',
      },
    });
  }, [journal]);

  useEffect(() => {
    setDate(startOfDay);
  }, [startOfDay]);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (entry: string) =>
      PatientService.updateJournalEntry(patient!, date, entry),
    success: {
      message: journal
        ? t('home.journal.successUpdate')
        : t('home.journal.successSubmit'),
    },
    failure: {
      message: journal
        ? t('home.journal.failureUpdate')
        : t('home.journal.failureSubmit'),
    },
  });

  const handleFormikSubmit = useCallback(
    (
      {
        entry,
      }: {
        entry: string;
      },
      {
        setSubmitting,
        resetForm,
      }: FormikHelpers<{
        entry: string;
      }>
    ) => {
      resetForm({ values: { entry } });
      onSubmit(entry).then(() => setSubmitting(false));
    },
    [onSubmit]
  );

  return (
    <Stack
      flex={1}
      direction={md ? 'row' : 'column'}
      spacing={8}
      overflow="hidden"
    >
      <Box overflow="auto">
        <Box
          width={320}
          height={330}
          margin={2}
          boxShadow="0px 3px 6px rgba(0, 0, 0, 0.246176)"
          borderRadius={2}
          overflow="hidden"
        >
          <StaticDatePicker
            value={date}
            onChange={(val) => {
              if (val) {
                setDate(val);
              }
            }}
            // Not needed with @mui/x-date-pickers@6
            // renderInput={(params) => <TextField {...params} />}
            minDate={moment(patient?.created)}
            displayStaticWrapperAs="desktop"
            disableFuture
            views={['day']}
          />
        </Box>
      </Box>
      <Stack flex={1} overflow="hidden" spacing={4}>
        <Box overflow="auto">
          <Stack spacing={4}>
            <Stack className="patient-journal-title" spacing={1}>
              <Typography variant="h5">{date.format('LL')}</Typography>
              {!!journal && (
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    entry:
                      journal.properties.audioTranscript ||
                      journal.properties.entry ||
                      '',
                  }}
                  onSubmit={handleFormikSubmit}
                >
                  <Form className="formik-flex">
                    <Stack flexDirection="column" spacing={2} width="100%">
                      <Field name="entry">
                        {({ field }: FieldProps) => (
                          <TextField
                            className="edit-journal"
                            multiline
                            maxRows={50}
                            variant="outlined"
                            fullWidth
                            {...field}
                          />
                        )}
                      </Field>

                      <AudioJournalButtons journal={journal} />

                      <Button type="submit" variant="outlined">
                        {t('home.journal.update')}
                      </Button>
                    </Stack>
                  </Form>
                </Formik>
              )}
            </Stack>
            {hasCarePriorityResponseNotes && (
              <Stack className="patient-journal-carePriority" spacing={1}>
                <Typography variant="h6">
                  {t('content.journal.carePriority')}
                </Typography>
                <Card>
                  <Stack spacing={2}>
                    {_.map(
                      carePriorityResponses,
                      (carePriorityResponse) =>
                        !!carePriorityResponse.properties.note && (
                          <Stack
                            direction="row"
                            key={carePriorityResponse.id}
                            className="patient-journal-carePriority-content"
                          >
                            <Typography variant="body1" noWrap>
                              {`${_.get(
                                _.get(
                                  symptomPrioritiesById,
                                  carePriorityResponse.properties.priorityID
                                ),
                                'properties.name'
                              )} - `}
                            </Typography>
                            <Box flex={1}>
                              <Typography variant="body2">
                                {carePriorityResponse.properties.note}
                              </Typography>
                            </Box>
                          </Stack>
                        )
                    )}
                  </Stack>
                </Card>
              </Stack>
            )}
          </Stack>
        </Box>
        {!date.isSame(startOfDay, 'day') && (
          <Stack flex={1}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setDate(startOfDay)}
            >
              {t('common.back')}
            </Button>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
