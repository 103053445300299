import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/OtherCareIssues.svg';

export function OtherCareIssues(): JSX.Element {
  return (
    <ResourceGroup
      titleId="content.resources.other_care_issues.title"
      infoId="content.resources.other_care_issues.info"
      type="OtherCareIssues"
      Icon={Icon}
    />
  );
}
