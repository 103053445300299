import { Stack, Typography } from '@mui/material';
import { PropsWithRef } from 'react';

// import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import ThumbDownIcon from '@mui/icons-material/ThumbDown';
// import { useTranslation } from 'react-i18next';

export interface CarePriorityTipProps {
  tip: string;
}

export function CarePriorityTip({
  tip,
}: PropsWithRef<CarePriorityTipProps>): JSX.Element {
  // const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="start"
      className="patient-priority-history-item-care-tip"
    >
      <Typography variant="body1" flexGrow={1}>
        {tip}
      </Typography>

      {/* <Stack direction="row" alignItems="center" flexShrink={0}>
        <Typography variant="body2" flexShrink={0}>
          {t('content.history.care_priority_history.tip_help')}
        </Typography>

        <IconButton color="primary" onClick={() => alert('TODO!')}>
          <ThumbUpIcon />
        </IconButton>

        <IconButton color="primary" onClick={() => alert('TODO!')}>
          <ThumbDownIcon />
        </IconButton>
      </Stack> */}
    </Stack>
  );
}
