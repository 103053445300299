import { useTranslation } from 'react-i18next';
import { Link, Stack, Typography } from '@mui/material';
import { COLOURS } from 'ecarepd-shared-utilities';

export default function Donate(): JSX.Element {
  const { t } = useTranslation();
  const donateLink =
    'https://alumni.uottawa.ca/donation-form?fid=Xwkc%2bU6o4xE%3d&fdesc=KNMFA1WGbByEFlpOUdcbF8Ve4%2fgVJKajSkZhF%2f0J2yE%3d';

  return (
    <Stack
      alignItems="center"
      spacing={2}
      alignSelf="center"
      justifyContent="center"
      maxWidth={600}
      marginBottom={10}
      gap={2}
    >
      <Typography variant="h6" color="primary">
        {t('donate.header')}
      </Typography>
      <Typography>{t('donate.text')}</Typography>
      <Link
        underline="none"
        border="solid"
        borderRadius={2}
        borderColor={COLOURS.Primary}
        paddingX={10}
        paddingY={2}
        target="_blank"
        href={donateLink}
      >
        {t('donate.donate_now')}
      </Link>
    </Stack>
  );
}
