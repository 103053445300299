import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/MaintainingMeds.svg';

export function MaintainingMeds(): JSX.Element {
  return (
    <ResourceGroup
      titleId="content.resources.maintaining_meds.title"
      infoId="content.resources.maintaining_meds.info"
      Icon={Icon}
    />
  );
}
