import { Stack, Typography, Card, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  PatientService,
  useApiCallWithMessageBar,
  useResponseCareTipsByDate,
  ResponseCareTip,
  CommonDataContext,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';
import _ from 'lodash';
import _fp from 'lodash/fp';
import { useContext, Fragment, useMemo } from 'react';
import { ReactComponent as Positive } from '../../assets/Positive.svg';
import { ReactComponent as Negative } from '../../assets/Negative.svg';

export default function CareTipRatings(): JSX.Element {
  const { t } = useTranslation();
  const { patient, patientPriorities } = useContext(DataContext);
  const { symptomPrioritiesById } =
    useContext<CommonDataContextProps>(CommonDataContext);

  let responseCareTips = useResponseCareTipsByDate(
    moment().startOf('day').subtract(1, 'year').toISOString(),
    moment().endOf('day').toISOString()
  );

  responseCareTips = useMemo(() => {
    return _fp.flow([
      _fp.orderBy(['created'], ['desc']),
      _fp.partition((ct: ResponseCareTip) =>
        _.includes(
          patientPriorities?.properties.priorityIDs || [],
          ct.properties.priorityID
        )
      ),
      ([a, b]) => {
        return [...a, ...b];
      },
      _fp.groupBy('properties.priorityID'),
      _fp.values,
    ])(responseCareTips);
  }, [patientPriorities, responseCareTips]);

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (responseCareTip: ResponseCareTip, rating: 0 | 1 | -1) =>
      PatientService.setResponseCareTip({
        ...responseCareTip.properties,
        rating,
      }),
    success: {
      message: t('common.success'),
    },
    failure: {
      message: t('common.failure'),
    },
  });
  return (
    <Card className="care-tip-rating-wrapper">
      <Typography paddingTop={4} variant="h5">
        {t('call_request.rating.recent')}
      </Typography>
      <Typography paddingBottom={4}>
        {t('call_request.rating.recent_subheader')}
      </Typography>

      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
      >
        {_.map(responseCareTips, (priority: any) => {
          const symptom = _.get(
            symptomPrioritiesById,
            priority[0]?.properties?.priorityID
          );

          return (
            <Fragment key={symptom?.id}>
              <Typography paddingLeft={2} variant="h6">
                {symptom?.properties?.name}
              </Typography>

              {_.map(priority, (p: any) => {
                return (
                  <Card key={p.id} className="care-tip-rating">
                    <Typography
                      fontSize={18}
                      fontStyle="italic"
                      flexBasis="0"
                      flexGrow="3"
                    >
                      {p?.properties?.tip}
                    </Typography>

                    <Stack flexDirection="row" padding={2} alignItems="center">
                      <Positive
                        className={
                          p?.properties?.rating === 1
                            ? 'selected-svg'
                            : 'unselected-svg'
                        }
                        onClick={() =>
                          onSubmit(p, p?.properties?.rating === 1 ? 0 : 1)
                        }
                      />

                      <Negative
                        className={
                          p?.properties?.rating === -1
                            ? 'selected-svg'
                            : 'unselected-svg'
                        }
                        onClick={() =>
                          onSubmit(p, p?.properties?.rating === -1 ? 0 : -1)
                        }
                      />

                      <Button
                        variant="contained"
                        color="secondary"
                        className={
                          p?.properties?.rating === 0
                            ? 'selected-button'
                            : 'unselected-button'
                        }
                        onClick={() => onSubmit(p, 0)}
                      >
                        {t('call_request.rating.rating')}
                      </Button>
                    </Stack>
                  </Card>
                );
              })}
            </Fragment>
          );
        })}
      </Stack>
    </Card>
  );
}
