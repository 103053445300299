import { COLOURS } from './colours';
import { fontFamily } from './constants';
const variants = [
    {
        props: { variant: 'selectable' },
        style: {
            minWidth: 'unset',
            padding: 0,
            fontWeight: 'lighter',
        },
    },
    {
        props: { variant: 'selectableSelected' },
        style: {
            minWidth: 'unset',
            padding: 0,
            fontWeight: 'bold',
        },
    },
];
const styleOverrides = {
    root: Object.assign(Object.assign({}, fontFamily), { '.patient-chart-footer .priority-toggle &': {
            padding: 'unset',
        }, '.navigation &': {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '21px',
            color: COLOURS.DarkGrey,
            textTransform: 'none',
        } }),
};
export const MuiButton = {
    variants,
    styleOverrides,
};
