import { spacing } from './constants';
const styleOverrides = {
    paper: {
        borderRadius: 8,
        margin: spacing * 4,
        padding: spacing * 4,
        '.year-selector .MuiMenu-paper&': {
            maxHeight: '300px',
        },
    },
};
export const MuiPopover = {
    styleOverrides,
};
