import { useInstancesByQuery } from 'hive-react-utils';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import fp from 'lodash/fp';
import { mergeObjCollectionBy } from '../utils';
import { useTranslation } from 'react-i18next';
// convertion required to have (value, index, collection) for the functional map function instead of only (value)
// eslint-disable-next-line @typescript-eslint/naming-convention
const _fp = fp.convert({ cap: false });
const DATE_FORMAT = 'MMM DD';
const STEPS_GOAL = 10000;
const ASLEEP_DURATION_GOAL = 540; // 540 minutes -> 9 hours
const ASLEEP_DURATION_MIN_GOAL = 300; // 300 minutes -> 5 hours
function generateEmptyStats(startDate, endDate) {
    const numberOfDays = moment(endDate).diff(moment(startDate), 'days');
    return _fp.flow([
        _fp.times((i) => {
            const m = moment(startDate)
                .startOf('day')
                .add(i + 1, 'day');
            return {
                [m.toISOString()]: [
                    { properties: { date2: m.toISOString(), date: m.toISOString() } },
                ],
            };
        }),
        _fp.assignInAll,
    ])(numberOfDays);
}
function extractPriorityIds(priorityResponses) {
    return _fp.flow([
        // This comment is to prevent the prettier to complain about this multi-line flow (for readability!)
        _fp.groupBy('properties.priorityID'),
        _fp.keys,
    ])(priorityResponses);
}
export function useStatsFromPriorityResponses(priorityResponses, emptyStats) {
    const [stats, setStats] = useState([]);
    const [priorityIds, setPriorityIds] = useState([]);
    useEffect(() => {
        if (!priorityResponses ||
            _.get(priorityResponses, 'status') !== "done" /* ContentObserver.status.DONE */) {
            return;
        }
        // get the priority ids
        const extractedPriorityIds = extractPriorityIds(priorityResponses);
        setPriorityIds(extractedPriorityIds);
        // Now that we have extracted the priority names, we can continue processing the data
        const processed = _fp.flow([
            // Make sure the date2 properties are all at the start of the day
            _fp.map((p) => {
                const date = moment(p.properties.date2)
                    .startOf('day')
                    .utc(true)
                    .toISOString();
                return _.set(p, 'properties.date2', date);
            }),
            // Group by date and format as "MonthName Day"
            _fp.groupBy('properties.date2'),
            // Merge over the empty stats so that we have an entry for every day, even if there is no data
            (s) => _.merge({}, emptyStats, s),
            _fp.mapKeys((_ignored, d) => moment.utc(d).format(DATE_FORMAT)),
            // Reformat values as a single entry with each datapoint as a single item
            _fp.mapValues(_fp.flow([
                _fp.map((p) => {
                    var _a, _b;
                    return ({
                        // The timestamp will be important for sorting!
                        timestamp: moment.utc((_a = p === null || p === void 0 ? void 0 : p.properties) === null || _a === void 0 ? void 0 : _a.date2).unix(),
                        [((_b = p === null || p === void 0 ? void 0 : p.properties) === null || _b === void 0 ? void 0 : _b.priorityID) || '']: {
                            id: p.id,
                            value: p.properties.response,
                            note: p.properties.note,
                        },
                    });
                }),
                _fp.assignAll,
            ])),
            // Now reformat so that the date is part of each entry
            _fp.map((values, date) => {
                return Object.assign({ date }, values);
            }),
            // Since the previous map is on an object, we are never sure of the order. Remember that timestamp?
            // We now use it for sorting
            _fp.sortBy('timestamp'),
        ])(priorityResponses);
        // TODO: change today's date to 'Today', if possible. Chart might order differently
        setStats(processed);
    }, [priorityResponses, emptyStats]);
    return [stats, priorityIds];
}
export function useStatsFromActivities(activities, emptyStats) {
    const [stats, setStats] = useState([]);
    useEffect(() => {
        // Now that we have extracted the priority names, we can continue processing the data
        const processed = _fp.flow([
            // Group by date and format as "MonthName Day"
            _fp.groupBy('properties.date'),
            // Merge over the empty stats so that we have an entry for every day, even if there is no data
            (s) => _.merge({}, emptyStats, s),
            _fp.mapKeys((_ignored, d) => moment.utc(d).format(DATE_FORMAT)),
            // Reformat values as a single entry with each datapoint as a single item
            _fp.mapValues(_fp.flow([
                _fp.map((activity) => ({
                    // The timestamp will be important for sorting!
                    timestamp: moment.utc(activity === null || activity === void 0 ? void 0 : activity.properties.date).unix(),
                    activity: {
                        id: activity.id,
                        rawValue: activity.properties.steps,
                        value: _.clamp(_.toNumber(activity.properties.steps) / STEPS_GOAL, 1) *
                            4, // 100% goal is hardcoded to 10 000 steps
                    },
                })),
                _fp.assignAll,
            ])),
            // Now reformat so that the date is part of each entry
            _fp.map((values, date) => {
                return Object.assign({ date }, values);
            }),
            // Since the previous map is on an object, we are never sure of the order. Remember that timestamp?
            // We now use it for sorting
            _fp.sortBy('timestamp'),
        ])(activities);
        // TODO: change today's date to 'Today', if possible. Chart might order differently
        setStats(processed);
    }, [activities, emptyStats]);
    return stats;
}
export function useStatsFromSleeps(sleeps, emptyStats) {
    const [stats, setStats] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        // Now that we have extracted the priority names, we can continue processing the data
        const processed = _fp.flow([
            // Group by date and format as "MonthName Day"
            _fp.groupBy('properties.date'),
            // Merge over the empty stats so that we have an entry for every day, even if there is no data
            (s) => _.merge({}, emptyStats, s),
            _fp.mapKeys((_ignored, d) => moment.utc(d).format(DATE_FORMAT)),
            // Reformat values as a single entry with each datapoint as a single item
            _fp.mapValues(_fp.flow([
                _fp.map((sleep) => ({
                    // The timestamp will be important for sorting!
                    timestamp: moment.utc(sleep === null || sleep === void 0 ? void 0 : sleep.properties.date).unix(),
                    sleep: {
                        id: sleep.id,
                        rawValue: t('content.patient_chart.sleep_raw', {
                            hours: moment
                                .duration(sleep.properties.minutesAsleep, 'm')
                                .hours(),
                            minutes: moment
                                .duration(sleep.properties.minutesAsleep, 'm')
                                .subtract(moment
                                .duration(sleep.properties.minutesAsleep, 'm')
                                .hours(), 'h')
                                .minutes(),
                        }),
                        value: _.clamp((sleep.properties.minutesAsleep - ASLEEP_DURATION_MIN_GOAL) /
                            (ASLEEP_DURATION_GOAL - ASLEEP_DURATION_MIN_GOAL), 0, 1) * 4, // goal is hardcoded to 0%: 5 hours, 100%: 9 hours
                    },
                })),
                _fp.assignAll,
            ])),
            // Now reformat so that the date is part of each entry
            _fp.map((values, date) => {
                return Object.assign({ date }, values);
            }),
            // Since the previous map is on an object, we are never sure of the order. Remember that timestamp?
            // We now use it for sorting
            _fp.sortBy('timestamp'),
        ])(sleeps);
        // TODO: change today's date to 'Today', if possible. Chart might order differently
        setStats(processed);
    }, [sleeps, emptyStats, t]);
    return stats;
}
export function useStatsByPatientId(patientId, startDate, endDate) {
    const emptyStats = useMemo(() => generateEmptyStats(startDate, endDate), [startDate, endDate]);
    const priorityResponses = useInstancesByQuery('ecarepd', 'getPatientPriorityResponses', 'patientPriorityResponse', {
        debounce: true,
        debounceWait: 2000,
        debounceMaxWait: 15000,
    }, patientId, moment.utc(startDate).toISOString(), moment.utc(endDate).toISOString());
    const activities = useInstancesByQuery('ecarepd', 'activityByPatientIdDateInterval', 'activity', {}, patientId, moment.utc(startDate).toISOString(), moment.utc(endDate).toISOString());
    const sleeps = useInstancesByQuery('ecarepd', 'sleepByPatientIdDateInterval', 'sleep', {}, patientId, moment.utc(startDate).toISOString(), moment.utc(endDate).toISOString());
    const [priorityResponsesStats, priorityIds] = useStatsFromPriorityResponses(priorityResponses, emptyStats);
    const activitiesStats = useStatsFromActivities(activities, emptyStats);
    const sleepsStats = useStatsFromSleeps(sleeps, emptyStats);
    const stats = useMemo(() => mergeObjCollectionBy([priorityResponsesStats, activitiesStats, sleepsStats], 'date'), [activitiesStats, priorityResponsesStats, sleepsStats]);
    return [stats, priorityIds];
}
