import _ from 'lodash';
import fp from 'lodash/fp';
const fpMerge = (sources) => _.merge(_.first(sources), ..._.slice(sources, 1));
export const mergeObjCollectionBy = (sources, iteratee) => _.flow([
    // This comment is to prevent the prettier to complain about this multi-line flow (for readability!),
    fp.map(fp.keyBy(iteratee)),
    fpMerge,
    fp.values,
    fp.sortBy('timestamp'),
])(sources);
