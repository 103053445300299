import _fp from 'lodash/fp';
import { useCallback, useMemo, useState } from 'react';
import { DialogContent, IconButton } from '@mui/material';
import {
  DialogTitleWithClose,
  Journal,
  useFeatureFlags,
} from 'ecarepd-shared-utilities';
import PercentIcon from '@mui/icons-material/Percent';
import { LoggedDialog } from 'hive-analytics-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export interface AudioJournalVoiceScoreProps {
  journal: Journal;
}

function AudioJournalVoiceScoreInternal({
  journal,
}: AudioJournalVoiceScoreProps): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const score = useMemo(
    () =>
      _fp.flow([
        _fp.get('properties.voiceScore.inference'),
        _fp.map(
          _fp.flow([
            _fp.pick(['type', 'score', 'voiceFeatures']),
            _fp.mapValues((v) => _.get(v, 'value', v)),
          ])
        ),
      ])(journal),
    [journal]
  );

  return (
    <>
      <LoggedDialog
        dialogName="audio-journal-voice-score"
        open={open}
        onClose={onClose}
        fullWidth
      >
        <DialogTitleWithClose onClose={onClose}>
          {t('home.journal.score.title')}
        </DialogTitleWithClose>

        <DialogContent dividers>
          <pre>{JSON.stringify(score || '', null, 2)}</pre>
        </DialogContent>
      </LoggedDialog>

      <IconButton
        color="primary"
        className="audio-journal-button"
        onClick={onOpen}
      >
        <PercentIcon />
      </IconButton>
    </>
  );
}

export function AudioJournalVoiceScore({
  journal,
}: AudioJournalVoiceScoreProps): JSX.Element {
  const featureFlags = useFeatureFlags();

  if (!featureFlags.SONDE || !journal?.properties.voiceScore) {
    return <></>;
  }
  return <AudioJournalVoiceScoreInternal journal={journal} />;
}
