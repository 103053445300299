import { COLOURS } from './colours';
import { spacing } from './constants';
const styleOverrides = {};
export const MuiAccordion = {
    styleOverrides,
};
const summaryStyleOverrides = {
    root: {
        '.patient-priority-history-item &, .patient-resources-item &': {
            borderRadius: '4px',
            backgroundColor: COLOURS.LightBlueBackground,
            minHeight: 'unset',
            '& .MuiTypography-root': {
                fontStyle: 'italic',
                fontWeight: 500,
                fontSize: '30px',
                lineHeight: '22px',
                letterSpacing: '-0.72px',
                color: COLOURS.RoyalBlue,
            },
        },
        '.patient-resources-item &': {
            minHeight: '70px',
        },
        '.patient-resource-summary-icon': {
            width: '36px',
            height: 'auto',
            transition: 'all 150ms ease-out',
            '.Mui-expanded &': {
                width: '54px',
                transition: 'all 150ms ease-out',
            },
        },
    },
    content: {
        '.patient-priority-history-item &, .patient-resources-item &': {
            margin: `${spacing * 3}px 0`,
            '&.Mui-expanded': {
                margin: `${spacing * 3}px 0`,
            },
        },
    },
    expandIconWrapper: {
        '.patient-priority-history-item &, .patient-resources-item &': {
            color: COLOURS.RoyalBlue,
        },
    },
};
export const MuiAccordionSummary = {
    styleOverrides: summaryStyleOverrides,
};
const detailsStyleOverrides = {
    root: {
        paddingRight: `${spacing * 7}px`,
        paddingLeft: `${spacing * 7}px`,
        '.patient-priority-history-item &': {
            '& .MuiTypography-h4': {
                marginTop: `${spacing * 3}px`,
                fontStyle: 'italic',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '24px',
                color: COLOURS.DarkGrey,
            },
            '& .patient-priority-history-item-care-tip': {
                padding: `${spacing * 4}px`,
                backgroundColor: COLOURS.LightBlueBackground,
                borderRadius: '4px',
            },
            '& .patient-priority-history-item-care-tip .MuiTypography-body1': {
                fontStyle: 'italic',
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '22px',
                color: COLOURS.DarkerGrey,
            },
            '& .patient-priority-history-item-care-tip .MuiTypography-body2': {
                fontStyle: 'italic',
                fontWeight: 400,
                fontSize: '13px',
                lineHeight: '22px',
                color: COLOURS.DarkGrey,
            },
            '& .MuiLink-root': {
                marginTop: `${spacing * 3}px`,
            },
        },
        '.patient-resources-item &': {
            fontStyle: 'italic',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '24px',
            backgroundColor: COLOURS.LightBlueBackground,
        },
    },
};
export const MuiAccordionDetails = {
    styleOverrides: detailsStyleOverrides,
};
