import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { ResponsiveContainer, ComposedChart, CartesianGrid, Tooltip, Line, XAxis, YAxis, Bar, } from 'recharts';
import { ScaleSvgImage } from '../ScaleSvgImage';
import { CustomDotShapesOrder, CustomDot } from './CustomDot';
import _ from 'lodash';
import _fp from 'lodash/fp';
import moment from 'moment';
import { ChartLegend } from './ChartLegend';
import { COLOURS, spacing } from '../../theme';
import { ScaleValueResponses } from '../ScaleConstants';
export function ChartBody({ stats, priorityIds, visiblePriorities, visibleWearableDatas, }) {
    const processedStats = useMemo(() => {
        return _.map(stats, _fp.mapValues((v) => {
            if (typeof v !== 'object') {
                return v;
            }
            return Object.assign(Object.assign({}, v), { value: v.value === -1 ? null : v.value });
        }));
    }, [stats]);
    return (
    // TODO: Figure out why this takes more space that it should vertically.
    // NOTE: The Responsive container might not resize propertly with React@18. Hopefully, by the time the
    // product is release, this will be fixed. Otherwise, we might have to use React@17
    // https://github.com/recharts/recharts/issues/2831
    _jsx(ResponsiveContainer, { children: _jsxs(ComposedChart, Object.assign({ data: processedStats, margin: {
                top: spacing * 3,
                bottom: spacing * 2,
                left: spacing * -6,
                right: spacing * 4,
            } }, { children: [_jsx(CartesianGrid, { horizontal: true, vertical: false }), _jsx(Tooltip, { content: (props) => _jsx(ChartLegend, Object.assign({}, props)) }), _.map(['activity', 'sleep'], (id) => {
                    const hide = !visibleWearableDatas.has(id);
                    const color = COLOURS.ChartWearable[id];
                    return (_jsx(Bar, { hide: hide, dataKey: `${id}.value`, fill: color, radius: [10, 10, 0, 0], maxBarSize: 20 }));
                }), _.map(priorityIds, (id, index) => {
                    const hide = !visiblePriorities.has(id);
                    const color = COLOURS.Chart[index % COLOURS.Chart.length];
                    const shape = CustomDotShapesOrder[index % CustomDotShapesOrder.length];
                    return (_jsx(Line, { hide: hide, r: 6, connectNulls: true, isAnimationActive: false, dataKey: `${id}.value`, fill: color, stroke: color, strokeWidth: 2, activeDot: false, dot: _jsx(CustomDot, { shape: shape }) }, id));
                }), _jsx(XAxis, { dataKey: "timestamp", tickMargin: 16, tickFormatter: (value) => moment.unix(value).utc().format('MMM DD') }), _jsx(YAxis, { domain: [ScaleValueResponses.VerySad, ScaleValueResponses.VeryHappy], tickCount: 5, tick: (props) => ScaleSvgImage(Object.assign(Object.assign({}, props), { width: 32, height: 32 })) })] })) }));
}
