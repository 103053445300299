import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, Link } from '@mui/material';
import _ from 'lodash';
import htmlReactParser from 'html-react-parser';
import { CallRequestDialog } from '../callRequest/CallRequestDialog';
import { useNavigate } from 'react-router-dom';
import { PATH_TRACKING } from '../../content/constants';
import { useState, PropsWithRef, useContext } from 'react';
import { DataContext } from '../../contexts/DataContext';
import { Priority } from './CarePriority';

export interface CarePrioritiesProps {
  handleOpen: (v: boolean) => void;
}

export default function CarePriorities({
  handleOpen,
}: PropsWithRef<CarePrioritiesProps>): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientPriorities: selectedPriorities } = useContext(DataContext);
  const [showNeedHelp, setShowNeedHelp] = useState(false);

  return (
    <Stack
      id="walkthrough-care-priorities"
      flexBasis="0"
      flexGrow="4"
      spacing={2}
    >
      <CallRequestDialog
        open={showNeedHelp}
        onClose={() => setShowNeedHelp(false)}
      />

      <Stack
        alignItems="baseline"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h5">{t('home.care_priorities.header')}</Typography>
        <Button variant="text" onClick={() => handleOpen(false)}>
          {t('home.care_priorities.change')}
        </Button>
      </Stack>
      <Stack spacing={4}>
        {selectedPriorities?.properties?.priorities &&
        !_.isEmpty(selectedPriorities.properties?.priorities) ? (
          <>
            {_.map(selectedPriorities.properties.priorities, (priority) => (
              <div key={priority.id}>
                <Priority symptomPriority={priority} />
              </div>
            ))}
            <Button variant="outlined" onClick={() => navigate(PATH_TRACKING)}>
              {t('home.care_priorities.complete')}
            </Button>
          </>
        ) : (
          <>
            <Typography>
              {htmlReactParser(t('home.care_priorities.no_priorities'))}
            </Typography>
            <Button variant="outlined" onClick={() => handleOpen(false)}>
              {t('home.care_priorities.select')}
            </Button>
          </>
        )}
        <Link
          alignSelf="center"
          underline="none"
          fontSize="large"
          variant="body2"
          onClick={() => setShowNeedHelp(true)}
        >
          {t('home.care_priorities.help')}
        </Link>
      </Stack>
    </Stack>
  );
}
