import { Button, Stack } from '@mui/material';
import { PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';
import { spacing } from 'ecarepd-shared-utilities';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export interface CarePrioritiesActionsProps {
  tabValue: number;
  initialSetup: boolean;
  alphabeticalGroupings: any;
  typeIndex: number;
  handleSubmit: () => void;
  setTabValue: (v: number) => void;
  setTypeIndex: (v: number) => void;
}

export default function CarePrioritiesActions({
  tabValue,
  initialSetup,
  handleSubmit,
  setTabValue,
  setTypeIndex,
  typeIndex,
  alphabeticalGroupings,
}: PropsWithRef<CarePrioritiesActionsProps>): JSX.Element {
  const { t } = useTranslation();

  if (tabValue === 0) {
    return (
      <Button variant="outlined" onClick={handleSubmit} className="blue-button">
        {t('common.done')}
      </Button>
    );
  }

  return (
    <>
      <Stack flexDirection="row" justifyContent="center" gap={spacing}>
        <Button
          variant="outlined"
          onClick={() => {
            if (typeIndex === 0 || !initialSetup) {
              setTabValue(0);
            } else {
              setTypeIndex(typeIndex - 1);
            }
          }}
          startIcon={<ArrowBack />}
        >
          {t('common.back')}
        </Button>

        {alphabeticalGroupings.length - 1 !== typeIndex && initialSetup && (
          <Button
            variant="outlined"
            onClick={() => setTypeIndex(typeIndex + 1)}
            endIcon={<ArrowForward />}
          >
            {t('common.next')}
          </Button>
        )}
        {alphabeticalGroupings.length - 1 === typeIndex && initialSetup && (
          <Button onClick={handleSubmit} className="blue-button">
            {t('common.done')}
          </Button>
        )}
      </Stack>
    </>
  );
}
