import { useHiveConfig } from 'hive-react-utils';
import { useMemo } from 'react';
function generateClientId(config) {
    const match = /^([a-fA-F0-9]{8})-([a-fA-F0-9]{4})-([a-fA-F0-9]{4})-([a-fA-F0-9]{4})-([a-fA-F0-9]{12})$/.exec(config.orgId);
    if (!match) {
        return;
    }
    const [, id] = match;
    return `${id}-${config.appId}`;
}
export function useForgotPasswordUri() {
    const config = useHiveConfig();
    const uri = useMemo(() => {
        if (!(config === null || config === void 0 ? void 0 : config.host) || !(config === null || config === void 0 ? void 0 : config.orgId) || !(config === null || config === void 0 ? void 0 : config.appId) || !(config === null || config === void 0 ? void 0 : config.orgName)) {
            return;
        }
        const clientId = generateClientId(config);
        return `${config.host}/keycloak/realms/hive-${config.orgName}/login-actions/reset-credentials?client_id=${clientId}`;
    }, [config]);
    return uri;
}
