import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../../contexts/DataContext';
import _ from 'lodash';
import { AnalyticsService } from 'hive-analytics-react';
import { Resource } from 'ecarepd-shared-utilities';
import { ReactComponent as ButtonIcon } from '../../../assets/resources/Doc Icon.svg';
import { PdfDialog } from '../../../components/dialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ResourceGroupProps {
  titleId: string;
  infoId?: string;
  type?: string;
  Icon?: FunctionComponent<any>;
  columns?: number;
}

export function ResourceGroup({
  children,
  titleId,
  infoId,
  type,
  Icon,
  columns = 2,
}: PropsWithChildren<ResourceGroupProps>): JSX.Element {
  const { t } = useTranslation();

  const { resourcesByType } = useContext(DataContext);
  const resources = useMemo(
    () => _.get(resourcesByType, type || '', []),
    [resourcesByType, type]
  );

  const [blobId, setBlobId] = useState('');
  const [title, setTitle] = useState('');

  const showPdf = useCallback((r: Resource) => {
    AnalyticsService.logClick('SHOW-PDF', r.properties);
    setBlobId(r.properties.blobId);
    setTitle(r.properties.title);
  }, []);

  const onClose = useCallback(() => {
    setBlobId('');
    setTitle('');
  }, []);

  return (
    <>
      <PdfDialog
        open={!!blobId}
        blobId={blobId}
        title={title}
        onClose={onClose}
      />

      <Accordion className="patient-resources-item">
        <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            paddingRight={40}
          >
            {Icon && <Icon className="patient-resource-summary-icon" />}
            {titleId && <Typography variant="h5">{t(titleId)}</Typography>}
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack spacing={2}>
            {infoId && (
              <Typography flexBasis={0} flexGrow={1}>
                {t(infoId)}
              </Typography>
            )}

            <Stack
              display="grid"
              gridTemplateColumns={`repeat(${columns}, 1fr)`}
              spacing={1}
            >
              {_.map(resources, (r: Resource) => (
                <Button
                  startIcon={<ButtonIcon />}
                  key={r.id}
                  onClick={() => showPdf(r)}
                >
                  {r.properties.title}
                </Button>
              ))}
            </Stack>

            {children}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
