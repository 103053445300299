import { PropsWithRef, useMemo, useState } from 'react';
import { Button, Stack, Typography, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
  CareTeamMemberType,
  useCareTeamMembers,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { CareTeamMemberCard } from './CareTeamMemberCard';
import { CareTeamDialog, CareTeamDialogMode } from './CareTeamDialog';

export interface PhysicianProps {
  type: CareTeamMemberType;
}

export function CareTeamMembers({
  type,
}: PropsWithRef<PhysicianProps>): JSX.Element {
  const careTeamMembers = useCareTeamMembers();
  const [showCareTeamDialog, setShowCareTeamDialog] = useState(false);
  const showDialog = () => {
    setShowCareTeamDialog(true);
  };
  const { t } = useTranslation();

  const typeLabel = useMemo(() => {
    switch (type) {
      case CareTeamMemberType.PHYSICIAN_AND_NURSE:
        return t('content.care_team.physicians');
      case CareTeamMemberType.ALLIED_CARE_PROFESSIONAL:
        return t('content.care_team.allied_professionals');
    }
  }, [t, type]);

  const members = useMemo(
    () =>
      _.filter(careTeamMembers, (careTeamMember) => {
        return careTeamMember.properties.careTeamMemberType === type;
      }),
    [careTeamMembers, type]
  );

  return (
    <div>
      <CareTeamDialog
        mode={CareTeamDialogMode.Add}
        open={showCareTeamDialog}
        onClose={() => setShowCareTeamDialog(false)}
        careTeamMemberType={type}
      />
      <Stack spacing={0}>
        <Stack direction="row" spacing={4} className="care-team">
          <Typography variant="h6">{typeLabel}</Typography>

          <Button onClick={showDialog} startIcon={<AddIcon fontSize="small" />}>
            {t('content.care_team.add_new')}
          </Button>
        </Stack>

        {members.length === 0 &&
          type === CareTeamMemberType.PHYSICIAN_AND_NURSE && (
            <Typography align="justify">
              {t('content.care_team.empty_physician')}
            </Typography>
          )}

        {members.length === 0 &&
          type === CareTeamMemberType.ALLIED_CARE_PROFESSIONAL && (
            <Typography align="justify">
              {t('content.care_team.empty_professional')}
            </Typography>
          )}

        <Grid container spacing={6}>
          {_.map(members, (careTeamMember) => (
            <Grid item key={careTeamMember.id} lg={3} sm={6} xs={12}>
              <CareTeamMemberCard careTeamMember={careTeamMember} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </div>
  );
}
