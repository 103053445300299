import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/eCarePD.svg';
import { useTranslation } from 'react-i18next';
import { PropsWithRef } from 'react';
import { Stack, Typography } from '@mui/material';
import htmlReactParser from 'html-react-parser';

interface SectionProps {
  titleId: string;
  infoId: string;
}

function Section({ titleId, infoId }: PropsWithRef<SectionProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t(titleId)}</Typography>
      <Typography>{htmlReactParser(t(infoId))}</Typography>
    </Stack>
  );
}

export function Using(): JSX.Element {
  return (
    <ResourceGroup
      titleId="content.resources.using.title"
      infoId="content.resources.using.info"
      Icon={Icon}
    >
      <Stack spacing={8} paddingTop={4}>
        <Section
          titleId="content.resources.using.content.priorities.title"
          infoId="content.resources.using.content.priorities.info"
        />

        <Section
          titleId="content.resources.using.content.journal.title"
          infoId="content.resources.using.content.journal.info"
        />

        <Section
          titleId="content.resources.using.content.care_team.title"
          infoId="content.resources.using.content.care_team.info"
        />

        <Section
          titleId="content.resources.using.content.care_finder.title"
          infoId="content.resources.using.content.care_finder.info"
        />
      </Stack>
    </ResourceGroup>
  );
}
