import moment from 'moment';
import { useObservableState } from 'hive-react-utils';
import { Observable } from 'rxjs';
const observeStartOfDay = () => {
    return new Observable((subscriber) => {
        let previous = moment().startOf('day');
        subscriber.next(previous);
        const interval = setInterval(() => {
            const now = moment().startOf('day');
            if (!previous.isSame(now)) {
                subscriber.next(now);
                previous = now;
            }
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    });
};
export function useStartOfDay() {
    return useObservableState(observeStartOfDay, moment().startOf('day'));
}
