import { createElement, PropsWithRef, useMemo, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import {
  SymptomPriority,
  scaleValueByResponse,
  PatientPriorityResponse,
} from 'ecarepd-shared-utilities';

import moment from 'moment';
import { NoteDialog } from '../../components/dialog';

export interface CarePriorityScaleItemProps {
  response: PatientPriorityResponse;
  symptomPriority: SymptomPriority;
}
export function CarePriorityScaleItem({
  response,
  symptomPriority,
}: PropsWithRef<CarePriorityScaleItemProps>): JSX.Element {
  const icon = useMemo(() => {
    const r = response.properties.response;
    if (r != null && r >= 0) {
      const scaleValue = scaleValueByResponse(r);
      return createElement(scaleValue.icon);
    }

    // Should not happen
    return null;
  }, [response]);

  const responseId = response.id;

  const [note, setNote] = useState(() => response.properties.note || '');
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);

  return (
    <Stack>
      <NoteDialog
        reason="edit"
        responseId={responseId}
        symptomPriority={symptomPriority}
        open={noteDialogOpen}
        onClose={() => setNoteDialogOpen(false)}
        note={note}
        setNote={setNote}
      />

      <Button disabled={!responseId} onClick={() => setNoteDialogOpen(true)}>
        <Stack direction="row" alignItems="end" spacing={-3}>
          {icon}

          <BookIcon color="primary" visibility={note ? 'visible' : 'hidden'} />
        </Stack>
      </Button>

      <Typography align="center">
        {moment(response.properties.date2).format('MMM D')}
      </Typography>
    </Stack>
  );
}
