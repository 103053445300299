import { Fragment, PropsWithRef, useContext } from 'react';
import { Stack } from '@mui/material';
import { SymptomPriority } from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';
import _ from 'lodash';
import { CarePriorityItem } from './CarePriorityItem';

export interface CarePriorityHistoryProps {
  entryCount: number;
}

export function CarePriorityHistory({
  entryCount,
}: PropsWithRef<CarePriorityHistoryProps>) {
  const { patient, patientSymptomPriorities } = useContext(DataContext);

  return (
    <Stack spacing={2} marginTop={20}>
      {_.map(patientSymptomPriorities, (p: SymptomPriority) => (
        <Fragment key={p.id}>
          <CarePriorityItem
            patient={patient}
            entryCount={entryCount}
            symptomPriority={p}
          />
        </Fragment>
      ))}
    </Stack>
  );
}
