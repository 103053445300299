import { COLOURS } from './colours';
const styleOverrides = {
    input: {
        '.call-request-content &': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '22px',
            lineHeight: '26px',
            letterSpacing: '0.6875px',
            color: COLOURS.DarkerGrey,
        },
    },
};
export const MuiInputBase = {
    styleOverrides,
};
