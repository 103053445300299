import { useInstancesByClassName } from 'hive-react-utils';
import { useMemo } from 'react';
import _ from 'lodash';
export function useResources() {
    return useInstancesByClassName('ecarepd', 'resource', {});
}
export function useResourcesByType() {
    const resources = useResources();
    return useMemo(() => _.groupBy(resources, 'properties.type'), [resources]);
}
