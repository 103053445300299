import {
  Button,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  DialogTitleWithClose,
  SymptomPriority,
} from 'ecarepd-shared-utilities';
import { PropsWithRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import htmlReactParser from 'html-react-parser';
import _ from 'lodash';
import { LoggedDialog } from 'hive-analytics-react';

export interface SymptomDetailsDialogProps {
  symptomPriority: SymptomPriority;
  open: boolean;
  onClose: () => void;
}
export function SymptomDetailsDialog({
  symptomPriority,
  open,
  onClose,
}: PropsWithRef<SymptomDetailsDialogProps>): JSX.Element {
  const { t } = useTranslation();

  // Make sure we replace \r\b and indent bullets (if any)
  const processedInfo = useMemo(
    () =>
      symptomPriority.properties.information
        .replace(/\r\n/g, '<br>')
        .replace(/•/g, '&nbsp;&nbsp;&nbsp;•'),
    [symptomPriority]
  );

  return (
    <LoggedDialog
      dialogName="symptom-priority-details"
      extraData={{
        symptomPriorityId: symptomPriority.id,
        symptomPriorityName: symptomPriority.properties.name,
      }}
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      className="patient-symptom-details"
    >
      <DialogTitleWithClose onClose={onClose}>
        {symptomPriority.properties.name}
      </DialogTitleWithClose>

      <DialogContent dividers className="patient-symptom-details-content">
        <Stack spacing={4}>
          <Typography variant="body1">
            {htmlReactParser(processedInfo)}
          </Typography>

          <Stack direction="row" spacing={2}>
            <Stack flexGrow="0">
              <Typography variant="h6">Tips</Typography>
            </Stack>

            <Stack flexGrow="1" spacing={2}>
              <List>
                {_.map(
                  symptomPriority.properties.careTips,
                  (tip: any, index: number) => {
                    return (
                      <ListItem key={index}>
                        <Typography variant="body2">
                          • {tip.toString()}
                        </Typography>
                      </ListItem>
                    );
                  }
                )}
              </List>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.continue')}</Button>
      </DialogActions>
    </LoggedDialog>
  );
}
