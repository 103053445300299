import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import { Button, Stack } from '@mui/material';
import { useLoginCallback } from 'hive-react-utils';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { AnalyticsService } from 'hive-analytics-react';
const OIDC_PATH = process.env.PUBLIC_URL === '/' ? '/oidc' : `${process.env.PUBLIC_URL}/oidc`;
export function Disconnected() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = useMemo(() => `${OIDC_PATH}?path=${pathname}`, [pathname]);
    const handleLoginInternal = useLoginCallback(path);
    const handleLogin = useCallback(() => {
        AnalyticsService.logClick('DISCONNECTED-SIGN-IN');
        handleLoginInternal();
    }, [handleLoginInternal]);
    return (_jsx(Stack, Object.assign({ flexGrow: "1", justifyContent: "center", alignItems: "center" }, { children: _jsx(Button, Object.assign({ variant: "contained", onClick: handleLogin }, { children: _jsx(_Fragment, { children: t('signin.please_sign_in') }) })) })));
}
