import { Button } from '@mui/material';
import {
  PatientService,
  TextDialog,
  useApiCallWithMessageBar,
} from 'ecarepd-shared-utilities';
import { AnalyticsService } from 'hive-analytics-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function DeleteAccount(): JSX.Element {
  const { t } = useTranslation();

  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);

  const onClose = useCallback(() => {
    setConfirm1(false);
    setConfirm2(false);
  }, []);

  const openConfirm1 = useCallback(() => {
    AnalyticsService.logClick('DELETE_ACCOUNT_CONFIRM1');
    setConfirm1(true);
  }, []);
  const openConfirm2 = useCallback(() => {
    AnalyticsService.logClick('DELETE_ACCOUNT_CONFIRM2');
    setConfirm1(false);
    setConfirm2(true);
  }, []);

  const { progress: deleting, callback: onDeleteAccount } =
    useApiCallWithMessageBar({
      canExecute: () => true,
      execute: PatientService.deleteMyAccount,
      success: {
        snackbarProps: {
          autoHideDuration: 2000,
        },
        message: t('common.success'),
      },
      failure: {
        message: t('common.failure'),
      },
    });

  return (
    <>
      <Button variant="outlined" color="error" onClick={openConfirm1}>
        {t('settings.delete_account1.button')}
      </Button>

      <TextDialog
        open={confirm1}
        disabled={deleting}
        onClose={onClose}
        title={t('settings.delete_account1.title')}
        text={t('settings.delete_account1.message1')}
        text2={t('settings.delete_account1.message2')}
        actions={
          <>
            <Button disabled={deleting} onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button disabled={deleting} onClick={openConfirm2}>
              {t('settings.delete_account1.yes_delete')}
            </Button>
          </>
        }
      />

      <TextDialog
        open={confirm2}
        disabled={deleting}
        onClose={onClose}
        title={t('settings.delete_account2.title')}
        text={t('settings.delete_account2.message1')}
        text2={t('settings.delete_account2.message2')}
        actions={
          <>
            {/* Inverting the continue button for the second dialog */}
            <Button disabled={deleting} onClick={onDeleteAccount}>
              {t('settings.delete_account2.yes_delete')}
            </Button>
            <Button disabled={deleting} onClick={onClose}>
              {t('common.cancel')}
            </Button>
          </>
        }
      />
    </>
  );
}
