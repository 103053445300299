import { ThemeProvider } from '@mui/material';
import {
  useAppVersion,
  useCurrentLanguage,
  useTitleEffect,
} from 'hive-react-utils';
import { useEffect } from 'react';
import { patientTheme } from './theme';
import Main from './content/Main';
import Geocode from 'react-geocode';

import {
  CommonDataContextProvider,
  MessageBarProvider,
} from 'ecarepd-shared-utilities';
import { DataContextProvider } from './contexts/DataContext';
import { ConfirmProvider } from 'material-ui-confirm';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fr';
import moment from 'moment';

const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  (window as any)._env_.REACT_APP_GOOGLE_API_KEY;

export default function App() {
  useTitleEffect('app.title');
  const language = useCurrentLanguage();

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const { version } = useAppVersion();
  useEffect(() => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    console.debug(`App Version: ${version}`);
  }, [version]);

  return (
    <ThemeProvider theme={patientTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MessageBarProvider>
          <CommonDataContextProvider>
            <DataContextProvider>
              <ConfirmProvider>
                <Main />
              </ConfirmProvider>
            </DataContextProvider>
          </CommonDataContextProvider>
        </MessageBarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
