import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PriorityToggle } from './PriorityToggle';
import { CustomDotShapesOrder } from './CustomDot';
import _ from 'lodash';
import { CommonDataContext } from '../../contexts';
import { COLOURS } from '../../theme';
import { WearableDataToggle } from './WearableDataToggle';
export function ChartFooter({ priorityIds, visiblePriorities, visibleWearableDatas, toggleVisiblePriority, toggleVisibleWearableData, carePriorityId, }) {
    const { t } = useTranslation();
    const { symptomPrioritiesById } = useContext(CommonDataContext);
    return (_jsxs(Stack, Object.assign({ direction: "row", paddingRight: 10, paddingLeft: 10, spacing: 8, className: "patient-chart-footer" }, { children: [_jsxs(Stack, Object.assign({ direction: "column", width: "70%", spacing: 2 }, { children: [_jsx(Typography, Object.assign({ variant: "h4", color: "primary" }, { children: t(carePriorityId || 'content.patient_chart.care_priorities') })), _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: _.map(priorityIds, (id, index) => {
                            const color = COLOURS.Chart[index % COLOURS.Chart.length];
                            const shape = CustomDotShapesOrder[index % CustomDotShapesOrder.length];
                            const name = _.get(symptomPrioritiesById, [
                                id,
                                'properties',
                                'name',
                            ]);
                            return (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(PriorityToggle, { name: name, color: color, shape: shape, visible: visiblePriorities.has(id), toggle: () => toggleVisiblePriority(id) }) }), id));
                        }) }))] })), _jsxs(Stack, Object.assign({ width: "30%", spacing: 2 }, { children: [_jsx(Typography, Object.assign({ variant: "h4", color: "primary" }, { children: t('content.patient.wearable_information.title') })), _jsx(Grid, Object.assign({ container: true, spacing: 2 }, { children: _.map(['activity', 'sleep'], (id) => {
                            return (_jsx(Grid, Object.assign({ item: true }, { children: _jsx(WearableDataToggle, { wearableData: id, visible: visibleWearableDatas.has(id), toggle: () => toggleVisibleWearableData(id) }) }), id));
                        }) }))] }))] })));
}
