import { COLOURS } from './colours';
const styleOverrides = {
    root: {
        backgroundColor: COLOURS.White,
        color: COLOURS.DarkBlue,
    },
};
export const MuiAppBar = {
    styleOverrides,
};
