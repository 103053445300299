import { ReactComponent as Mental } from '../../assets/Care Priorities - Mental.svg';
import { ReactComponent as Physical } from '../../assets/Care Priorities - Physical.svg';
import { ReactComponent as Lifestyle } from '../../assets/Care Priorities - Lifestyle.svg';
import { ReactComponent as Other } from '../../assets/Care Priorities - Other.svg';

export const COMPONENTS: any = {
  Mental: Mental,
  Physical: Physical,
  Lifestyle: Lifestyle,
  Other: Other,
  Physique: Physical,
  Modedevie: Lifestyle,
  Autre: Other,
  default: Physical,
};
