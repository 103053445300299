import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DialogTitleWithClose } from 'ecarepd-shared-utilities';
import { LoggedDialog } from 'hive-analytics-react';
import { useTranslation } from 'react-i18next';
import { PropsWithRef } from 'react';

export interface CallRequestNoDataProps {
  open: boolean;
  onClose: () => void;
}

export function CallRequestDialogNoData({
  open,
  onClose,
}: PropsWithRef<CallRequestNoDataProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <LoggedDialog dialogName="HelpDialog" open={open} onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>
        {t('call_request.title')}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <Typography>{t('call_request.no_data')}</Typography>
        <Typography marginTop={2}>
          {t('call_request.no_data_paragraph2')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </LoggedDialog>
  );
}
