import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';
import { STORAGE_HISTORY_ENTRY_COUNT } from '../constants';
import { CarePriorityHistory } from './CarePriorityHistory';
import { CarePriorityHistoryChart } from './CarePriorityHistoryChart';

export default function History(): JSX.Element {
  const { t } = useTranslation();
  const { patient } = useContext(DataContext);

  const [entryCount, setEntryCount] = useState(
    () =>
      parseInt(localStorage.getItem(STORAGE_HISTORY_ENTRY_COUNT) || '5') || 5
  );

  const entryCountChanged = useCallback((event: SelectChangeEvent<number>) => {
    const newEntryCount = event.target.value as number;
    setEntryCount(newEntryCount);
    localStorage.setItem(STORAGE_HISTORY_ENTRY_COUNT, newEntryCount.toString());
  }, []);

  return (
    <Stack flexGrow="1" spacing={2} className="patient-priority-history">
      <Typography variant="h5" color="primary">
        {t('content.history.care_priority_history.title')}
      </Typography>

      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h6" color="primary">
          {t('content.history.care_priority_history.how_you_doing')}
        </Typography>

        <Select
          variant="standard"
          value={entryCount}
          defaultValue={5}
          onChange={entryCountChanged}
        >
          <MenuItem value={5}>
            {t(
              'content.history.care_priority_history.number_of_entries.last_five'
            )}
          </MenuItem>

          <MenuItem value={20}>
            {t(
              'content.history.care_priority_history.number_of_entries.last_twenty'
            )}
          </MenuItem>

          <MenuItem value={50}>
            {t(
              'content.history.care_priority_history.number_of_entries.last_fifty'
            )}
          </MenuItem>

          <MenuItem value={10000}>
            {t('content.history.care_priority_history.number_of_entries.all')}
          </MenuItem>
        </Select>
      </Stack>

      {entryCount === 5 ? (
        <CarePriorityHistory entryCount={entryCount} />
      ) : (
        <CarePriorityHistoryChart patient={patient} entryCount={entryCount} />
      )}
    </Stack>
  );
}
