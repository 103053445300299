import { jsx as _jsx } from "react/jsx-runtime";
import { Circle, Diamond, Hexagon, Pentagon, Square, Triangle } from './shapes';
import { HappyIcon, MehIcon, SadIcon, VeryHappyIcon, VerySadIcon, } from '../icons';
import { ScaleValueNames, ScaleValueResponses } from '../ScaleConstants';
export const CustomDotShapesOrder = [
    'circle',
    'square',
    'triangle',
    'diamond',
    'hexagon',
    'pentagon',
    // Not scale items in this list
];
const SHAPE_BY_NAME = {
    circle: (x, y, width, height) => (_jsx(Circle, { x: x, y: y, width: width, height: height })),
    diamond: (x, y, width, height) => (_jsx(Diamond, { x: x, y: y, width: width, height: height })),
    hexagon: (x, y, width, height) => (_jsx(Hexagon, { x: x, y: y, width: width, height: height })),
    pentagon: (x, y, width, height) => (_jsx(Pentagon, { x: x, y: y, width: width, height: height })),
    square: (x, y, width, height) => (_jsx(Square, { x: x, y: y, width: width, height: height })),
    triangle: (x, y, width, height) => (_jsx(Triangle, { x: x, y: y, width: width, height: height })),
    [ScaleValueNames.VerySad]: (x, y, width, height) => _jsx(VerySadIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueNames.VerySad]: (x, y, width, height) => _jsx(VerySadIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueNames.Sad]: (x, y, width, height) => _jsx(SadIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueResponses.Sad]: (x, y, width, height) => _jsx(SadIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueNames.Meh]: (x, y, width, height) => _jsx(MehIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueResponses.Meh]: (x, y, width, height) => _jsx(MehIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueNames.Happy]: (x, y, width, height) => _jsx(HappyIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueResponses.Happy]: (x, y, width, height) => _jsx(HappyIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueNames.VeryHappy]: (x, y, width, height) => _jsx(VeryHappyIcon, { x: x, y: y, width: width, height: height }),
    [ScaleValueResponses.VeryHappy]: (x, y, width, height) => _jsx(VeryHappyIcon, { x: x, y: y, width: width, height: height }),
};
export function CustomDot(props) {
    // Note: recharts is placing dots, so we receive cx and cy instead of x and y
    let { shape, cx: x, cy: y, r, fill } = props;
    // We get called even when there is no dot to display, usually when y is null
    if (x == null || y == null || r == null) {
        return null;
    }
    const width = r * 2;
    const height = r * 2;
    x -= width / 2;
    y -= height / 2;
    const element = SHAPE_BY_NAME[shape](x, y, width, height);
    return (_jsx("g", Object.assign({ style: { fill, stroke: 'none', color: fill, strokeWidth: 1 } }, { children: element })));
}
