import React, { useMemo, useState, useCallback } from 'react';
import Geocode from 'react-geocode';
import { Stack } from '@mui/material';
import _ from 'lodash';
import CareFinderMap from './CareFinderMap';
import CareFinderSidebar from './CareFinderSideBar';
import CareFinderSearchResults from './CareFinderSearchResults';
import { useCareLocations } from 'ecarepd-shared-utilities';
import { filterByNearest, filterByType, findServices } from './utils';

export default function CareFinder(): JSX.Element {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [sortedLocations, setSortedLocations] = useState<object[]>([]);
  const [addressCoords, setAddressCoords] = useState<number[]>([]);
  const [typedAddress, setTypedAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [errorShown, setErrorShown] = useState(false);
  const [distance, setDistance] = useState(10); // TODO: use local storage
  const [showInHome, setShowInHome] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const locations = useCareLocations();

  const onClick = useCallback(
    (index: string) => {
      if (selectedServices.includes(index)) {
        setSelectedServices(_.filter(selectedServices, (e) => e !== index));
      } else {
        setNoResults(false);
        setSelectedServices((previousPriorities: string[]) => [
          ...previousPriorities,
          index,
        ]);
      }
    },
    [setSelectedServices, selectedServices]
  );

  const setAddress = useCallback((address: string) => {
    setAddressCoords([]); // need to clear while the async is running
    setTypedAddress(address);
    setErrorShown(false);
    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
      (response: {
        results: { geometry: { location: { lat: any; lng: any } } }[];
      }) => {
        const { lat, lng } = response.results[0].geometry.location;
        setAddressCoords([lat, lng]);
        setNoResults(false);
      },
      (error: any) => {
        setAddressError(error.message);
      }
    );
  }, []);

  const onSearch = useCallback(() => {
    setSortedLocations([]);

    if (_.isEmpty(addressCoords) && typedAddress) {
      setErrorShown(true);
      return;
    }

    let filteredLocations = locations || [];
    if (allSelected && !showInHome) {
      filteredLocations = _.filter(
        locations,
        (e) => !_.isEmpty(e.properties?.coords)
      );
    } else if (!allSelected) {
      filteredLocations = filterByType(selectedServices, locations);
    }

    if (!_.isEmpty(addressCoords)) {
      const response = filterByNearest(
        filteredLocations,
        showInHome,
        addressCoords,
        distance
      );
      const nearLocations = _.orderBy(response.near, ['distance'], ['asc']);
      filteredLocations = [...nearLocations, ...response.inHome];
    }
    if (_.isEmpty(filteredLocations)) {
      setNoResults(true);
    } else {
      setSortedLocations(filteredLocations);
    }
  }, [
    selectedServices,
    locations,
    addressCoords,
    distance,
    allSelected,
    showInHome,
    typedAddress,
  ]);

  const refs = useMemo(
    () =>
      _.reduce(
        sortedLocations,
        (acc: any, a: object, index: number) => {
          acc[index] = React.createRef();
          return acc;
        },
        {}
      ),
    [sortedLocations]
  );

  const handleMapIconClick = (id: string) => {
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const services = useMemo(() => findServices(locations), [locations]);

  return (
    <Stack flexDirection="row">
      {_.isEmpty(sortedLocations) ? (
        <CareFinderSidebar
          typedAddress={typedAddress}
          sortedServices={services}
          onClick={onClick}
          onSearch={onSearch}
          setAddress={setAddress}
          distance={distance}
          setDistance={(dist) => {
            setDistance(dist);
            setNoResults(false);
          }}
          selectedServices={selectedServices}
          showInHome={showInHome}
          setShowInHome={() => setShowInHome(!showInHome)}
          allSelected={allSelected}
          setAllSelected={() => {
            setAllSelected((previous) => !previous);
            setSelectedServices([]);
          }}
          noResults={noResults}
          addressError={addressError}
          setAddressError={setAddressError}
          errorShown={errorShown}
          setErrorShown={setErrorShown}
        />
      ) : (
        <CareFinderSearchResults
          distance={distance}
          address={typedAddress}
          refs={refs}
          sortedLocations={sortedLocations}
          onClick={() => setSortedLocations([])}
          selectedServices={selectedServices}
          allSelected={allSelected}
        />
      )}
      <CareFinderMap
        locations={sortedLocations}
        mapIconClick={handleMapIconClick}
      />
    </Stack>
  );
}
