import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import { Stack } from '@mui/material';
import { useStatsByPatientId, useStatsFromActivities, useStatsFromPriorityResponses, useStatsFromSleeps, useSymptomPriorities, } from '../../hooks';
import { ChartFooter } from './ChartFooter';
import { ChartHeader } from './ChartHeader';
import { ChartBody } from './ChartBody';
import moment from 'moment';
import _ from 'lodash';
import { mergeObjCollectionBy } from '../../utils';
const STORAGE_CHART_PERIOD = 'chart-period';
function ChartWithControls({ patient, titleId, displayPeriodId, }) {
    const [period, setPeriod] = useState(() => parseInt(localStorage.getItem(STORAGE_CHART_PERIOD) || '30'));
    const changePeriod = useCallback((event) => {
        const newPeriod = event.target.value;
        setPeriod(newPeriod);
        localStorage.setItem(STORAGE_CHART_PERIOD, newPeriod.toString());
    }, []);
    const startDate = useMemo(() => moment().subtract(period, 'day').startOf('day').utc(true).toISOString(), [period]);
    const [stats, priorityIds] = useStatsByPatientId(patient.properties.patientID, startDate, moment().endOf('day').utc(true).toISOString());
    const initialized = useRef(false);
    const initializedWearable = useRef(false);
    const allPriorities = useSymptomPriorities();
    const [visiblePriorities, setVisiblePriorities] = useState(new Set());
    const [visibleWearableDatas, setVisibleWearableDatas] = useState(new Set());
    useEffect(() => {
        if (initialized.current || _.isEmpty(allPriorities)) {
            return;
        }
        const allPriorityIds = new Set(_.map(allPriorities, (p) => p.id));
        setVisiblePriorities(allPriorityIds);
        initialized.current = true;
    }, [initialized, allPriorities]);
    useEffect(() => {
        if (initializedWearable.current) {
            return;
        }
        setVisibleWearableDatas(new Set(['activity', 'sleep']));
        initializedWearable.current = true;
    }, [initializedWearable]);
    const togglePriorityVisibility = useCallback((id) => {
        if (!initialized) {
            return;
        }
        let updated = new Set(visiblePriorities);
        if (updated.has(id)) {
            updated.delete(id);
        }
        else {
            updated.add(id);
        }
        setVisiblePriorities(updated);
    }, [initialized, visiblePriorities]);
    const toggleWearableDataVisibility = useCallback((id) => {
        if (!initializedWearable) {
            return;
        }
        let updated = new Set(visibleWearableDatas);
        if (updated.has(id)) {
            updated.delete(id);
        }
        else {
            updated.add(id);
        }
        setVisibleWearableDatas(updated);
    }, [initializedWearable, visibleWearableDatas]);
    return (_jsxs(Stack, Object.assign({ direction: "column", height: "100%", spacing: 2, padding: 2, className: "patient-chart" }, { children: [_jsx(ChartHeader, { titleId: titleId, displayPeriodId: displayPeriodId, period: period, changePeriod: changePeriod }), _jsx(Stack, Object.assign({ flexGrow: 1 }, { children: _jsx(ChartBody, { patient: patient, stats: stats, priorityIds: priorityIds, visiblePriorities: visiblePriorities, visibleWearableDatas: visibleWearableDatas }) })), _jsx(ChartFooter, { priorityIds: priorityIds, visiblePriorities: visiblePriorities, visibleWearableDatas: visibleWearableDatas, toggleVisiblePriority: togglePriorityVisibility, toggleVisibleWearableData: toggleWearableDataVisibility })] })));
}
function ChartWithResponsesWearable({ patient, responses, activities, sleeps, }) {
    const [priorityResponsesStats, priorityIds] = useStatsFromPriorityResponses(responses);
    const activitiesStats = useStatsFromActivities(activities);
    const sleepsStats = useStatsFromSleeps(sleeps);
    const stats = useMemo(() => mergeObjCollectionBy([priorityResponsesStats, activitiesStats, sleepsStats], 'date'), [activitiesStats, priorityResponsesStats, sleepsStats]);
    const initialized = useRef(false);
    const initializedWearable = useRef(false);
    const allPriorities = useSymptomPriorities();
    const [visiblePriorities, setVisiblePriorities] = useState(new Set());
    const [visibleWearableDatas, setVisibleWearableDatas] = useState(new Set());
    useEffect(() => {
        if (initialized.current || _.isEmpty(allPriorities)) {
            return;
        }
        const allPriorityIds = new Set(_.map(allPriorities, (p) => p.id));
        setVisiblePriorities(allPriorityIds);
        initialized.current = true;
    }, [initialized, allPriorities]);
    useEffect(() => {
        if (initializedWearable.current) {
            return;
        }
        setVisibleWearableDatas(new Set(['activity', 'sleep']));
        initializedWearable.current = true;
    }, [initializedWearable]);
    const togglePriorityVisibility = useCallback((id) => {
        if (!initialized) {
            return;
        }
        let updated = new Set(visiblePriorities);
        if (updated.has(id)) {
            updated.delete(id);
        }
        else {
            updated.add(id);
        }
        setVisiblePriorities(updated);
    }, [initialized, visiblePriorities]);
    const toggleWearableDataVisibility = useCallback((id) => {
        if (!initializedWearable) {
            return;
        }
        let updated = new Set(visibleWearableDatas);
        if (updated.has(id)) {
            updated.delete(id);
        }
        else {
            updated.add(id);
        }
        setVisibleWearableDatas(updated);
    }, [initializedWearable, visibleWearableDatas]);
    return (_jsxs(Stack, Object.assign({ flexGrow: "1", spacing: 2, padding: 2, className: "patient-chart" }, { children: [_jsx(Stack, Object.assign({ flexGrow: "1" }, { children: _jsx(ChartBody, { patient: patient, stats: stats, priorityIds: priorityIds, visiblePriorities: visiblePriorities, visibleWearableDatas: visibleWearableDatas }) })), _jsx(ChartFooter, { priorityIds: priorityIds, visiblePriorities: visiblePriorities, visibleWearableDatas: visibleWearableDatas, toggleVisiblePriority: togglePriorityVisibility, toggleVisibleWearableData: toggleWearableDataVisibility })] })));
}
export function PatientChart({ patient, titleId, displayPeriodId, responses, activities, sleeps, }) {
    if (responses) {
        return (_jsx(ChartWithResponsesWearable, { patient: patient, responses: responses, activities: activities, sleeps: sleeps }));
    }
    return (_jsx(ChartWithControls, { patient: patient, titleId: titleId, displayPeriodId: displayPeriodId }));
}
