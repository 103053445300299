import 'survey-core/modern.min.css';
import { Model, StylesManager } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { useTranslation } from 'react-i18next';
import { useDataContext } from '../../contexts/DataContext';
import {
  useApiCallWithMessageBar,
  PatientService,
} from 'ecarepd-shared-utilities';
import { useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import ScoreResultsPHQ9 from '../../components/Survey/ScoreResultsPHQ9';
import { ScoreResultsGuidePHQ9 } from '../../components/Survey/ScoreResultsGuidePHQ9';
import {
  SURVEY_VERSION,
  getTotalPHQScore,
  usePHQ9SurveyModel,
} from '../../components/Survey/SurveyUtils';

StylesManager.applyTheme('modern');

export default function SurveyPage() {
  const { t } = useTranslation();
  const { patient } = useDataContext();
  const [latestTotalScore, setLatestTotalScore] = useState(Infinity);
  const { COLUMNS, ROWS, SURVEY_JSON } = usePHQ9SurveyModel();

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (sender: any) => {
      const phqScore = getTotalPHQScore(sender.data);
      const data = {
        ...sender.data,
        'Total Score': phqScore,
      };
      return PatientService.addSurveyResults(data, SURVEY_VERSION).then(() => {
        setLatestTotalScore(phqScore);
      });
    },
    success: {
      message: t('home.survey.successSubmit'),
    },
    failure: {
      message: t('home.survey.failureSubmit'),
    },
  });

  const surveyModel = useMemo(() => {
    const survey = new Model(SURVEY_JSON);
    survey.onComplete.add(onSubmit);
    return survey;
  }, [onSubmit, SURVEY_JSON]);

  return (
    <Stack>
      <Survey model={surveyModel} />
      <Stack margin="5%">
        <ScoreResultsGuidePHQ9 columns={COLUMNS} rows={ROWS} />
      </Stack>
      {latestTotalScore !== Infinity && (
        <ScoreResultsPHQ9
          phq9Score={latestTotalScore}
          resetScore={() => setLatestTotalScore(Infinity)}
        />
      )}
    </Stack>
  );
}
