import { Stack, Typography, Card, Button } from '@mui/material';
import {
  PatientService,
  useApiCallWithMessageBar,
  ResponseCareTip,
} from 'ecarepd-shared-utilities';
import { DataContext } from '../../contexts/DataContext';
import _ from 'lodash';
import { PropsWithRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Positive } from '../../assets/Positive.svg';
import { ReactComponent as Negative } from '../../assets/Negative.svg';

export interface CareTipRatingProps {
  priority: string;
  responseCareTips: ResponseCareTip[];
}

export function CareTipRating({
  priority,
  responseCareTips,
}: PropsWithRef<CareTipRatingProps>): JSX.Element {
  const { t } = useTranslation();
  const { patientPriorities, patient } = useContext(DataContext);

  const symptom = _.filter(
    patientPriorities?.properties.priorities,
    (p: any) => p.id === priority
  );

  const { callback: onSubmit } = useApiCallWithMessageBar({
    canExecute: () => !!patient,
    execute: (responseCareTip: ResponseCareTip, rating: 0 | 1 | -1) =>
      PatientService.setResponseCareTip({
        ...responseCareTip.properties,
        rating,
      }),
    success: {
      message: t('common.success'),
    },
    failure: {
      message: t('common.failure'),
    },
  });

  return (
    <Stack spacing={4}>
      <Typography variant="h6">
        {t('call_request.rating.header', {
          symptom: symptom[0].properties.name,
        })}
      </Typography>

      <Stack alignItems="center">
        {_.map(responseCareTips, (p: ResponseCareTip) => {
          return (
            <Card key={p.id} className="care-tip-rating">
              <Typography
                fontSize={18}
                fontStyle="italic"
                flexBasis="0"
                flexGrow="3"
              >
                {p.properties.tip}
              </Typography>

              <Positive
                className={
                  p.properties.rating === 1 ? 'selected-svg' : 'unselected-svg'
                }
                onClick={() => onSubmit(p, p.properties.rating === 1 ? 0 : 1)}
              />

              <Negative
                className={
                  p.properties.rating === -1 ? 'selected-svg' : 'unselected-svg'
                }
                onClick={() => onSubmit(p, p.properties.rating === -1 ? 0 : -1)}
              />

              <Button
                variant="contained"
                color="secondary"
                className={
                  p.properties.rating === 0
                    ? 'selected-button'
                    : 'unselected-button'
                }
                onClick={() => onSubmit(p, 0)}
              >
                {t('call_request.rating.rating')}
              </Button>
            </Card>
          );
        })}
      </Stack>
    </Stack>
  );
}
