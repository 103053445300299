import { COLOURS } from './colours';
import { primary, fontFamily, spacing } from './constants';
export const typography = {
    h6: {
        fontWeight: 'unset',
    },
    body1: {
        color: primary.main,
    },
    body2: {
        color: primary.main,
    },
};
const styleOverrides = {
    root: Object.assign({}, fontFamily),
    body1: {
        'header .MuiIconButton-root &': {
            // Undo the color in the theme for typography.body1 when in the header menu button
            color: 'unset',
            whiteSpace: 'nowrap',
        },
        '.recharts-tooltip-wrapper &': {
            fontWeight: 500,
            fontStyle: 'italic',
            fontSize: '16px',
            lineHeight: '20px',
        },
        '.wearable-data-card-steps &': {
            fontStyle: 'italic',
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '26px',
            color: COLOURS.Green,
        },
        '.wearable-data-card-sleepQuality &': {
            fontStyle: 'italic',
            fontWeight: '500',
            fontSize: '24px',
            lineHeight: '26px',
            color: COLOURS.DarkBlue,
        },
    },
    body2: {
        '.patient-chart-footer .priority-toggle &': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '14px',
            color: COLOURS.DarkGrey,
        },
        '.wearable-data-card &': {
            fontStyle: 'italic',
            fontWeight: '500',
            fontSize: '13px',
            lineHeight: '16px',
        },
        '&.MuiLink-root': {
            cursor: 'pointer',
        },
    },
    h4: {
        '.recharts-tooltip-wrapper &': {
            color: COLOURS.YetAnotherBlue,
            fontWeight: 500,
            fontStyle: 'italic',
            fontSize: '16px',
            lineHeight: '20px',
            marginBottom: `${spacing * 2}px`,
        },
        '.patient-chart-footer &': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            letterSpacing: '-0.337647px',
            color: COLOURS.RoyalBlue,
        },
    },
};
export const MuiTypography = {
    styleOverrides,
};
