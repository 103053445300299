import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/NewlyDiagnosed.svg';

export function NewlyDiagnosed(): JSX.Element {
  return (
    <ResourceGroup
      titleId="content.resources.newly_diagnosed.title"
      infoId="content.resources.newly_diagnosed.info"
      type="NewlyDiagnosed"
      Icon={Icon}
      columns={1}
    />
  );
}
