import { en as privacy_policy_text } from './PrivacyPolicy';
import { TermsOfService_en as terms_of_service_text } from './TermsOfService';

const APP_TITLE = 'eCarePD';

export const en = {
  app: {
    title: APP_TITLE,
  },
  common: {
    cancel: 'Cancel',
    continue: 'Continue',
    success: 'Success',
    failure: 'Failure',
    back: 'Back',
    next: 'Next',
    n_a: 'N/A',
    done: 'Done',
    yes: 'Yes',
    no: 'No',
    required_field: 'Required field',
  },
  footer: {
    contact_us: 'Need help? Contact us.',
    contact_email: 'mailto:ecarepd@toh.ca',
    privacy_policy_title: 'Privacy Policy',
    privacy_policy: 'privacy policy',
    privacy_policy_text,
    terms_of_service_title: 'Terms of Service',
    terms_of_service: 'terms of service',
    terms_of_service_text,
  },
  header: {
    title: APP_TITLE,
    login: 'Sign In',
    logout: 'Sign Out',
    settings: 'Settings',
    survey_title: 'Write a Review',
    depression_survey: 'PHQ-9 Survey',
  },
  home: {
    care_priorities: {
      header: 'Your Care Priorities',
      change: 'Change My Care Priorities',
      last_report: 'Last report: ',
      no_report: 'not reported yet',
      speech: 'Speech',
      freezing: 'Freezing',
      memory: 'Memory',
      sleep: 'Sleep',
      complete: 'Complete Care Survey',
      help: 'Need to talk to a Clinician?',
      more_information: 'More information',
      select: 'Select your care priorities',
      no_priorities:
        "Having personal goals and tracking your care priorities help you assess how you are feeling and maintaining your quality of life can make a big difference when it comes to living well with Parkinson's.<br><br>eCare PD can assist you to achieve your goals by focussing on your personal care priorities and providing resources and tips to help you.",
    },
    journal: {
      header: 'Daily Journal',
      subheader:
        "How are you doing today? What's going well? Are you having any issues or concerns?",
      submit: 'Send to Journal',
      update: 'Update Journal',
      successUpdate: 'Daily Journal updated',
      successSubmit: 'Daily Journal created',
      failureUpdate: 'Failed to update Journal',
      failureSubmit: 'Failed to create Journal',
      audio: {
        processing: 'Processing...',
        record: 'Record Audio Journal',
        stop: 'Stop',
      },
      score: {
        title: 'Voice Feature Scores',
      },
    },
    survey: {
      successSubmit: 'Feedback submitted',
      failureSubmit: 'Failed to submit feedback',
      label: 'Tell us how we did!',
    },
    welcome: {
      header: 'Welcome {{firstName}}!',
      subheader:
        'We hope you’ve been doing well, track your status and check your history to monitor your progress.',
      exercise_header:
        'Have you thought about signing up for an exercise program?',
      exercise_subheader:
        "There is evidence that exercise may hold specific benefits for people with Parkinson's in staying active and relatively limber, and improving balance and motor coordination.",
    },
  },
  not_implemented: 'Not implemented',
  tab_bar: {
    care_finder: 'Care Finder',
    care_team: 'Care Team',
    history: 'History',
    home: 'Home',
    journal: 'Journal',
    resources: 'Resources',
    tracking: 'Tracking',
  },
  content: {
    resources: {
      newly_diagnosed: {
        title: 'Newly Diagnosed',
        info: "If you have been recently diagnosed with Parkinson's disease, you are not alone. You can get more information and tools you need to lead a healthier, more independent life.",
      },
      maintaining_meds: {
        title: 'Maintaining Your PD Meds',
        info: "Medication will help you function but may cause side effects. It is important to find the right balance between the medication's benefits and side effects. Everyone with Parkinson's is unique and will experience different symptoms, which means the treatment you receive will be geared to your specific needs. Drugs for Parkinson's work on the brain's complex chemistry and may need to be taken several times a day. Use them as prescribed and do not alter your doses without consulting your doctor.",
      },
      other_care_issues: {
        title: 'Other Care Issues',
        info: "Each person with Parkinson's is unique and each person may experience different symptoms. However, since Parkinson's is a progressive condition, symptoms.",
      },
      specialists: {
        title: 'PD Specialists in Canada',
        info: 'Use the resource below to access contact information about Parkinson’s centers in Canada dedicated to the care of patients and families living with Parkinson’s.',
        sites: {
          cmdg: {
            title: 'Canadian Movement Disorder Group',
            text: 'This site provides contact information about Canadian specialists in the field of Movement Disorders and the centers in Canada dedicated to the care of patients and families affected by these disorders.',
            url: 'http://www.cmdg.org',
          },
        },
      },
      more_resources: {
        title: 'More Resources For Living With PD',
        sites: {
          parkinson_foundation: {
            title: 'Parkinson Foundation',
            text: 'This is the site for an American based foundation that makes life better for people with Parkinson’s disease by improving care and advancing research toward a cure.',
            url: 'https://www.parkinson.org',
          },
          parkinson_canada: {
            title: 'Parkinson Canada',
            text: 'Works to provide support services and education to people living with Parkinson’s disease, their families, and the health care professionals who treat them.',
            url: 'https://www.parkinson.ca',
          },
        },
      },
      more_research: {
        title: 'More About PD Research',
        sites: {
          michaeljfox_foundation: {
            title: 'The Michael J Fox Foundation',
            text: "The Michael J. Fox Foundation is dedicated to finding a cure for Parkinson's disease through an aggressively funded research agenda and to ensuring the development of improved therapies for those living with Parkinson's today.",
            url: 'https://www.michaeljfox.org',
          },
        },
      },
      using: {
        title: 'Using the eCare PD Application',
        info: 'With eCare PD, you will be able to track your symptoms, adding relevant personal notes and information, enabling you to see how symptoms track over time, get coaching help when you are having ongoing issues, and better inform your entire care team of status and events between visits.',
        content: {
          priorities: {
            title: 'Your Care Priorities',
            info: `Every Parkinson's experience is unique. The symptoms and progression will vary from person to person. Living with Parkinson's requires an individualized approach to managing your care.

To set the aspects of care you want to improve, tap the “Set Your Care Priorities" button on the ‘home’ screen to start the selection process. You can self-rate your degree of satisfaction of your care priorities you at your own pace by tapping the “Complete Care survey" button on the ‘home screen’ or tap the <b>"Tracking”</b> tab in the left navigation. 

When you want to look at your overall progress regarding the completion of care priorities, which tips you have received tap the <b>"History"</b> tab in the left navigation.`,
          },
          journal: {
            title: 'Your Journal',
            info: `Keeping a journal helps to evaluate your personal progress, keeping note of how your doing on a regular basis, and lets you look back and reflect on events and situations that have impacted your progression living with Parkinson’s.

Journal entries are private to your account, and will not be shared with your care team, or any one else with acces to the eCare PD platform.

Tap the <b>"Journal"</b> tab in the left navigation to access your private PD diary.`,
          },
          care_finder: {
            title: 'Your Care Finder',
            info: `Information about community resources is key for people living with Parkinson’s. 

Tap the <b>"Care Finder"</b> tab in the left navigation to find resources close to you.`,
          },
          care_team: {
            title: 'Your Care Team',
            info: `It is essential for you to be an active manager of your Parkinson's in partnership with a care team. Members of your care team might include some or all of the following: Neurologist, Family doctor, Parkinson's nurse specialist, Psychiatrist/psychologist, Physiotherapist, Occupational therapist, Speech language pathologist, Dietician, Social worker, or other healthcare professionals. 

Tap the <b>"Care Team"</b> tab in the left navigation to get started and have your care team in eCARE-PD for your convenience.`,
          },
        },
      },
    },
    journal: {
      dialog: {
        title: "It's Your Journal",
        content:
          'Your journal is your place to keep personal notes and thoughts that can relate to your PD journey, or anything else.\n\nThese entries are private to your account, and will not be shared with your care team, or any one else with acces to the eCare PD platform.',
      },
      carePriority: 'Care Priority',
    },
    care_priorities: {
      header: 'Update Your Care Priorities',
      subheader:
        'Take your time to identify 3 or 4 care priorities that you want to focus on at this time.<br>Which care priorities affect your well-being at this time?<br>You can select care priorities for which you will receive tips when needed and you can monitor over time.',
      current: 'Your current care priorities: ',
      none: 'none selected.',
      // Capitilized to match backend, do not change
      Physical: 'Physical Health',
      Mental: 'Mental Health',
      Lifestyle: 'Lifestyle & Social Life',
      Other: 'Other Care Priorities',
      select: 'Select your Care Priorities',
      maximum:
        'You have already selected the maximum of 4 Care Priorities for tracking. <br><br>It is important to focus on a few top priorities at a time.<br><br>You can go back and modify your selection to ensure you are focussing on your top issues.',
      maximum_header: 'Maximum Reached',
      success_submit: 'Care Priorities updated',
      failure_submit: 'Failed to update Care Priorities',
    },
    patient: {
      wearable_information: {
        title: 'Wearable Tracker Information',
      },
    },
    patient_chart: {
      title: 'Care Priority Recent History',
      display_period: 'Display Period',
      care_priorities: 'Your Care Priorities',
      show: 'Show',
      hide: 'Hide',
      last_month: 'Last Month',
      last_week: 'Last Week',
      last_ten_days: 'Last 10 days',
      last_five_days: 'Last 5 days',
      sleep: 'Sleep',
      activity: 'Steps',
      sleep_legend: 'Sleep duration: ',
      sleep_raw: '{{hours}} hours {{minutes}} minutes',
      activity_legend: ' steps',
    },
    history: {
      care_priority_history: {
        title: 'Care Priority History',
        how_you_doing: "Here's how you've been doing over the past",
        recent_tips: 'Recent Care Tips...',
        tip_help: 'Does this help?',
        full_tip_sheet_link: 'Tap here to see the full tip sheet.',
        number_of_entries: {
          last_five: 'Last 5 Entries',
          last_twenty: 'Last 20 Entries',
          last_fifty: 'Last 50 Entries',
          all: 'All Entries',
        },
        note_dialog: {
          title: 'Personal Note - {{symptomName}}',
          please_note:
            'Please note that the care priority notes will be shared with you care team members.',
          add_note: 'Add Note',
        },
      },
    },
    tracking: {
      title: 'Care Priority Tracking Survey',
      sub_title:
        'Complete a survey to monitor over time how you are doing with the care priorities you selected.',
      actions: {
        update: 'Update Care Priorities',
      },
      priorities: {
        more_info: 'Tap here to see more information',
        add_note: 'Add personal note',
        care_tip_title: '{{symptom}} Care Tip',
        more_about: 'More About {{symptom}}',
      },
      tooltips: {
        4: 'Very good, no issues or concerns',
        3: 'Pretty good, maybe a couple of minor issues or concerns that have little impact on normal activities.',
        2: 'Alright but not great, a few issues or concerns that have an impact on some daily activities.',
        1: 'Not that good, there have been a number of issues or concerns that have impacted normal activities.',
        0: 'Very bad, lots of issues, troubles or concerns, and have made it difficult to perform daily activities.',
      },
    },
    tutorial: {
      header: 'Welcome to eCare PD',
      tab1: "Parkinson's is a complex disease and everyone experiences it differently. <br><br> eCare PD is a platform that allows you to monitor your care priorities over time, manage your personal goals of care by providing tailored care tips and personalized care resources.",
      tab2: "In order to get a good start with eCare PD we have a few questions to get an understanding of where you are in your Parkinson's journey. <br><br>This information will be used to help direct the most relevant information and services to you.",
      first_name: 'What is your first name?',
      first_name_placeholder: 'Please enter your first name',
      last_name: 'What is your last name?',
      last_name_placeholder: 'Please enter your last name',
      telephone: 'What is your mobile phone number?',
      telephone_placeholder: 'Please enter your phone number',
      allow_contact: 'Allow Care Team to contact using text messaging',
      tab4: 'In order to provide the most relevant information, tips and services the following information is required.',
      diagnosed_date: 'Year of Diagnosis',
      diagnosed_date_placeholder: 'What year were you diagnosed with PD?',
      birth_date: 'Year of Birth',
      birth_date_placeholder: 'Please enter the year of your birth',
      gender: 'What is your gender?',
      please_select: 'Please select',
      male: 'Male',
      female: 'Female',
      tab5: "Having personal goals and tracking your care priorities help you assess how you are feeling and maintaining your quality of life can make a big difference when it comes to living well with Parkinson's. eCare PD can assist you to achieve your goals by focusing on your personal care priorities and providing resources and tips to help you.",
      skip: 'Skip',
      tab6: 'Your eCare PD experienced can be further enhanced by using a connected health tracker, and allowing eCare PD to see your status will help to monitor your movement and sleep quality. <br><br>You can connect your wearable device at any time from the home screen.',
      success_submit: 'Patient data updated',
      failure_submit: 'Failed to update patient data',
    },
    care_finder: {
      where: 'Where are you?',
      where_placeholder: 'Enter Postal Code',
      distance: 'Within what distance?',
      services: 'What service(s) are you looking for?',
      select_all: 'Select All',
      search: 'Find your Care Specialist',
      search_results_title: 'Find the services you need',
      modify: 'Modify Search Criteria',
      result_count: '{{resultCount}} matches to criteria',
      services_offered: 'Services Offered:',
      in_home: 'Include in-home service providers?',
      in_home_service: 'in-home service',
      km_display: '{{value}} km',
      all_selected: 'All services selected',
      no_results_header: 'No Services were found with current criteria.',
      no_results_subheader:
        'Please increase search distance, or expand services selection.',
      postal_code_error: 'Postal code not found',
    },
    walk_through: {
      welcome: {
        title: 'Welcome to eCare PD',
        text: 'eCare PD is a platform that allows you to monitor the important aspects of your health care priorities over time, manage your personal health goals, providing tailored care tips and personalized care resources.',
      },
      care_priorities: {
        title: 'Care Priority Survey',
        text: 'Your most recent care survey results will be presented here for you to easily review your status, see care tips, get coaching feedback and more information at your fingertips.',
      },
      wearable: {
        title: 'Get the most out of your wearable device',
        text: 'Connect your wearable device to eCare PD to see how your sleep and fitness activity impacts your overall wellness, and how it influences your care priorities over time.',
      },
      journal: {
        title: 'Keeping a Journal',
        text: "Keeping a journal helps to evaluate your personal progress, keeping note of how your doing on a regular basis, and lets you look back and reflect on events and situations that have impacted your progression living with Parkinson's.",
      },
      ratings: {
        title: 'Care Tips',
        text: 'Care tips are suggestions to help you when are having issues with your care priorities, you can review recent tips that have been presented, and rate them to let us know how they worked for you.',
      },
      explore_home: {
        title: 'Explore eCare PD',
        text: 'Tapping on the left side bar icons you can navigate to different areas of the eCare PD application.<br><b>Home</b> will return you here, the main screen of the application.',
      },
      explore_tracking: {
        title: 'Explore eCare PD',
        text: 'Tap the <b>Tracking</b> icon to go to the Care Priority Tracking Survey to fill out your survey.',
      },
      explore_history: {
        title: 'Explore eCare PD',
        text: 'Tap the <b>History</b> icon to see your Care Priority History allowing you to review how you have been progressing with your care priorities.',
      },
      explore_journal: {
        title: 'Explore eCare PD',
        text: 'Tap the <b>Journal</b> icon to see all of your entries into your journal.',
      },
      explore_care_team: {
        title: 'Explore eCare PD',
        text: 'Tap the <b>Care Team</b> icon to access your personally curated list of your care team members, allowing you to create a consolidated place for your contacts.',
      },
      explore_care_finder: {
        title: 'Explore eCare PD',
        text: 'Tap <b>Care Finder</b> to find information about community resources and services for people living with Parkinson’s. ',
      },
      explore_resources: {
        title: 'Explore eCare PD',
        text: 'Tap the <b>Resources</b> icon to access tips and information about Parkinson’s as well as links to other sites providing research and community care information.',
      },
    },
    care_team: {
      physicians: 'Physicans and Nurses',
      allied_professionals: 'Allied Care Professionals',
      add_new: 'Add New',
      dialog: {
        header: {
          add: 'Add Care Team Member',
          edit: 'Edit Care Team Member',
        },
        subtitle: 'Add your care team members for easy reference',
        name: 'Name',
        email: 'Email',
        address: 'Address',
        phone_number: 'Phone Number',
        text_number: 'Text Messaging Number',
        title: 'Title',
        location: 'Location',
        add_to_care_team: 'Add to Care Team',
        remove_from_care_team: 'Remove from Care Team',
        remove_confirmation_title: 'Remove Care Team Member',
        remove_confirmation_message:
          'Do you really want to remove this member from your care team?',
        update_care_team: 'Update Details',
        invalid_name: 'Invalid Name',
        invalid_title: 'Invalid Title',
        invalid_email: 'Invalid Email address',
      },
      empty_physician:
        "You do not have any Physicians or Nurses in your Care Team yet, click 'Add New' to start building your Care Team",
      empty_professional:
        "You do not have any Allied Care Professionals in your Care Team yet, click 'Add New' to start building your Care Team",
      success_submit: 'Care Team Member Added',
      failure_submit: 'Failed to add Care Team Member',
    },
    wearable_daily: {
      last_updated: 'Last Updated:',
      not_connected: 'Not Connected',
      connect_fitbit_header: 'Connect your Fitbit',
      connect_garmin_header: 'Connect your Garmin',
      connect_info:
        'Connect eCare PD with your wearable device to see you health and fitness information with your care priorities tracking.',
      connect: 'Connect Now',
      steps_label: 'steps',
      steps_noAccess:
        'No access to steps data. To do a reset, delete the connection in the profile settings.',
      sleep_label: 'sleep duration',
      sleep_hours: 'hours',
      sleep_minutes: 'minutes',
      sleep_noAccess:
        'No access to sleep data. To do a reset, delete the connection in the profile settings.',
    },
    depression_survey: {
      title: 'Patient Health Questionnaire (PHQ-9)',
      name: 'Name',
      difficulty:
        'If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
      how_often:
        'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
      date: 'Date',
      column1: 'Not at all (0)',
      column2: 'Several days (1)',
      column3: 'More than half the days (2)',
      column4: 'Nearly every day (3)',
      row1: 'Little interest or pleasure in doing things',
      row2: 'Feeling down, depressed or hopeless',
      row3: 'Trouble falling or staying asleep, or sleeping too much',
      row4: 'Feeling tired or having little energy',
      row5: 'Poor appetite, overeating',
      row6: 'Feeling bad about yourself - or that you are a failure or have let yourself or your family down',
      row7: 'Trouble concentrating on things, such as reading the newspaper or watching television',
      row8: 'Moving or speaking so slowly that other people could have noticed? Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
      row9: 'Thoughts that you would be better off dead or of hurting yourself in some way',
      item1: 'Not difficult at all',
      item2: 'Somewhat difficult',
      item3: 'Very difficult',
      item4: 'Extremely difficult',
      table_column0: 'Score',
      table_column1: 'Depression Severity',
      table_column2: 'Action',
      table_rows: {
        severity0: 'None - minimal',
        action0: 'Patient may not need depression treatment.',
        severity1: 'Mild',
        action1:
          "Use clinical judgment about treatment, based on patient's duration of symptoms and functional impairment.",
        severity2: 'Moderate',
        action2:
          "Use clinical judgment about treatment, based on patient's duration of symptoms and functional impairment.",
        severity3: 'Moderately severe',
        action3:
          'Treat using antidepressants, psychotherapy or a combination of treatment.',
        severity4: 'Severe',
        action4: 'Treat using antidepressants with or without psychotherapy.',
      },
      guide_title: 'How to Score the PHQ-9',
      description1: 'Major depressive disorder (MDD) is suggested if:',
      list_item1:
        "• Of the 9 items, 5 or more are checked as at least 'more than half the days'",
      list_item2:
        "• Either item 1 or 2 is checked as at least 'more than half the days'",
      description2: 'Other depressive syndrome is suggested if:',
      list_item3:
        "• Of the 9 items, between 2 to 4 are checked as at least 'more than half the days'",
      list_item4:
        "• Either item 1 or 2 is checked as at least 'more than half the days'",
      description3:
        'PHQ-9 scores can be used to plan and monitor treatment. To score the instrument, tally the numbers of all the checked responses under each heading (not at all=0, several days=1, more than half the days=2, and nearly every day=3). Add the numbers together to total the score on the bottom of the questionnaire. Interpret the score by using the guide listed below.',
      guide_table_header_title: 'Guide for Interpreting PHQ-9 Scores',
      health_assessment: 'Functional Health Assessment',
      description4:
        "The instrument also includes a functional health assessment. This asks the patient how emotional difficulties or problems impact work, life at home, or relationships with other people. Patient response of 'very difficult' or 'extremely difficult' suggest that the patient's functionality is impaired. After treatment begins, functional status and number score can be measured to assess patient improvement.",
      note: 'Note:',
      description5:
        'Depression should not be diagnosed or excluded solely on the basis of a PHQ-9 score. A PHQ-9 score ≥ 10 has a sensitivity of 88% and a specificity of 88% for major depression.1 Since the questionnaire relies on patient self-report, the practitioner should verify all responses. A definitive diagnosis is made taking into account how well the patient understood the questionnaire, as well as other relevant information from the patient.',
      description6:
        'PHQ-9 is adapted from PRIME MD TODAY, developed by Drs Spitzer, Williams, Kroenke and colleagues, with an educational grant from Pfizer Inc. Use of the PHQ-9 may only be made in accordance with the Terms of Use available at www.pfizer.com. Copyright © 1999 Pfizer Inc. All rights reserved. PRIME MD TODAY is a trademark of Pfizer Inc.',
      reference: 'Reference:',
      description7:
        'Kroenke K, Spitzer RL, Williams JB. The PHQ-9: Validity of a brief depression severity measure. J Gen Intern Med. 2001;16(9):606-613.',
      your_score: 'Your Score: ',
      your_score_title: 'Patient Health Questionnaire (PHQ-9) Results',
    },
  },
  call_request: {
    title: 'Need to talk to a Clinician?',
    no_data:
      'eCARE-PD is a self-care tool. To fully experience its potential, please start by selecting your care priorities, track your symptoms, and try some of the care tips before requesting a call with your PD doctor.',
    no_data_paragraph2:
      "By doing that, your care provider will have a better understanding how you have been managing with Parkinson's yourself and provider better care.",
    priority: {
      header:
        'If you need help, one of your Care Team members can contact you to help you with your concerns specific to your Care Priorities.',
      please_select:
        'Please select which Care Priority you are having difficulties with:',
    },
    rating: {
      header:
        "When you've previously reported difficulties with {{symptom}} we gave you the following tips/strategies to help deal with the {{symptom}}. Can you tell if you tried the tip, and if so, did they help?",
      rating: "I haven't tried it yet",
      recent: 'Recent Care Tips',
      recent_subheader: 'How did the recent care tips work for you?',
    },
    comment: {
      header:
        'Would you like to send a comment or description of the issues you are experiencing to help your care team to understand and focus the conversation to be had?',
    },
    done: {
      header:
        'A member of your Care Team will contact you for a 10-minute call to discuss your concerns with {{symptom}} within the next week.',
      sub_header:
        'For assistance, you can contact us by email at <a href="mailto:ecarepd@toh.ca">ecarepd@toh.ca</a>',
      when: 'TODO: get next review date from backend',
    },
  },
  signin: {
    please_sign_in: 'Please sign in...',
  },
  donate: {
    header: 'Donate to Parkinson Research in Ottawa',
    text: "Your gift today will support the many Parkinson research projects taking place in Ottawa that will help you and your family to live better with Parkinson's disease.",
    donate_now: 'Donate Now',
  },
  settings: {
    your_info: {
      title: 'Your Info',
      name: 'Name',
      email: 'Email Address',
      diagnosis_date: 'Year of Diagnosis',
      dob: 'Year of Birth',
      gender: 'Gender',
      edit_info: {
        title: 'Update Your Info',
        first_name: 'First Name',
        last_name: 'Last Name',
        phone_number: 'Phone Number',
        allow_sms: 'Allow Care Team to contact using text messaging',
        button: 'Edit Info',
        save: 'Save Updates',
        diagnosis_date: 'Year of Diagnosis',
        email: 'Email Address *',
        email_subtext:
          '* Email Address is not modifiable, if you need to change it please contact us at ecarepd@toh.ca',
      },
      errors: {
        first_name: 'First name must be specified',
        last_name: 'Last name must be specified',
        phone: 'Phone number must be specified',
        diagnostics_before_dob:
          'The diagnostics date cannot be before your birth date',
      },
    },
    reset_password: 'Reset Password',
    wearable: 'Wearable',
    fitbit: {
      connect: 'Connect Fitbit',
      disconnect: 'Disconnect Fitbit',
      title: 'Disconnect Fitbit',
      yes_delete: 'Yes, disconnect my Fitbit',
      refresh: 'Refresh Data',
      message1:
        'Are you sure you want to disconnect your Fitbit data from eCare PD?',
    },
    garmin: {
      connect: 'Connect Garmin',
      disconnect: 'Disconnect Garmin',
      title: 'Disconnect Garmin',
      yes_delete: 'Yes, disconnect my Garmin',
      refresh: 'Refresh Data',
      message1:
        'Are you sure you want to disconnect your Garmin data from eCare PD?',
    },
    delete_account1: {
      button: 'Delete Account',
      title: 'Delete Account',
      yes_delete: 'Yes, delete my account',
      message1: 'Are you sure you want to delete you eCarePD account?',
      message2:
        'Your referring Doctor will be notified that you have chosen to leave the eCarePD program.',
    },
    delete_account2: {
      button: 'Delete Account',
      title: 'Delete Account',
      yes_delete: "Yes, I'm sure",
      message1: 'Are you really sure you want to delete your eCarePD account?',
      message2:
        'Once complete, you will not be able to access eCarePD using these credentials.',
    },
  },
  under_construction: {
    title: {
      care_team: 'Care Team',
      care_finder: 'Care Finder',
      resources: 'Resources',
    },
    message:
      'Sorry, this service is not currently available, it is currently in development, please check back.',
    building: "What we're building....",
  },
};
