import { PropsWithRef, useContext, useState } from 'react';
import { Button, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import { ResponseMap } from './Tracking';
import {
  PatientService,
  PatientPriorityResponseProperties,
  useApiCallWithMessageBar,
  ResponseCareTipProps,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import CarePrioritiesLanding from '../carePriorities/CarePrioritiesLanding';
import { DataContext } from '../../contexts/DataContext';
import { useNavigate } from 'react-router-dom';
import { PATH_HOME } from '../constants';

export interface ActionsProps {
  responses: ResponseMap;
  responseCareTips?: ResponseCareTipProps[];
}

export function Actions({
  responses,
  responseCareTips,
}: PropsWithRef<ActionsProps>): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientPriorities } = useContext(DataContext);

  const [showChangePriorities, setShowChangePriorities] = useState(false);

  const { progress: submitting, callback: onUpdatePriorities } =
    useApiCallWithMessageBar({
      canExecute: () => !!responses,
      execute: async () => {
        for (const priorityId of _.keys(responses)) {
          const r: PatientPriorityResponseProperties = responses[priorityId];
          const responseID = await PatientService.addPatientPriorityResponse(
            priorityId,
            r.date2,
            r.response != null ? r.response : -1,
            r.note || ''
          );

          const responseCareTip = _.find(
            responseCareTips,
            (tip: ResponseCareTipProps) => tip.priorityID === priorityId
          );

          if (responseCareTip) {
            await PatientService.setResponseCareTip({
              ...responseCareTip,
              responseID,
            });
          }
        }

        navigate(PATH_HOME);
      },
      success: {
        snackbarProps: {
          autoHideDuration: 1000,
        },
      },
    });

  return (
    <Stack
      spacing={2}
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      alignItems="center"
    >
      <CarePrioritiesLanding
        onClose={() => setShowChangePriorities(false)}
        open={showChangePriorities}
        oldPriorities={patientPriorities}
      />

      <Link onClick={() => setShowChangePriorities(true)}>
        <Stack direction="row" alignItems="center">
          <EditIcon />
          {t('content.tracking.actions.update')}
        </Stack>
      </Link>

      <Button
        variant="outlined"
        onClick={onUpdatePriorities}
        disabled={submitting}
      >
        {t('common.continue')}
      </Button>
    </Stack>
  );
}
