import {
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import htmlReactParser from 'html-react-parser';
import { PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, spacing } from 'ecarepd-shared-utilities';
import { LoggedDialog } from 'hive-analytics-react';

export interface CarePrioritiesTooltipProps {
  open: boolean;
  onClose: () => void;
  text: string;
  priority: string;
}

export default function CarePrioritiesTooltip({
  onClose,
  open,
  text,
  priority,
}: PropsWithRef<CarePrioritiesTooltipProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <LoggedDialog
      dialogName="care-priorities-tip"
      open={open}
      onClose={onClose}
    >
      <DialogTitleWithClose onClose={onClose}>{priority}</DialogTitleWithClose>
      <DialogContent>
        <Typography paddingTop={spacing}>{htmlReactParser(text)}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t('common.continue')}
        </Button>
      </DialogActions>
    </LoggedDialog>
  );
}
