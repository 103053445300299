import { Stack } from '@mui/material';
import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/MoreResearch.svg';
import { Link } from './Link';

export function MoreResearch(): JSX.Element {
  return (
    <ResourceGroup titleId="content.resources.more_research.title" Icon={Icon}>
      <Stack paddingLeft={4} paddingTop={4}>
        <Link strings="content.resources.more_research.sites.michaeljfox_foundation" />
      </Stack>
    </ResourceGroup>
  );
}
