import { ComponentsOverrides } from '@mui/material';
import { spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiPaper'] = {
  root: {
    '.care-team-card &': {
      height: 200,
      borderRadius: spacing * 4,
      maxWidth: 350,
    },
  },
};

export const MuiPaper = {
  styleOverrides: styleOverrides,
};
