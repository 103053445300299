import { DialogContent, Stack, Typography } from '@mui/material';
import { useState, PropsWithRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import htmlReactParser from 'html-react-parser';
import {
  DialogTitleWithClose,
  CommonDataContext,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';
import { LoggedDialog } from 'hive-analytics-react';
import TabPanel from './TabPanel';
import { ReactComponent as Back } from '../../assets/Stepper L.svg';
import { ReactComponent as Forward } from '../../assets/Stepper R.svg';
import _ from 'lodash';

export interface WalkThroughProps {
  open: boolean;
  onClose: () => void;
}

const WINDOW_HEIGHT = window.innerHeight;
const HEADER_HEIGHT = 70;

enum TabValuesWithWearable {
  welcome,
  care_priorities,
  wearable,
  journal,
  ratings,
  explore_home,
  explore_tracking,
  explore_history,
  explore_journal,
  explore_care_team,
  explore_care_finder,
  explore_resources,
}

const TAB_VALUE_ELEMENT_IDS_WITH_WEARABLE = {
  [TabValuesWithWearable.care_priorities]: 'walkthrough-care-priorities',
  [TabValuesWithWearable.wearable]: 'walkthrough-wearable',
  [TabValuesWithWearable.journal]: 'walkthrough-journal',
  [TabValuesWithWearable.ratings]: 'walkthrough-ratings',
  [TabValuesWithWearable.explore_home]: 'walkthrough-explore-home',
  [TabValuesWithWearable.explore_tracking]: 'walkthrough-explore-tracking',
  [TabValuesWithWearable.explore_history]: 'walkthrough-explore-history',
  [TabValuesWithWearable.explore_journal]: 'walkthrough-explore-journal',
  [TabValuesWithWearable.explore_care_team]: 'walkthrough-explore-care-team',
  [TabValuesWithWearable.explore_care_finder]:
    'walkthrough-explore-care-finder',
  [TabValuesWithWearable.explore_resources]: 'walkthrough-explore-resources',
};

enum TabValues {
  welcome,
  care_priorities,
  journal,
  ratings,
  explore_home,
  explore_tracking,
  explore_history,
  explore_journal,
  explore_care_team,
  explore_care_finder,
  explore_resources,
}

const TAB_VALUE_ELEMENT_IDS = {
  [TabValues.care_priorities]: 'walkthrough-care-priorities',
  [TabValues.journal]: 'walkthrough-journal',
  [TabValues.ratings]: 'walkthrough-ratings',
  [TabValues.explore_home]: 'walkthrough-explore-home',
  [TabValues.explore_tracking]: 'walkthrough-explore-tracking',
  [TabValues.explore_history]: 'walkthrough-explore-history',
  [TabValues.explore_journal]: 'walkthrough-explore-journal',
  [TabValues.explore_care_team]: 'walkthrough-explore-care-team',
  [TabValues.explore_care_finder]: 'walkthrough-explore-care-finder',
  [TabValues.explore_resources]: 'walkthrough-explore-resources',
};

export default function WalkThrough({
  onClose,
  open,
}: PropsWithRef<WalkThroughProps>): JSX.Element {
  const { t } = useTranslation();
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const hasWearable = featureFlags.FITBIT || featureFlags.GARMIN;
  const tabValues = hasWearable ? TabValuesWithWearable : TabValues;
  const [value, setValue] = useState(tabValues.welcome);
  let tabValuesLastIndex = Object.keys(tabValues).length / 2 - 1;
  let tabValueElementIds = hasWearable
    ? TAB_VALUE_ELEMENT_IDS_WITH_WEARABLE
    : TAB_VALUE_ELEMENT_IDS;

  const handleClose = () => {
    const menu = document.getElementById('walkthrough-explore-home');
    const journal = document.getElementById('walkthrough-journal');
    if (menu) {
      menu.scrollIntoView(); // make sure the top of the menu is visible on close if we've scrolled away
    }
    if (journal) {
      journal.scrollIntoView(); // make sure the top of the journal is visible on close if we've scrolled away
    }
    onClose();
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    const items = document.getElementsByClassName('MuiBackdrop-root');
    const backdrop = _.first(items) as HTMLElement;
    if (!backdrop) {
      return;
    }

    const id = _.get(tabValueElementIds, value);
    const e = id ? document.getElementById(id) : undefined;
    if (!e) {
      return;
    }

    let rect = _.get(e.getClientRects(), 0);
    if (!rect) {
      return;
    }

    if (
      (id !== 'walkthrough-care-priorities' && rect.bottom > WINDOW_HEIGHT) ||
      rect.top < HEADER_HEIGHT
    ) {
      e.scrollIntoView();
      rect = _.get(e.getClientRects(), 0); // regenerate values as they would have changed after a scroll
    }

    const left = rect.x - 8;
    const top = rect.y - 8;
    const right = rect.right + 8;
    const bottom = rect.bottom + 8;

    const polygon = `polygon(0% 0%, 0% 100%, ${left}px 100%, ${left}px ${top}px, ${right}px ${top}px, ${right}px ${bottom}px, ${left}px ${bottom}px, ${left}px 100%, 100% 100%, 100% 0%)`;
    const p = backdrop.style.clipPath;
    backdrop.style.clipPath = polygon;
    return () => {
      backdrop.style.clipPath = p;
    };
  }, [open, value, tabValueElementIds]);

  return (
    <LoggedDialog
      dialogName="walkthrough-dialog"
      onClose={handleClose}
      open={open}
      className="walkthrough-dialog"
    >
      <DialogTitleWithClose onClose={handleClose}>
        {t(`content.walk_through.${tabValues[value]}.title`)}
      </DialogTitleWithClose>

      <DialogContent>
        <TabPanel value={value} index={value}>
          {value > 0 && (
            <Back onClick={() => setValue((previous) => previous - 1)} />
          )}
          <Stack display="flex" flexDirection="row">
            <Typography paddingX={4}>
              {htmlReactParser(
                t(`content.walk_through.${tabValues[value]}.text`)
              )}
            </Typography>
          </Stack>
          {value < tabValuesLastIndex && (
            <Forward
              fontSize="small"
              onClick={() => setValue((previous) => previous + 1)}
            />
          )}
        </TabPanel>
      </DialogContent>
    </LoggedDialog>
  );
}
