var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { assertLoggableConnection, LoggedConnectionService, } from 'hive-analytics-react';
import { utils } from 'hive-client-utils';
import _ from 'lodash';
import moment from 'moment';
const REDIRECT_URI = (_a = window._env_) === null || _a === void 0 ? void 0 : _a.REACT_APP_PATIENT_APP_URL;
function invitePatient(firstName, lastName, email, clinicianId) {
    const connection = assertLoggableConnection();
    const handler = utils.waitForInvocationReaction(connection.bee, _.identity);
    return connection.bee.actions
        .invoke('invitePatient', firstName, lastName, email, REDIRECT_URI, clinicianId)
        .then(handler);
}
function setPatientPriorities(patient, priorities) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('setPatientPriorities', patient.properties.patientID, priorities)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function assertJournalEntry(patient, entry) {
    const connection = assertLoggableConnection();
    const date = moment().startOf('day').toISOString();
    return connection.bee.actions
        .invoke('assertJournalEntry', patient.properties.patientID, date, entry)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function updateJournalEntry(patient, date, entry) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('assertJournalEntry', patient.properties.patientID, moment(date).toISOString(), entry)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function updatePatientPriorityResponse(responseId, props) {
    if (_.isEmpty(props)) {
        // Nothing to update!
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('updatePatientPriorityResponse', responseId, props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function deleteMyAccount() {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('deleteMyAccount')
        .then(utils.waitForInvocationReaction(connection.bee))
        .then(() => LoggedConnectionService.releaseBee('DELETE_MY_ACCOUNT'));
}
function addPatientPriorityResponse(priorityId, date, response, note) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('addPatientPriorityResponse', priorityId, moment(date).toISOString(), response, note || '')
        .then(utils.waitForInvocationReaction(connection.bee, (r) => r.details.message));
}
function updatePatient(patient, props) {
    if (_.isEmpty(props)) {
        return Promise.resolve();
    }
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('updatePatient', patient.id, props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function formatDate(date) {
    if (!date) {
        return '';
    }
    return moment(date).format('YYYY');
}
function createCallRequest(patient, priorityID, date, comment) {
    const connection = assertLoggableConnection();
    date = moment(date).toISOString();
    return connection.bee.actions
        .invoke('createCallRequest', patient.id, priorityID, date, comment)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function completeCallRequest(requestId) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('completeCallRequest', requestId)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function setResponseCareTip(props) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('setResponseCareTip', props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function addCareTeamMember(props) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('addCareTeamMember', props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function updateCareTeamMember(careTeamMemberId, props) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('updateCareTeamMember', careTeamMemberId, props)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function removeCareTeamMember(careTeamMemberId) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('removeCareTeamMember', careTeamMemberId)
        .then(utils.waitForInvocationReaction(connection.bee));
}
function refreshData(device) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke(`${device.toLocaleLowerCase()}.refreshData`)
        .then(utils.waitForInvocationReaction(connection.bee, (r) => r.details.message));
}
function requestAuthUrl(device) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke(`${device.toLocaleLowerCase()}.requestAuthUrl`)
        .then(utils.waitForInvocationReaction(connection.bee, (r) => {
        if (r.details.code === 0) {
            return r.details.message;
        }
        else {
            alert(r.details.message);
        }
    }));
}
function revokeDevice(device, patientID) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke(`${device.toLocaleLowerCase()}.revokeDevice`, patientID)
        .then(utils.waitForInvocationReaction(connection.bee, (r) => r.details.message));
}
function addSurveyResults(responses, version) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('ecarepd_survey.addSurveyResults', responses, version)
        .then(utils.waitForInvocationReaction(connection.bee, (r) => r.details.message));
}
function getOldestPatientResponse(patientID, symptomPriorityID) {
    return __awaiter(this, void 0, void 0, function* () {
        const connection = assertLoggableConnection();
        return connection.bee.storage
            .executeQuery('ecarepd', 'getOldestPatientPriorityResponse', [
            patientID,
            symptomPriorityID,
        ])
            .then((res) => {
            return _.first(res);
        });
    });
}
function exportPatientData(patientID, lang) {
    const connection = assertLoggableConnection();
    return connection.bee.api.invoke('extractPatientData', patientID, lang);
}
export const PatientService = {
    invitePatient,
    setPatientPriorities,
    assertJournalEntry,
    updateJournalEntry,
    updatePatientPriorityResponse,
    deleteMyAccount,
    addPatientPriorityResponse,
    updatePatient,
    formatDate,
    createCallRequest,
    completeCallRequest,
    setResponseCareTip,
    addCareTeamMember,
    updateCareTeamMember,
    removeCareTeamMember,
    requestAuthUrl,
    refreshData,
    revokeDevice,
    addSurveyResults,
    getOldestPatientResponse,
    exportPatientData,
};
