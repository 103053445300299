import { ComponentsOverrides } from '@mui/material';

const styleOverrides: ComponentsOverrides['MuiContainer'] = {
  root: {
    '.patient-priority-background .MuiToggleButton-root &': {
      // The values in here were copied or eyeballed from the Figma screens
      '> svg': {
        width: '52px',
        height: '52px',
        pointerEvents: 'none',
      },

      div: {
        padding: 0,
        position: 'absolute',
        right: '-4px',
        top: '2px',
        '> svg': {
          width: '24px',
          height: '24px',
          pointerEvents: 'none',
        },
      },
    },
  },
};

export const MuiContainer = {
  styleOverrides,
};
