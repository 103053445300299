import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import {
  DialogTitleWithClose,
  PatientService,
  SymptomPriority,
  useApiCallWithMessageBar,
} from 'ecarepd-shared-utilities';
import { AnalyticsService, LoggedDialog } from 'hive-analytics-react';
import {
  ChangeEvent,
  PropsWithRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

export interface NoteDialogProps {
  reason: string;
  responseId?: string;
  symptomPriority: SymptomPriority;
  open: boolean;
  onClose: () => void;
  note: string;
  setNote: (note: string) => void;
}
export function NoteDialog({
  reason,
  responseId,
  symptomPriority,
  note,
  setNote,
  open,
  onClose,
}: PropsWithRef<NoteDialogProps>): JSX.Element {
  const { t } = useTranslation();

  const [value, setValue] = useState(note);
  useEffect(() => {
    setValue(note);
  }, [open, note]);

  const onChange = useCallback(
    (event: ChangeEvent<any>) => setValue(event.target.value),
    []
  );

  // This is only used when we have a responseId
  const { progress: submitting, callback: onContinue } =
    useApiCallWithMessageBar({
      canExecute: () => !!responseId,
      execute: () =>
        PatientService.updatePatientPriorityResponse(responseId!, {
          note: value,
        }).then(() => {
          setNote(value);
          onClose();
        }),
      success: {
        message: t('common.success'),
        snackbarProps: {
          autoHideDuration: 2000,
        },
      },
      failure: {
        message: t('common.failure'),
      },
    });

  const onAddNote = useCallback(() => {
    AnalyticsService.logClick('ADD_NOTE');
    setNote(value);
    onClose();
  }, [value, setNote, onClose]);

  return (
    <LoggedDialog
      dialogName="note-dialog"
      extraData={{ reason }}
      open={open}
      onClose={onClose}
    >
      <DialogTitleWithClose onClose={onClose} disabled={submitting}>
        {t('content.history.care_priority_history.note_dialog.title', {
          symptomName: symptomPriority.properties.name,
        })}
      </DialogTitleWithClose>

      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          multiline
          rows={3}
          value={value}
          onChange={onChange}
        />
        <Typography>
          {t('content.history.care_priority_history.note_dialog.please_note')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button disabled={submitting} onClick={onClose}>
          {t('common.cancel')}
        </Button>

        {responseId && (
          <Button disabled={submitting} onClick={onContinue}>
            {t('common.continue')}
          </Button>
        )}

        {!responseId && (
          <Button disabled={submitting} onClick={onAddNote}>
            {t('content.history.care_priority_history.note_dialog.add_note')}
          </Button>
        )}
      </DialogActions>
    </LoggedDialog>
  );
}
