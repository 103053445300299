import { ComponentsOverrides, Theme } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides<Theme>['MuiTypography'] = {
  root: {
    whiteSpace: 'pre-wrap',
  },
  body1: {
    color: COLOURS.Black,
    '.patient-symptom-details-content &': {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '32px',
      letterSpacing: '0.6875px',
      color: COLOURS.DarkerGrey,
    },
    '.call-request-content &': {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '32px',
      letterSpacing: '0.5px',
      color: COLOURS.DarkerGrey,
    },
    '.call-request-content label &': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '21px',
      lineHeight: '25px',
      color: COLOURS.DarkerGrey,
    },
    '.under_construction &': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '25px',
      color: COLOURS.DarkerGrey,
    },
    '.patient-journal-entry-content &': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
    },
    '.patient-journal-carePriority-content &': {
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
    },
    '.patient-resources &': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '24px',
      color: COLOURS.DarkerGrey,
    },
    '.patient-resource-card &': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.5px',
      whiteSpace: 'pre-line',
      color: COLOURS.DarkerGrey,
    },

    '&.map-item': {
      position: 'absolute',
      color: COLOURS.White,
      fontWeight: 'bold',
      left: '50%',
      top: '50%',
      zIndex: '1000',
      transform: 'translate(-45%, -75%)',
    },
    '.wearable-data-card-steps &': {
      fontStyle: 'italic',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '26px',
      color: COLOURS.Green,
    },
    '.wearable-data-card-sleepQuality &': {
      fontStyle: 'italic',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '26px',
      color: COLOURS.DarkBlue,
    },

    '.care-team-card &': {
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '16px',
    },
  },
  body2: {
    color: COLOURS.DarkGrey,
    '.patient-symptom-details-content &': {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '20px',
      letterSpacing: '0.5625px',
      color: COLOURS.DarkerGrey,
    },
    '.call-request-content &': {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '22px',
      letterSpacing: '-0.482353px',
      color: COLOURS.RoyalBlue,
    },
    '.patient-journal-carePriority-content &': {
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      color: COLOURS.DarkerGrey,
    },

    '.patient-editInfo-content &': {
      fontStyle: 'normal',
      fontSize: '16px',
      color: COLOURS.DarkGrey,
      paddingLeft: `${spacing * 2.5}px`,
      margin: '0px',
    },
    '.patient-resources &': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '22px',
      color: COLOURS.DarkerGrey,
    },
    '.wearable-data-card &': {
      fontStyle: 'italic',
      fontWeight: '500',
      fontSize: '13px',
      lineHeight: '16px',
    },
    '.care-team-card &': {
      color: COLOURS.RoyalBlue,
      fontWeight: '400',
      textDecoration: 'underline',
      fontSize: '14px',
    },
  },

  h4: {
    color: COLOURS.Primary,
    '.under_construction &': {
      fontStyle: 'italic',
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '29px',
      color: COLOURS.RoyalBlue,
    },
    '.care-team-card &': {
      color: COLOURS.RoyalBlue,
      fontWeight: '900',
      fontSize: '24px',
    },
  },
  h5: {
    '.patient-priority-history &': {
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '29px',
      color: COLOURS.RoyalBlue,
    },
    '.under_construction &': {
      fontStyle: 'italic',
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '24px',
      color: COLOURS.RoyalBlue,
    },
    '.patient-journal-title &': {
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '29px',
      color: COLOURS.DarkBlue,
    },
    '.patient-resources &': {
      fontStyle: 'italic',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '29px',
      color: COLOURS.RoyalBlue,
    },
    '.patient-resource-card &': {
      fontStyle: 'italic',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0.5px',
      color: COLOURS.Primary,
    },
  },
  h6: {
    '.patient-priority-history &': {
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '22px',
      color: COLOURS.DarkGrey,
    },
    '.patient-journal-carePriority &': {
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '22px',
      color: COLOURS.DarkGrey,
    },
    '.patient-symptom-details-content &': {
      fontStyle: 'italic',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '26px',
      letterSpacing: '0.6875px',
      color: COLOURS.RoyalBlue,
    },

    '.patient-editInfo-content &': {
      fontStyle: 'normal',
      fontSize: '13px',
      color: COLOURS.DarkGrey,
      paddingLeft: `${spacing * 2.5} px`,
      margin: '0px',
    },

    '.care-team &': {
      color: COLOURS.DarkerGrey,
      fontWeight: 400,
    },
  },
  subtitle1: {
    '.care-team-card &': {
      color: COLOURS.DarkGrey,
      fontSize: '14px',
    },
  },
};

export const MuiTypography = {
  styleOverrides,
};
