import { ComponentsOverrides } from '@mui/material';
import { theme } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiTableRow'] = {
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.MuiTableRow-hover:hover': {
      backgroundColor: 'initial',
    },
  },
};

export const MuiTableRow = {
  styleOverrides: styleOverrides,
};
