import { useMemo, PropsWithRef } from 'react';
import _ from 'lodash';
import {
  Stack,
  Typography,
  Slider,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLOURS } from 'ecarepd-shared-utilities';

const REGEX_POSTAL_CODE =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
const SLIDER_STEPS = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 5 },
  { value: 8 },
  { value: 10 },
  { value: 15 },
  { value: 25 },
  { value: 35 },
  { value: 50 },
  { value: 75 },
  { value: 100 },
];
export interface CareFinderSidebarProps {
  sortedServices: {};
  selectedServices: {};
  onClick: (v: string) => void;
  setAddress: (v: string) => void;
  onSearch: () => void;
  distance: number;
  typedAddress: string;
  setDistance: (v: number) => void;
  showInHome: boolean;
  setShowInHome: () => void;
  allSelected: boolean;
  setAllSelected: () => void;
  noResults: boolean;
  addressError: string;
  setAddressError: (v: string) => void;
  errorShown: boolean;
  setErrorShown: (v: boolean) => void;
}

export default function CareFinderSidebar({
  sortedServices,
  onClick,
  onSearch,
  setAddress,
  typedAddress,
  distance,
  setDistance,
  showInHome,
  setShowInHome,
  selectedServices,
  setAllSelected,
  allSelected,
  noResults,
  addressError,
  setAddressError,
  setErrorShown,
  errorShown,
}: PropsWithRef<CareFinderSidebarProps>): JSX.Element {
  const { t } = useTranslation();

  const onChange = useMemo(
    () =>
      _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || null;
        if (!value) {
          setErrorShown(false);
          return;
        }

        const match = REGEX_POSTAL_CODE.exec(value.trim());

        if (match) {
          setAddress(e.target.value);
        } else {
          setAddressError(t('content.care_finder.postal_code_error'));
          setErrorShown(true);
        }
      }, 500),
    [setAddress, setAddressError, setErrorShown, t]
  );

  return (
    <Stack flexBasis="100%" maxWidth="40%" marginRight={8}>
      {noResults && (
        <Stack>
          <Typography fontWeight="bold" variant="h5" color="primary">
            {t('content.care_finder.no_results_header')}
          </Typography>
          <Typography fontWeight="bold" variant="h6" color="primary">
            {t('content.care_finder.no_results_subheader')}
          </Typography>
        </Stack>
      )}
      <Stack flexDirection="row" gap={2}>
        <Stack flexBasis="50%" margin={2} marginRight={4} rowGap={6}>
          <Typography fontWeight="bold" variant="h6">
            {t('content.care_finder.where')}
          </Typography>
          <TextField
            defaultValue={typedAddress}
            onChange={onChange}
            variant="standard"
            placeholder={
              t('content.care_finder.where_placeholder') || undefined
            }
          />
        </Stack>
        <Stack flexBasis="50%" margin={2} marginRight={10} rowGap={8}>
          <Typography fontWeight="bold" variant="h6">
            {t('content.care_finder.distance')}
          </Typography>
          <Slider
            key="distance-slider"
            value={distance}
            onChange={(event, value) => setDistance(Number(value))}
            step={null}
            min={1}
            max={100}
            marks={SLIDER_STEPS}
            valueLabelDisplay="on"
            valueLabelFormat={(value) =>
              `${t('content.care_finder.km_display', { value })}`
            }
          />
        </Stack>
      </Stack>

      {errorShown && (
        <Stack>
          <Typography color={COLOURS.Red}>{addressError}</Typography>
        </Stack>
      )}

      <Stack
        flexDirection="row"
        justifyContent="space-between"
        marginLeft={2}
        alignItems="center"
      >
        <Typography fontWeight="bold" variant="h6">
          {t('content.care_finder.in_home')}
        </Typography>
        <FormControlLabel
          control={<Switch value={showInHome} onChange={setShowInHome} />}
          label={showInHome ? t('common.yes') : t('common.no')}
        />
      </Stack>

      <Stack marginBottom={2} marginLeft={2}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={2}
          alignItems="center"
        >
          <Typography fontWeight="bold" variant="h6">
            {t('content.care_finder.services')}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox checked={allSelected} onClick={setAllSelected} />
            }
            label={t('content.care_finder.select_all')}
          />
        </Stack>

        <Stack flexDirection="row" flexWrap="wrap">
          {_.map(sortedServices, (service, index) => (
            <Stack flex="50%" key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    key={index}
                    checked={_.includes(selectedServices, index) || allSelected}
                    onClick={() => onClick(index)}
                  />
                }
                label={_.startCase(_.toLower(index))}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Button variant="outlined" onClick={onSearch} disabled={noResults}>
        {t('content.care_finder.search')}
      </Button>
    </Stack>
  );
}
