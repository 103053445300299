import { ResourceGroup } from './ResourceGroup';
import { ReactComponent as Icon } from '../../../assets/resources/Specialists.svg';
import { Stack } from '@mui/material';
import { Link } from './Link';

export function Specialists(): JSX.Element {
  return (
    <ResourceGroup
      titleId="content.resources.specialists.title"
      infoId="content.resources.specialists.info"
      Icon={Icon}
    >
      <Stack paddingLeft={4} paddingTop={4}>
        <Link strings="content.resources.specialists.sites.cmdg" />
      </Stack>
    </ResourceGroup>
  );
}
