import {
  List,
  ListItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableCellProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const SCORES = [
  {
    score: '0 - 4',
  },
  {
    score: '5 - 9',
  },
  {
    score: '10 - 14',
  },
  {
    score: '15 - 19',
  },
  {
    score: '20 - 27',
  },
];
export interface ScoreGuidePHQ9ResultsProps {
  columns: (
    | { id: string; label: string; align: string; minWidth?: number }
    | { id: string; label: string; minWidth: number; align: string }
  )[];
  rows: { score: string; severity: string; action: string }[];
}

export function getScoreIndex(userScore: number) {
  let scoreIndex = Infinity;
  SCORES.find(({ score }, index) => {
    const [min, max] = score.split(' - ').map(Number);
    if (userScore >= min && userScore <= max) {
      scoreIndex = index;
      return true;
    }
    return false;
  });
  return scoreIndex;
}

export interface ScoreGuideTableProps extends ScoreGuidePHQ9ResultsProps {
  userScore?: number;
}

export function ScoreGuideTable({
  columns,
  rows,
  userScore,
}: ScoreGuideTableProps) {
  const { t } = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center" colSpan={3}>
            <Typography fontWeight="bold" variant="h5">
              {t('content.depression_survey.guide_table_header_title')}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align={(column.align as TableCellProps['align']) || 'left'}
              style={{
                top: 57,
                minWidth: column.minWidth,
              }}
            >
              <b>{column.label}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {rows.map((row, index) => (
          <TableRow hover tabIndex={-1} key={row.score}>
            {columns.map((column) => {
              const value = row[column.id as keyof typeof row];
              return (
                <TableCell
                  key={column.id}
                  align={column.align as TableCellProps['align']}
                  className={
                    userScore && index === getScoreIndex(userScore)
                      ? 'highlighted'
                      : ''
                  }
                  width="auto"
                >
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function ScoreResultsGuidePHQ9({
  columns,
  rows,
}: ScoreGuidePHQ9ResultsProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h4"
        fontWeight="bold"
        paddingBottom={4}
        alignSelf="center"
        color="rgb(26, 179, 148)"
      >
        {t('content.depression_survey.guide_title')}
      </Typography>

      <Typography variant="h5" fontWeight="bold">
        {t('content.depression_survey.description1')}
      </Typography>

      <List>
        <ListItem>{t('content.depression_survey.list_item1')}</ListItem>
        <ListItem>{t('content.depression_survey.list_item2')}</ListItem>
      </List>

      <Typography variant="h5" fontWeight="bold">
        {t('content.depression_survey.description2')}
      </Typography>

      <List>
        <ListItem>{t('content.depression_survey.list_item3')}</ListItem>
        <ListItem>{t('content.depression_survey.list_item4')}</ListItem>
      </List>

      <Typography>{t('content.depression_survey.description3')}</Typography>

      <ScoreGuideTable columns={columns} rows={rows} />

      <Typography variant="h5" fontWeight="bold" paddingTop={8}>
        {t('content.depression_survey.health_assessment')}
      </Typography>
      <Typography>{t('content.depression_survey.description4')}</Typography>

      <Typography paddingTop={4}>
        <b> {t('content.depression_survey.note')} </b>
        {t('content.depression_survey.description5')}
      </Typography>

      <Typography fontSize="small" paddingTop={10}>
        {t('content.depression_survey.description6')}
      </Typography>

      <Typography fontSize="small" paddingTop={4}>
        <b>{t('content.depression_survey.reference')} </b>
        {t('content.depression_survey.description7')}
      </Typography>
    </>
  );
}
