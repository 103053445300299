import { useConnection } from 'hive-react-utils';
import { useEffect, useState } from 'react';
export function useBlobById(blobId) {
    const connection = useConnection();
    const [blob, setBlob] = useState();
    useEffect(() => {
        if (!blobId || !(connection === null || connection === void 0 ? void 0 : connection.bee)) {
            return setBlob(undefined);
        }
        connection.bee.blob
            .download(blobId)
            .then(({ content, contentType }) => new Blob([content], { type: contentType }))
            .then((b) => URL.createObjectURL(b))
            .then(setBlob)
            .catch(console.error);
    }, [blobId, connection]);
    return blob;
}
