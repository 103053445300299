import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, CircularProgress, Grid, Stack, Tooltip, Typography, } from '@mui/material';
import _ from 'lodash';
import { useCurrentLanguage } from 'hive-react-utils';
import WarningIcon from '@mui/icons-material/Warning';
import { useApiCallWithMessageBar } from '../hooks';
import { CommonDataContext } from '../contexts';
import { useDailyWearableData, useFitbitSubs, useWearables } from '../hooks';
import { PatientService } from '../services';
import { ActivityIcon, SleepIcon } from '../assets';
import { AnalyticsService } from 'hive-analytics-react';
import moment from 'moment';
function Connected({ fitbitActivitiesSubs, fitbitSleepSubs, steps, sleepDuration, device, }) {
    const { t } = useTranslation();
    return (_jsx(Box, Object.assign({ paddingX: 4, flex: 1 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 6, className: "wearable-data-card", justifyContent: "center" }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Box, Object.assign({ className: "wearable-data-card-steps", position: "relative", width: 230 }, { children: [_jsx(Card, { children: _jsx(CardContent, { children: _jsxs(Stack, Object.assign({ direction: "row", alignItems: "center", spacing: 4, paddingX: 6, minHeight: 68 }, { children: [_jsx(Box, Object.assign({ width: 48 }, { children: _jsx(Stack, Object.assign({ direction: "row", justifyContent: "center" }, { children: _jsx(ActivityIcon, {}) })) })), _jsxs(Stack, { children: [_jsx(Typography, { children: steps ? _.toNumber(steps).toLocaleString() : '--' }), _jsx(Typography, Object.assign({ variant: "body2" }, { children: t('content.wearable_daily.steps_label') }))] })] })) }) }), _jsx(Box, Object.assign({ position: "absolute", top: 0, left: 0 }, { children: device === 'fitbit' &&
                                    fitbitActivitiesSubs &&
                                    !_.first(fitbitActivitiesSubs) && (_jsx(Tooltip, Object.assign({ title: t('content.wearable_daily.steps_noAccess') }, { children: _jsx(Box, Object.assign({ padding: 2 }, { children: _jsx(WarningIcon, { fontSize: "small", color: "error" }) })) }))) }))] })) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsxs(Box, Object.assign({ className: "wearable-data-card-sleepQuality", position: "relative", width: 230 }, { children: [_jsx(Card, { children: _jsx(CardContent, { children: _jsxs(Stack, Object.assign({ direction: "row", alignItems: "center", spacing: 4, paddingX: 6, minHeight: 68 }, { children: [_jsx(Box, Object.assign({ width: 48 }, { children: _jsx(SleepIcon, {}) })), _jsxs(Stack, { children: [_jsx(Typography, Object.assign({ variant: "body2" }, { children: t('content.wearable_daily.sleep_label') })), _jsx(Typography, { children: _.isNil(sleepDuration) ? ('--') : (_jsxs(Stack, { children: [_jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, { children: sleepDuration.hours() }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "body2", component: "span" }, { children: t('content.wearable_daily.sleep_hours') })) }))] })), _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, { children: sleepDuration.minutes() }) })), _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Typography, Object.assign({ variant: "body2", component: "span" }, { children: t('content.wearable_daily.sleep_minutes') })) }))] }))] })) })] })] })) }) }), _jsx(Box, Object.assign({ position: "absolute", top: 4, left: 4 }, { children: device === 'fitbit' &&
                                    fitbitSleepSubs &&
                                    !_.first(fitbitSleepSubs) && (_jsx(Tooltip, Object.assign({ title: t('content.wearable_daily.sleep_noAccess') }, { children: _jsx(Box, Object.assign({ padding: 2 }, { children: _jsx(WarningIcon, { fontSize: "small", color: "error" }) })) }))) }))] })) }))] })) })));
}
export function WearableDaily({ patientID, hideConnect, disableConnect, }) {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation();
    const { featureFlags } = useContext(CommonDataContext);
    const language = useCurrentLanguage();
    const wearables = useWearables(patientID);
    const wearableID = ((_a = _.first(wearables)) === null || _a === void 0 ? void 0 : _a.id) || '';
    const wearableDevice = ((_b = _.first(wearables)) === null || _b === void 0 ? void 0 : _b.properties.device) || '';
    const dailyWearableData = useDailyWearableData(wearableID);
    const steps = (_c = dailyWearableData === null || dailyWearableData === void 0 ? void 0 : dailyWearableData.activity) === null || _c === void 0 ? void 0 : _c.properties.steps;
    const sleepDuration = moment.duration((_d = dailyWearableData === null || dailyWearableData === void 0 ? void 0 : dailyWearableData.sleep) === null || _d === void 0 ? void 0 : _d.properties.minutesAsleep, 'm');
    const fitbitActivitiesSubs = useFitbitSubs(patientID, 'activities');
    const fitbitSleepSubs = useFitbitSubs(patientID, 'sleep');
    const lastUpdated = ((_e = _.first(wearables)) === null || _e === void 0 ? void 0 : _e.modified)
        ? moment((_f = _.first(wearables)) === null || _f === void 0 ? void 0 : _f.modified).locale(language).format('LLLL')
        : '';
    const handleConnect = (device) => {
        AnalyticsService.logClick('WEARABLE_CONNECT', { wearableDevice });
        PatientService.requestAuthUrl(device).then((uri) => window.location.assign(uri));
    };
    const { progress: refreshing, callback: onRefreshWearable } = useApiCallWithMessageBar({
        canExecute: () => !!wearableDevice,
        execute: () => {
            AnalyticsService.logClick(`${wearableDevice.toUpperCase()}_REFRESH`);
            return PatientService.refreshData(wearableDevice);
        },
        success: {
            snackbarProps: {
                autoHideDuration: 2000,
            },
            message: t('common.success'),
        },
        failure: {
            message: t('common.failure'),
        },
    });
    if (!wearables || !patientID) {
        return _jsx(CircularProgress, {});
    }
    if (wearableID) {
        return (_jsxs(Stack, Object.assign({ spacing: 4 }, { children: [_jsx(Connected, { fitbitActivitiesSubs: fitbitActivitiesSubs, fitbitSleepSubs: fitbitSleepSubs, steps: steps, sleepDuration: sleepDuration, device: wearableDevice }), !disableConnect &&
                    !featureFlags.HIDE_MAIN_REFRESH && // temporary UI hiding for prod
                    !featureFlags.GARMIN && (_jsx(Button, Object.assign({ variant: "outlined", onClick: onRefreshWearable, disabled: refreshing }, { children: t(`settings.${wearableDevice}.refresh`) }))), lastUpdated &&
                    !featureFlags.HIDE_MAIN_REFRESH && ( // temporary UI hiding for prod
                _jsxs(Typography, Object.assign({ fontSize: "small", paddingLeft: 6 }, { children: [t('content.wearable_daily.last_updated'), " ", lastUpdated] })))] })));
    }
    if (hideConnect) {
        return _jsx(_Fragment, {});
    }
    if (disableConnect) {
        return (_jsx(Box, Object.assign({ paddingX: 4, flex: 1 }, { children: _jsx(Typography, { children: t('content.wearable_daily.not_connected') }) })));
    }
    return (_jsxs(Box, Object.assign({ paddingX: 4, flex: 1 }, { children: [_jsx(Stack, { children: _jsx(Typography, { children: t('content.wearable_daily.connect_info') }) }), featureFlags.FITBIT && (_jsx(Stack, Object.assign({ spacing: 8, margin: 3 }, { children: _jsx(Button, Object.assign({ variant: "outlined", onClick: () => handleConnect('fitbit') }, { children: t('content.wearable_daily.connect_fitbit_header') })) }))), featureFlags.GARMIN && (_jsx(Stack, Object.assign({ spacing: 8 }, { children: _jsx(Button, Object.assign({ variant: "outlined", onClick: () => handleConnect('garmin') }, { children: t('content.wearable_daily.connect_garmin_header') })) })))] })));
}
