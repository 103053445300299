import _ from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageBarContext } from '../contexts/MessageBar';
export function useApiCallWithMessageBar({ canExecute, execute, success, failure, confirmation, }) {
    const { openMessageBar, closeMessageBar } = useContext(MessageBarContext);
    const { t } = useTranslation();
    const [progress, setProgress] = useState(false);
    const executeApi = useCallback((...props) => {
        if (canExecute && !canExecute()) {
            return Promise.reject();
        }
        setProgress(true);
        return execute(...props)
            .then(() => {
            openMessageBar(true, (success === null || success === void 0 ? void 0 : success.message) || t('common.success'), Object.assign({ autoHideDuration: 5000, onClose: () => {
                    setProgress(false);
                    closeMessageBar();
                    if (success === null || success === void 0 ? void 0 : success.onClose) {
                        success === null || success === void 0 ? void 0 : success.onClose();
                    }
                } }, success === null || success === void 0 ? void 0 : success.snackbarProps), Object.assign({ severity: 'success' }, success === null || success === void 0 ? void 0 : success.alertProps));
        })
            .catch((error) => {
            console.error(error);
            openMessageBar(true, (failure === null || failure === void 0 ? void 0 : failure.message) || t('common.failure'), Object.assign({ onClose: () => {
                    setProgress(false);
                    closeMessageBar();
                    if (failure === null || failure === void 0 ? void 0 : failure.onClose) {
                        failure === null || failure === void 0 ? void 0 : failure.onClose();
                    }
                } }, failure === null || failure === void 0 ? void 0 : failure.snackbarProps), Object.assign({ severity: 'error' }, failure === null || failure === void 0 ? void 0 : failure.alertProps));
        });
    }, [canExecute, execute, openMessageBar, success, failure, t, closeMessageBar]);
    const executeApiWithConfirmation = useCallback((...props) => {
        if (!(confirmation === null || confirmation === void 0 ? void 0 : confirmation.confirmFn)) {
            // will not happen, but shuts up the linter
            return Promise.reject();
        }
        return confirmation
            .confirmFn(_.pickBy({
            title: confirmation === null || confirmation === void 0 ? void 0 : confirmation.title,
            description: confirmation === null || confirmation === void 0 ? void 0 : confirmation.description,
            contentProps: {
                dividers: true,
            },
            confirmationText: confirmation === null || confirmation === void 0 ? void 0 : confirmation.confirmationText,
            cancellationText: confirmation === null || confirmation === void 0 ? void 0 : confirmation.cancellationText,
        }))
            .then(() => executeApi(...props))
            .catch(() => {
            // just swallow the error, it's just the user cancelling
        });
    }, [confirmation, executeApi]);
    const callback = useMemo(() => {
        return (confirmation === null || confirmation === void 0 ? void 0 : confirmation.enabled) ? executeApiWithConfirmation : executeApi;
    }, [confirmation, executeApi, executeApiWithConfirmation]);
    return useMemo(() => ({ progress, callback }), [progress, callback]);
}
