import { useInstancesByQuery } from 'hive-react-utils';
import { useMemo } from 'react';
import _ from 'lodash';
import { useStartOfDay } from './useStartOfDay';
import moment from 'moment';
export function useDailyWearableData(wearableID) {
    const startOfDay = useStartOfDay();
    const activities = useInstancesByQuery('ecarepd', 'activityByIdsDate', 'activity', {}, wearableID, moment(startOfDay).utc(true).toISOString());
    const sleeps = useInstancesByQuery('ecarepd', 'sleepByIdsDate', 'sleep', {}, wearableID, moment(startOfDay).utc(true).toISOString());
    // Make sure we ignore pending status
    return useMemo(() => ({
        activity: _.first(activities),
        sleep: _.first(sleeps),
    }), [activities, sleeps]);
}
