import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { createElement, Fragment, useContext, useMemo, } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonDataContext } from '../../contexts';
import { scaleValueByResponse } from '../ScaleConstants';
import { SleepIconSm, ActivityIconSm } from '../../assets';
import { ReactComponent as Tick } from './legends/tick.svg';
function getIdFromKey(key) {
    const match = key.match(/^([^.]*).value$/);
    if (!match) {
        return key;
    }
    const [, id] = match;
    return id;
}
const labelFormatter = (label) => moment.unix(label).utc().format('LL');
const NAME_BY_WEARABLE_DATA = {
    activity: 'content.patient_chart.activity_legend',
    sleep: 'content.patient_chart.sleep_legend',
};
const ICON_BY_WEARABLE_DATA = {
    activity: () => _jsx(ActivityIconSm, {}),
    sleep: () => _jsx(SleepIconSm, {}),
};
const WEARABLE_TYPES = ['activity', 'sleep'];
function ChartLegendRow({ color, dataKey, value, }) {
    const { symptomPrioritiesById } = useContext(CommonDataContext);
    const name = useMemo(() => {
        const id = getIdFromKey(dataKey);
        return _.get(symptomPrioritiesById, [id, 'properties', 'name']);
    }, [dataKey, symptomPrioritiesById]);
    const icon = useMemo(() => {
        const scaleValue = scaleValueByResponse(value);
        if (scaleValue === null || scaleValue === void 0 ? void 0 : scaleValue.icon) {
            return createElement(scaleValue.icon, { width: 32, height: 32 });
        }
        return value;
    }, [value]);
    if (value < 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, Object.assign({ direction: "row", color: color, alignItems: "center", spacing: 1 }, { children: [_jsx(Tick, {}), _jsx(Typography, Object.assign({ variant: "body1", color: color }, { children: name }))] })), _jsx(Stack, Object.assign({ marginLeft: 1 }, { children: icon }))] }));
}
function ChartLegendRowWearableData({ color, dataKey, payload, }) {
    const { t } = useTranslation();
    const id = useMemo(() => getIdFromKey(dataKey), [dataKey]);
    const name = useMemo(() => t(NAME_BY_WEARABLE_DATA[id]), [id, t]);
    const rawValue = useMemo(() => _.get(payload, [id, 'rawValue']), [id, payload]);
    const icon = useMemo(() => ICON_BY_WEARABLE_DATA[id](), [id]);
    if (!rawValue) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, Object.assign({ direction: "row", color: color, alignItems: "center", spacing: 1, height: 30 }, { children: [icon, _jsxs(Typography, Object.assign({ variant: "body1" }, { children: [id === 'sleep' && name, _jsx(Typography, Object.assign({ variant: "body1", color: color, component: "span" }, { children: rawValue })), id === 'activity' && name] }))] })), _jsx(Stack, {})] }));
}
export function ChartLegend({ label, payload, }) {
    return (_jsxs(Paper, { children: [_jsx(Typography, Object.assign({ variant: "h4" }, { children: labelFormatter(label) })), _jsx(Stack, Object.assign({ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center" }, { children: _.map(payload, (p) => (_jsx(Fragment, { children: _.includes(WEARABLE_TYPES, getIdFromKey(p.dataKey)) ? (_jsx(ChartLegendRowWearableData, Object.assign({}, p))) : (_jsx(ChartLegendRow, Object.assign({}, p))) }, p.dataKey))) }))] }));
}
