import { Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import htmlReactParser from 'html-react-parser';
import TabPanel from './TabPanel';
import { ReactComponent as Intake } from '../../assets/FTE2.svg';

export default function Tab2(props: { value: number }) {
  const { value } = props;
  const { t } = useTranslation();

  return (
    <TabPanel value={value} index={1}>
      <Stack display="flex" flexDirection="row">
        <Intake />
        <Typography>{htmlReactParser(t('content.tutorial.tab2'))}</Typography>
      </Stack>
    </TabPanel>
  );
}
