import { COLOURS } from './colours';
import { spacing } from './constants';
const styleOverrides = {
    root: {
        maxWidth: '110px',
        backgroundColor: COLOURS.Primary,
        '& .MuiTab-labelIcon': {
            color: COLOURS.White,
            margin: `${spacing}px`,
            marginRight: '0',
            '> svg': {
                color: COLOURS.White,
                padding: '0',
                alignSelf: 'center',
                pointerEvents: 'none',
            },
        },
        '.MuiTabs-indicator': {
            backgroundColor: COLOURS.White,
        },
        '& .Mui-selected': {
            minHeight: 'unset',
            backgroundColor: COLOURS.White,
            margin: `${spacing}px`,
            marginRight: '0',
            color: COLOURS.Primary,
            borderRadius: '8px 0 0 8px',
            '> svg': {
                color: COLOURS.Primary,
            },
        },
    },
    vertical: {
        height: '100%',
    },
};
export const MuiTabs = {
    styleOverrides,
};
