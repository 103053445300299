var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import _ from 'lodash';
import { assertLoggableConnection } from 'hive-analytics-react';
import moment from 'moment';
import { getWaveBlob } from 'webm-to-wav-converter';
import Resumable from 'resumablejs';
import { ConfigService } from 'hive-client-utils';
import { isDesktop, isMobile, mobileVendor } from 'react-device-detect';
import { getFeatureFlags } from '../hooks';
const FEATURE_FLAGS = getFeatureFlags();
function assertSondeFeatureFlagOrThrow() {
    if (!FEATURE_FLAGS.SONDE) {
        throw new Error('Sonde feature is not enabled!');
    }
}
const getDeviceType = _.cond([
    [_.constant(isMobile), () => 'MOBILE'],
    [_.constant(isDesktop), () => 'DESKTOP'],
    [_.stubTrue, () => 'OTHER'],
]);
const getManufacturer = () => mobileVendor || 'OTHER';
function sondeGenderFromPatient(patient) {
    switch (patient.properties.gender) {
        case 'M':
            return 'MALE';
        case 'F':
            return 'FEMALE';
        default:
            return 'OTHER';
    }
}
function uploadBlob(connection, blob, blobId) {
    const config = ConfigService.assertConfig();
    const url = `${config.host}/blob/upload/${blobId}`;
    const contentType = blob.type;
    const token = connection.token.access_token;
    return new Promise((resolveFn, rejectFn) => {
        const r = new Resumable({
            target: url,
            forceChunkSize: true,
            simultaneousUploads: 1,
            headers: {
                Authorization: `Token ${token}`,
            },
            // The auto-detect doesn't seem to work, so force resumableType as an extra query parameter if specified
            query: contentType ? { resumableType: contentType } : undefined,
        });
        r.on('fileAdded', () => r.upload());
        r.on('fileError', (_file, message) => rejectFn(message));
        r.on('complete', () => resolveFn());
        r.on('error', (message) => rejectFn(message));
        const file = new File([blob], `${blobId}.wav`);
        r.addFile(file);
    });
}
export function uploadFile(patient, blob, language) {
    return __awaiter(this, void 0, void 0, function* () {
        assertSondeFeatureFlagOrThrow();
        const connection = assertLoggableConnection();
        if (!patient.properties.sondeUserIdentifier) {
            const user = {
                yearOfBirth: moment(patient.properties.dateOfBirth).year(),
                gender: sondeGenderFromPatient(patient),
                language,
                device: {
                    type: getDeviceType(),
                    manufacturer: getManufacturer(),
                },
            };
            yield connection.bee.api.invoke('sonde.registerUser', user);
        }
        if (blob.type !== 'audio/wave') {
            blob = yield getWaveBlob(blob, false);
        }
        const { id: blobId } = yield connection.bee.api.invoke('sonde.createAudioJournalBlob');
        yield uploadBlob(connection, blob, blobId);
        const date = moment().startOf('day').toISOString();
        return connection.bee.api
            .invoke('sonde.addAudioJournal', date, blobId)
            .then(() => blob);
    });
}
export function downloadBlob(journal) {
    return __awaiter(this, void 0, void 0, function* () {
        assertSondeFeatureFlagOrThrow();
        if (!(journal === null || journal === void 0 ? void 0 : journal.properties.audioBlobId)) {
            throw new Error('Invalid journal entry: no audio blob!');
        }
        const connection = assertLoggableConnection();
        const raw = yield connection.bee.blob.download(journal.properties.audioBlobId);
        return new Blob([raw.content], { type: raw.contentType });
    });
}
export function playAudioJournal(journal) {
    return __awaiter(this, void 0, void 0, function* () {
        assertSondeFeatureFlagOrThrow();
        if (!(journal === null || journal === void 0 ? void 0 : journal.properties.audioBlobId)) {
            throw new Error('Invalid journal entry: no audio blob!');
        }
        const connection = assertLoggableConnection();
        const raw = yield connection.bee.blob.download(journal.properties.audioBlobId);
        const blob = new Blob([raw.content], { type: raw.contentType });
        const blobUrl = window.URL.createObjectURL(blob);
        return new Promise((resolve, reject) => {
            const audio = new Audio(blobUrl);
            audio.onended = () => resolve();
            audio.onerror = reject;
            audio.play();
            audio.pause();
        });
    });
}
export function getBlobDuration(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            const tempEl = document.createElement('audio');
            tempEl.onloadedmetadata = () => resolve(tempEl.duration);
            tempEl.onerror = (_event, _source, _linneno, _colno, error) => reject(error || new Error('Could not get duration'));
            tempEl.src = URL.createObjectURL(blob);
        });
    });
}
export function getBlobMetadata(blob) {
    return __awaiter(this, void 0, void 0, function* () {
        return {
            type: blob.type,
            size: blob.size,
            duration: yield getBlobDuration(blob),
        };
    });
}
export const SondeService = {
    uploadFile,
    downloadBlob,
    playAudioJournal,
    getBlobDuration,
    getBlobMetadata,
};
