import { useCurrentLanguage, useInstancesByClassName } from 'hive-react-utils';
import { useMemo } from 'react';
import { KeysOfSymptomPriorityForLanguage } from '../types';
import _ from 'lodash';
import _fp from 'lodash/fp';
function getValue(s, fieldName, language) {
    return (_.get(s, ['properties', 'languages', language, fieldName]) ||
        _.get(s, ['properties', 'languages', 'en', fieldName]) ||
        _.get(s, ['properties', fieldName]));
}
export function useSymptomPriorities() {
    const raw = useInstancesByClassName('ecarepd', 'symptomPriority');
    const language = useCurrentLanguage();
    return useMemo(() => {
        return _.map(raw, (s) => {
            const props = _fp.flow([
                _fp.map((k) => ({ [k]: getValue(s, k, language) })),
                _fp.assignInAll,
            ])(KeysOfSymptomPriorityForLanguage);
            return Object.assign(Object.assign({}, s), { properties: Object.assign(Object.assign({}, s.properties), props) });
        });
    }, [raw, language]);
}
