import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { useCallback, PropsWithRef } from 'react';

export interface InputFieldProps {
  autoComplete?: string;
  name: string;
  placeholder: string;
  value: string;
  label: string;
  required?: boolean;
  error?: boolean;
  inputProps?: any;
  setValue: (v: string) => void;
}

export default function InputField({
  name,
  autoComplete,
  placeholder,
  value,
  label,
  inputProps,
  setValue,
  required = false,
  error = false,
}: PropsWithRef<InputFieldProps>): JSX.Element {
  const { t } = useTranslation();
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [setValue]
  );
  return (
    <TextField
      autoComplete={autoComplete}
      inputProps={inputProps}
      value={value}
      error={error}
      onChange={onChange}
      name={name}
      required={required}
      placeholder={t(placeholder) || undefined}
      label={t(label)}
      variant="outlined"
    />
  );
}
