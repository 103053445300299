import { createTheme, ThemeOptions } from '@mui/material';
import { theme as baseTheme } from 'ecarepd-shared-utilities';
import { MuiButton } from './button';
import { MuiCard, MuiCardHeader, MuiCardContent } from './card';
import { MuiContainer } from './container';
import { MuiDialog, MuiDialogActions, MuiDialogTitle } from './dialog';
import { MuiDivider } from './divider';
import { MuiList, MuiListItem } from './list';
import { MuiPaper } from './paper';

import { MuiTabs } from './tabs';
import { MuiTypography } from './typography';
import { MuiTextField } from './textfield';
import { MuiTableRow } from './tableRow';
import { MuiTableCell } from './tableCell';

const updates: ThemeOptions = {
  components: {
    MuiButton,
    MuiCard,
    MuiCardContent,
    MuiCardHeader,
    MuiContainer,
    MuiDialog,
    MuiDialogActions,
    MuiDialogTitle,
    MuiDivider,
    MuiList,
    MuiListItem,
    MuiPaper,
    MuiTableCell,
    MuiTableRow,
    MuiTabs,
    MuiTextField,
    MuiTypography,
  },
};

export const patientTheme = createTheme(baseTheme, updates);
