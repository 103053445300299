import { Stack } from '@mui/material';
import { NewlyDiagnosed } from './info/NewlyDiagnosed';
import { MaintainingMeds } from './info/MaintainingMeds';
import { OtherCareIssues } from './info/OtherCareIssues';
import { Specialists } from './info/Specialists';
import { MoreResources } from './info/MoreResources';
import { MoreResearch } from './info/MoreResearch';
import { Using } from './info/Using';

export function Resources(): JSX.Element {
  return (
    <Stack spacing={6} className="patient-resources">
      <NewlyDiagnosed />
      <MaintainingMeds />
      <OtherCareIssues />
      <Specialists />
      <MoreResources />
      <MoreResearch />
      <Using />
    </Stack>
  );
}
