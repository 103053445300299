var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AnalyticsService, LoggedConnectionService, } from 'hive-analytics-react';
import { ConnectionService, ConfigService } from 'hive-client-utils';
export var AppNames;
(function (AppNames) {
    AppNames["Patient"] = "ecarepd-patient";
    AppNames["Clinician"] = "ecarepd-clinician";
})(AppNames || (AppNames = {}));
function init({ appName, keyToken, keyTokenExpiration, keyUsername, }) {
    return __awaiter(this, void 0, void 0, function* () {
        const BEE_NAME = process.env.REACT_APP_ANALYTICS_BEE_NAME ||
            window._env_.REACT_APP_ANALYTICS_BEE_NAME;
        const BEE_PASS = process.env.REACT_APP_ANALYTICS_BEE_PASS ||
            window._env_.REACT_APP_ANALYTICS_BEE_PASS;
        yield AnalyticsService.init({
            appName,
            environment: process.env.NODE_ENV,
            beeName: BEE_NAME,
            beePass: BEE_PASS,
            eventIds: {
                appStarted: 'App Started',
                signIn: {
                    start: 'Sign In',
                    end: 'Signed In',
                    error: 'Sign In Error',
                },
                click: 'User Click',
                signOut: {
                    start: 'Sign Out',
                    end: 'Signed Out',
                    error: 'Sign Out Error',
                },
                pageChange: {
                    start: 'Page Entered',
                    end: 'Page Left',
                    error: 'Page Error',
                },
                dialog: {
                    start: 'Dialog Displayed',
                    end: 'Dialog Hidden',
                    error: 'Dialog Error',
                },
                action: {
                    start: 'Start Action',
                    end: 'End Action',
                    error: 'Action Error',
                },
            },
        });
        const setBeeFn = (token, username) => {
            localStorage.setItem(keyToken, token.access_token);
            localStorage.setItem(keyTokenExpiration, token.access_token_expiry);
            localStorage.setItem(keyUsername, username);
        };
        const clearBeeFn = () => {
            localStorage.removeItem(keyToken);
            localStorage.removeItem(keyTokenExpiration);
            localStorage.removeItem(keyUsername);
        };
        const loadBeeFn = () => {
            const tokenExpiration = localStorage.getItem(keyTokenExpiration);
            // Check if we have a stored token, if so, we don't need the login screen
            const info = {
                token: localStorage.getItem(keyToken) || undefined,
                tokenExpiration: tokenExpiration ? parseInt(tokenExpiration) : undefined,
                username: localStorage.getItem(keyUsername) || undefined,
            };
            return info;
        };
        ConnectionService.setupCallbacks({
            setBeeFn,
            clearBeeFn,
            loadBeeFn,
        });
        // Init bee from local storage if possible (when config is available)
        ConfigService.config$.subscribe({
            next: (config) => {
                const { pathname } = window.location;
                if (pathname !== '/oidc') {
                    LoggedConnectionService.signInFromStorage(config);
                }
            },
            error: (error) => console.error('Could not get configuration', error),
        });
    });
}
export const LoginService = {
    init,
};
