import { forwardRef, useState, ReactElement, Ref } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material/';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { COLOURS } from 'ecarepd-shared-utilities';

import { ScoreGuideTable } from './ScoreResultsGuidePHQ9';
import { usePHQ9SurveyModel } from './SurveyUtils';

export interface ScoreResultsPHQ9Props {
  phq9Score: number;
  resetScore: () => void;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ScoreResultsPHQ9({
  phq9Score,
  resetScore,
}: ScoreResultsPHQ9Props): JSX.Element | null {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const { COLUMNS, ROWS } = usePHQ9SurveyModel();

  const handleClose = () => {
    setOpen(false);
    resetScore();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-phq9-results"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          {t('content.depression_survey.your_score_title')}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`5px solid ${COLOURS.Primary}`}
              borderRadius="50%"
              height="18em"
              width="18em"
              margin="1em"
            >
              <DialogContentText
                fontWeight="bold"
                variant="h4"
                textTransform="capitalize"
                component="span"
              >
                {t('content.depression_survey.your_score')}
              </DialogContentText>

              <DialogContentText
                fontWeight="bold"
                variant="h4"
                textTransform="capitalize"
                component="span"
              >
                {phq9Score}
              </DialogContentText>
            </Box>
          </Box>

          <ScoreGuideTable
            columns={COLUMNS}
            rows={ROWS}
            userScore={phq9Score}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} aria-label="close-dialog">
            {t('common.continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
