import { PropsWithRef, useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import MapItem from './MapItem';

const defaultCenter = {
  lat: 45.422666,
  lng: -75.699817,
};

const GOOGLE_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY ||
  (window as any)._env_.REACT_APP_GOOGLE_API_KEY;

export interface CareFinderMapProps {
  locations: any; //todo: set proper type
  mapIconClick: (v: string) => void;
}

export default function CareFinderMap({
  locations,
  mapIconClick,
}: PropsWithRef<CareFinderMapProps>): JSX.Element {
  const [center, setCenter] = useState(defaultCenter);

  useEffect(() => {
    let newCenter = defaultCenter;

    if (_.size(locations)) {
      const c = _.get(locations, '0.properties.coords.0');
      if (c) {
        newCenter = {
          lat: c.lat,
          lng: c.long,
        };
      }
    }
    setCenter(newCenter);
  }, [locations]);

  return (
    <Stack
      flexBasis="100%"
      borderRadius={2}
      height="85vh"
      width="100%"
      overflow="hidden"
      paddingTop={1}
      paddingLeft={1}
      boxShadow={`inset 0px 4px 13px rgb(0 0 0 / 50%)`}
    >
      <GoogleMapReact
        zoom={11}
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        yesIWantToUseGoogleMapApiInternals
        center={center}
      >
        {_.map(locations, (location, locationIndex) => {
          return _.map(location.properties?.coords, (coords, index) => (
            <MapItem
              lat={coords.lat}
              lng={coords.long}
              key={index}
              index={locationIndex}
              handleClick={mapIconClick}
            />
          ));
        })}
      </GoogleMapReact>
    </Stack>
  );
}
