import { Tabs, Tab } from '@mui/material';
import { AnalyticsService } from 'hive-analytics-react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as CareTeamLogo } from '../assets/Care Team.svg';
import { ReactComponent as DiaryLogo } from '../assets/Diary.svg';
import { ReactComponent as HistoryLogo } from '../assets/History.svg';
import { ReactComponent as HomeLogo } from '../assets/Home.svg';
import { ReactComponent as PublicationsLogo } from '../assets/Publications.svg';
import { ReactComponent as ServicesLogo } from '../assets/Services.svg';
import { ReactComponent as TrackingLogo } from '../assets/Tracking.svg';
import {
  PATH_CARE_FINDER,
  PATH_CARE_TEAM,
  PATH_HOME,
  PATH_HISTORY,
  PATH_JOURNAL,
  PATH_RESOURCES,
  PATH_TRACKING,
  STORAGE_CURRENT_TAB,
} from '../content/constants';

export default function TabBar(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Determine which tab based on the location
  const location = useLocation();
  const currentTab =
    location.pathname.includes('settings') ||
    location.pathname.includes('survey')
      ? false
      : location.pathname;

  const onTabChange = useCallback(
    (_event: any, value: string) => {
      AnalyticsService.logClick('TAB_CHANGE', { tab: value });
      navigate(value);
      localStorage.setItem(STORAGE_CURRENT_TAB, value);
    },
    [navigate]
  );

  return (
    <Tabs
      aria-label="icon tabs"
      onChange={onTabChange}
      orientation="vertical"
      value={currentTab}
      variant="scrollable"
    >
      <Tab
        icon={<HomeLogo />}
        aria-label={t('tab_bar.home') || undefined}
        label={t('tab_bar.home')}
        value={PATH_HOME}
        id="walkthrough-explore-home"
      />
      <Tab
        icon={<TrackingLogo />}
        aria-label={t('tab_bar.tracking') || undefined}
        label={t('tab_bar.tracking')}
        value={PATH_TRACKING}
        id="walkthrough-explore-tracking"
      />
      <Tab
        icon={<HistoryLogo />}
        aria-label={t('tab_bar.history') || undefined}
        label={t('tab_bar.history')}
        value={PATH_HISTORY}
        id="walkthrough-explore-history"
      />
      <Tab
        icon={<DiaryLogo />}
        aria-label={t('tab_bar.journal') || undefined}
        label={t('tab_bar.journal')}
        value={PATH_JOURNAL}
        id="walkthrough-explore-journal"
      />
      <Tab
        icon={<CareTeamLogo />}
        aria-label={t('tab_bar.care_team') || undefined}
        label={t('tab_bar.care_team')}
        value={PATH_CARE_TEAM}
        id="walkthrough-explore-care-team"
      />
      <Tab
        icon={<ServicesLogo />}
        aria-label={t('tab_bar.care_finder') || undefined}
        label={t('tab_bar.care_finder')}
        value={PATH_CARE_FINDER}
        id="walkthrough-explore-care-finder"
      />
      <Tab
        icon={<PublicationsLogo />}
        aria-label={t('tab_bar.resources') || undefined}
        label={t('tab_bar.resources')}
        value={PATH_RESOURCES}
        id="walkthrough-explore-resources"
      />
    </Tabs>
  );
}
