import { ComponentsOverrides } from '@mui/material';
import { COLOURS } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiDivider'] = {
  root: {
    '.patient-resources &': {
      borderBottomWidth: '2px',
      color: COLOURS.Divider,
    },
  },
};

export const MuiDivider = {
  styleOverrides,
};
