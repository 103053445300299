import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForgotPasswordUri } from 'ecarepd-shared-utilities';
import { useCallback } from 'react';
import { AnalyticsService } from 'hive-analytics-react';

export function ResetPassword(): JSX.Element {
  const { t } = useTranslation();
  const forgotPasswordUri = useForgotPasswordUri();

  const onResetPassword = useCallback(() => {
    if (!forgotPasswordUri) {
      return;
    }

    AnalyticsService.logClick('RESET-PASSWORD');
    window.location.assign(forgotPasswordUri);
  }, [forgotPasswordUri]);

  return (
    <Button variant="outlined" onClick={onResetPassword}>
      {t('settings.reset_password')}
    </Button>
  );
}
