import { ComponentsOverrides } from '@mui/material';
import { COLOURS, spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiCard'] = {
  root: {
    boxShadow: 'inset 0px 1px 3px 0px rgba(0,0,0,0.5)',
    padding: `${spacing * 2.5}px`,
    backgroundColor: COLOURS.LightBlueBackground + '40', // adding 25% opacity to color
    '.wearable-data-card &': {
      boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.5)',
      padding: 0,
      backgroundColor: 'white',
      '.MuiCardContent-root': {
        padding: '16px',
      },
    },
    '.wearable-data-card-steps &': {
      border: `2px solid ${COLOURS.Green}`,
      borderRadius: '4px',
    },
    '.wearable-data-card-sleepQuality &': {
      border: `2px solid ${COLOURS.DarkBlue}`,
      borderRadius: '4px',
    },
    '.update-care-priorities &': {
      borderRadius: spacing * 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${spacing}px ${spacing * 2}px ${spacing}px ${spacing * 2}px`,
      margin: spacing * 3,
      background: COLOURS.CardBackgroundColor,
      border: `1px solid ${COLOURS.CardBorderColor}`,
      boxShadow: 'none',
      '&.selected-card': {
        backgroundColor: COLOURS.DarkBlue,
        borderColor: COLOURS.White,
        '> .MuiTypography-root': {
          color: COLOURS.White,
        },
        '& .MuiSvgIcon-root': {
          transform: 'rotate(45deg)',
          color: COLOURS.White,
        },
      },
      '& .MuiSvgIcon-root': {
        color: COLOURS.DarkBlue,
      },
    },
    '.care-priority-card &': {
      flexDirection: 'column',
      backgroundColor: 'unset',
      color: COLOURS.Primary,
      width: '21%', // TODO: figure out why 20% changes the appearance drastically
      borderRadius: spacing * 2,
      justifyContent: 'space-between',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
      '.MuiTypography-root': {
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'noWrap',
      },
      '.MuiSvgIcon-root': {
        transform: 'rotate(45deg)',
        color: COLOURS.Primary,
      },
      '.MuiButton-root': {
        padding: 0,
        alignSelf: 'flex-start',
        textTransform: 'none',
        textDecoration: 'underline',
        color: COLOURS.Primary,
      },
    },
    '&.care-priorities-home': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '.MuiButton-root': {
        padding: 0,
        alignSelf: 'flex-start',
        textTransform: 'none',
        textDecoration: 'underline',
        color: COLOURS.Primary,
      },
      svg: {
        pointerEvents: 'all',
      },
    },
    '&.care-finder-service': {
      borderRadius: spacing * 2,
      padding: `${spacing}px ${spacing * 1}px`,
      margin: spacing,
      border: `1px solid ${COLOURS.Black}`,
      boxShadow: 'none',
      '.MuiTypography-root': {
        padding: 0,
      },
    },
    '&.care-tip-rating': {
      backgroundColor: COLOURS.LightBlueBackground,
      flexDirection: 'row',
      marginBottom: spacing * 2,
      boxShadow: 'none',
      display: 'flex',
      alignItems: 'center',
      svg: {
        pointerEvents: 'all',
      },
      '.unselected-button': {
        borderRadius: '25px',
        boxShadow: 'none',
        height: '32px',
        color: COLOURS.White,
        backgroundColor: COLOURS.Primary,
      },
      '.selected-button': {
        borderRadius: '25px',
        boxShadow: 'none',
        height: '32px',
        color: COLOURS.White,
        backgroundColor: COLOURS.DarkBlue,
      },
      '.selected-svg': {
        color: COLOURS.DarkBlue,
        padding: spacing,
      },
      '.unselected-svg': {
        padding: spacing,
        color: COLOURS.Primary,
      },
    },
    '&.care-tip-rating-wrapper': {
      width: '100%',
      boxShadow: 'none',
      flexGrow: 1,
      padding: 0,
      flexBasis: '50%',
      backgroundColor: 'unset',
      marginTop: spacing * 2,
      '> div': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
        borderRadius: spacing * 2,
        padding: spacing * 2,
        border: `1px solid ${COLOURS.CardBorderColor}`,
        overflowY: 'scroll',
        display: 'block',
        height: '37vh',
        boxSizing: 'border-box',
        flexDirection: 'column',
        '.MuiTypography-h6': {
          fontStyle: 'italic',
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: 2,
          color: COLOURS.DarkGrey,
        },
        '.care-tip-rating': {
          display: 'flex',
          flexDirection: 'column !important', // is overwritten otherwise
          alignItems: 'center',
          padding: spacing * 3,
          // background: COLOURS.LightBlueBackground,
          boxShadow: 'inset 0px 2px 2px 0px rgba(0,0,0,0.15)',
          borderRadius: spacing * 2,
          flex: 'none',
          order: 1,
          flexGrow: 0,
          backgroundColor: COLOURS.LightBlueBackground,
          margin: spacing * 2,
          overflow: 'initial',
        },
      },
    },
    '&.care-finder-result': {
      borderRadius: spacing * 2,
      padding: `${spacing}px ${spacing * 1}px`,
      margin: spacing,
      flexDirection: 'row',
      backgroundColor: COLOURS.GreyBackground,
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
      marginBottom: spacing * 4,
      '.MuiTypography-h6': {
        color: COLOURS.Primary,
        borderBottom: `1px solid ${COLOURS.Divider}`,
      },
      '> svg': {
        pointerEvents: 'all',
      },
    },
    '&.care-team-card': {
      // Bring back the default card boxShadow, and padding
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      padding: 0,
      borderRadius: spacing * 4,
    },
  },
};

export const MuiCard = {
  styleOverrides: styleOverrides,
};

const headerStyleOverrides: ComponentsOverrides['MuiCardHeader'] = {
  root: {
    '.care-team-card &': {
      paddingBottom: 0,
    },
  },
};

export const MuiCardHeader = {
  styleOverrides: headerStyleOverrides,
};

const contentStyleOverrides: ComponentsOverrides['MuiCardContent'] = {
  root: {
    '.care-team-card &': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
export const MuiCardContent = {
  styleOverrides: contentStyleOverrides,
};
