import {
  useEffect,
  useState,
  useCallback,
  PropsWithRef,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import {
  useCoachingTips,
  CoachingTip,
  WearableDaily,
  Patient,
  CommonDataContext,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';

const TODAYS_TIP_KEY = 'todaysTip';

export interface WelcomeProps {
  patient?: Patient;
}

export default function Welcome({
  patient,
}: PropsWithRef<WelcomeProps>): JSX.Element {
  const [firstName, setFirstName] = useState('');
  const { t } = useTranslation();
  const coachingTips = useCoachingTips();
  const [todaysTip, setTodaysTip] = useState<CoachingTip>();
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);

  useEffect(() => {
    if (patient?.properties) {
      setFirstName(patient.properties.firstname);
    }
  }, [patient]);

  const getNewTip = useCallback(() => {
    if (_.isEmpty(coachingTips)) {
      return;
    }
    const newTip = _.sample(coachingTips);
    const storedTip = {
      expiration: moment().endOf('day').toISOString(),
      value: newTip?.id,
    };
    localStorage.setItem(TODAYS_TIP_KEY, JSON.stringify(storedTip));
    setTodaysTip(newTip);
  }, [coachingTips]);

  useEffect(() => {
    try {
      const savedTip = JSON.parse(localStorage.getItem(TODAYS_TIP_KEY) || '{}');
      if (savedTip) {
        const expiration = moment(savedTip.expiration);
        if (expiration.isValid() && moment().isBefore(expiration)) {
          const tip = _.find(coachingTips, { id: savedTip.value });
          setTodaysTip(tip);
          return;
        }
      }
      // eslint-disable-next-line prettier/prettier
    } catch {}
    getNewTip();
  }, [getNewTip, coachingTips]);

  return (
    <Stack flexBasis="0" flexGrow="2" spacing={8}>
      <Stack>
        <Typography variant="h4">
          {t('home.welcome.header', { firstName })}
        </Typography>
        <Stack spacing={2}>
          <Typography>{t('home.welcome.subheader')}</Typography>
        </Stack>
      </Stack>
      {(featureFlags.FITBIT || featureFlags.GARMIN) && (
        <Stack
          direction="row"
          justifyContent="center"
          id="walkthrough-wearable"
        >
          <WearableDaily
            patientID={patient?.properties.patientID || ''}
            hideConnect={false}
          />
        </Stack>
      )}
      <Stack spacing={2}>
        <Typography variant="h6">{todaysTip?.properties?.title}</Typography>
        <Typography>{todaysTip?.properties?.text}</Typography>
      </Stack>
    </Stack>
  );
}
