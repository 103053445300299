import { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import CarePriorities from '../components/home/CarePriorities';
import DailyJournal from '../components/home/DailyJournal';
import Welcome from '../components/home/Welcome';
import Tutorial from './tutorial/Tutorial';
import WalkThrough from './tutorial/Walkthrough';
import { JournalDialog } from '../components/dialog';
import CarePrioritiesLanding from './carePriorities/CarePrioritiesLanding';
import { DataContext } from '../contexts/DataContext';
import CareTipRatings from '../components/home/CareTipRatings';
import {
  CommonDataContext,
  CommonDataContextProps,
} from 'ecarepd-shared-utilities';

export default function Home(): JSX.Element {
  const { patient } = useContext(DataContext);
  const [isTutorialDialogOpen, setIsTutorialDialogOpen] = useState(false);
  const [isWalkThroughDialogOpen, setIsWalkThroughDialogOpen] = useState(false);
  const [isJournalDialogOpen, setIsJournalDialogOpen] = useState(false);
  const [openPriorities, setOpenPriorities] = useState(false);
  const [initialSetup, setInitialSetup] = useState(false);
  const { featureFlags } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const hasWearable = featureFlags.FITBIT || featureFlags.GARMIN;
  const { patientPriorities: selectedPriorities } = useContext(DataContext);

  useEffect(() => {
    if (patient?.properties?.showTutorial) {
      setIsTutorialDialogOpen(true);
      setInitialSetup(true);
    }
  }, [patient]);

  const handleOpenPriorities = () => {
    return setOpenPriorities(true);
  };

  const handleClosePriorities = () => {
    setOpenPriorities(false);
  };

  return (
    <Stack flexGrow="1" direction="row" spacing={4}>
      <Tutorial
        onClose={() => {
          setIsTutorialDialogOpen(false);
          if (initialSetup && !openPriorities) {
            setIsWalkThroughDialogOpen(true);
          }
        }}
        open={isTutorialDialogOpen}
        openCarePriorities={handleOpenPriorities}
      />
      <CarePrioritiesLanding
        initialSetup={initialSetup}
        onClose={() => {
          handleClosePriorities();
          if (initialSetup && !hasWearable) {
            setIsTutorialDialogOpen(false);
            setIsWalkThroughDialogOpen(true);
          }
        }}
        open={openPriorities}
        oldPriorities={selectedPriorities}
      />
      <WalkThrough
        onClose={() => setIsWalkThroughDialogOpen(false)}
        open={isWalkThroughDialogOpen}
      />
      <JournalDialog
        onClose={() => setIsJournalDialogOpen(false)}
        open={isJournalDialogOpen}
      />
      <Welcome patient={patient} />
      <CarePriorities handleOpen={handleOpenPriorities} />
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={3}
        flexBasis={0}
        height="100%"
      >
        <Box id="walkthrough-journal">
          <DailyJournal onClickInfo={() => setIsJournalDialogOpen(true)} />
        </Box>
        <Box id="walkthrough-ratings">
          <CareTipRatings />
        </Box>
      </Box>
    </Stack>
  );
}
