import { Stack } from '@mui/material';
import { Journal, useFeatureFlags } from 'ecarepd-shared-utilities';
import { AudioJournalPlay } from './AudioJournalPlay';
import { AudioJournalVoiceScore } from './AudioJournalVoiceScore';

export interface AudioJournalButtonsProps {
  journal?: Journal;
}

function AudioJournalButtonsInternal({
  journal,
}: AudioJournalButtonsProps): JSX.Element {
  return (
    <Stack
      className="audio-journal-buttons"
      direction="row"
      spacing={1}
      justifyContent="center"
    >
      {journal?.properties.voiceScore && (
        <AudioJournalVoiceScore journal={journal} />
      )}

      {journal?.properties.audioBlobId && (
        <AudioJournalPlay journal={journal} />
      )}
    </Stack>
  );
}

export function AudioJournalButtons({
  journal,
}: AudioJournalButtonsProps): JSX.Element {
  const featureFlags = useFeatureFlags();

  if (!featureFlags.SONDE && !journal) {
    return <></>;
  }

  return <AudioJournalButtonsInternal journal={journal} />;
}
