import { ReactComponent as MapPin } from '../../assets/map_pin.svg';
import { Typography } from '@mui/material';
import { PropsWithRef } from 'react';

export interface MapItemProps {
  lat?: number;
  lng?: number;
  index: string;
  handleClick?: (v: string) => void;
}

export default function MapItem({
  index,
  handleClick = () => {},
}: PropsWithRef<MapItemProps>): JSX.Element {
  return (
    <div onClick={() => handleClick(index)}>
      <Typography className="map-item">{Number(index) + 1}</Typography>
      <MapPin color="primary" fontSize="large" className="map-pin" />
    </div>
  );
}
