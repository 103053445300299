import { Stack } from '@mui/material';
import { CareTeamMemberType } from 'ecarepd-shared-utilities';
import { CareTeamMembers } from './CareTeamMembers';

export default function CareTeam(): JSX.Element {
  return (
    <div>
      <Stack spacing={10}>
        <CareTeamMembers type={CareTeamMemberType.PHYSICIAN_AND_NURSE} />
        <CareTeamMembers type={CareTeamMemberType.ALLIED_CARE_PROFESSIONAL} />
      </Stack>
    </div>
  );
}
