import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, DialogActions, DialogContent } from '@mui/material';
import { AnalyticsService, LoggedDialog } from 'hive-analytics-react';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../DialogTitleWithClose';
export function StepDialog({ dialogName, dialogTitle, open, onClose, disableEscape, disableBackdropClick, steps, stepParameters, submitting, onContinue: onInternalContinue, }) {
    const { t } = useTranslation();
    const [stepIndex, setStepIndex] = useState(0);
    const step = useMemo(() => steps[stepIndex], [stepIndex, steps]);
    useEffect(() => {
        // Reset values when opening
        if (open) {
            setStepIndex(0);
        }
    }, [open]);
    const content = useMemo(() => step.component(stepParameters), [step, stepParameters]);
    const onNext = useCallback(() => {
        AnalyticsService.logClick(`${_.toUpper(dialogName)}-NEXT`);
        setStepIndex((index) => index + 1);
    }, [dialogName]);
    const onBack = useCallback(() => {
        AnalyticsService.logClick(`${_.toUpper(dialogName)}-BACK`);
        setStepIndex((index) => index - 1);
    }, [dialogName]);
    const isNextDisabled = useMemo(() => step.isNextDisabled(stepParameters), [step, stepParameters]);
    const nextLabel = useMemo(() => {
        if (step.nextLabel) {
            return step.nextLabel(t);
        }
        if (step.isLast) {
            return t('common.continue');
        }
        return t('common.next');
    }, [step, t]);
    const onContinue = useCallback(() => {
        AnalyticsService.logClick(`${_.toUpper(dialogName)}-CONTINUE`);
        onInternalContinue();
    }, [dialogName, onInternalContinue]);
    const onNextOrContinue = useCallback(() => {
        if (step.isLast) {
            onContinue();
        }
        else {
            onNext();
        }
    }, [step, onContinue, onNext]);
    const onEscapeOrBackdropClick = useCallback((_event, reason) => {
        if (submitting) {
            return false;
        }
        if (reason === 'escapeKeyDown' && !!disableEscape) {
            return false;
        }
        if (reason === 'backdropClick' && !!disableBackdropClick) {
            return false;
        }
        onClose();
    }, [submitting, onClose, disableEscape, disableBackdropClick]);
    return (_jsxs(LoggedDialog, Object.assign({ dialogName: dialogName, open: open, onClose: onEscapeOrBackdropClick, fullWidth: true, className: "step-dialog" }, { children: [_jsx(DialogTitleWithClose, Object.assign({ onClose: onClose, disabled: submitting }, { children: dialogTitle })), _jsx(DialogContent, Object.assign({ dividers: true }, { children: content })), _jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ disabled: submitting || step.isFirst, onClick: onBack }, { children: t('common.back') })), _jsx(Button, Object.assign({ disabled: submitting || isNextDisabled, onClick: onNextOrContinue }, { children: nextLabel }))] })] })));
}
