import moment from 'moment';
const REGEX_EMAIL = /^\S+@\S+$/;
function validateEmail(email, options) {
    if (!email) {
        if (!!(options === null || options === void 0 ? void 0 : options.ignoreEmpty)) {
            return true;
        }
    }
    const match = REGEX_EMAIL.exec(email || '');
    return !!match;
}
function validateDiagnosticsDate(diagnosticsDate, options) {
    // If the date is null, we return a result based on the validIfNull option
    if (!diagnosticsDate) {
        return !!(options === null || options === void 0 ? void 0 : options.validIfNull);
    }
    diagnosticsDate = moment(diagnosticsDate);
    if (!diagnosticsDate.isValid()) {
        return false;
    }
    if (options === null || options === void 0 ? void 0 : options.dateOfBirth) {
        const dob = moment(options.dateOfBirth);
        if (dob.isValid()) {
            if (diagnosticsDate.isBefore(dob)) {
                return false;
            }
        }
    }
    // All good!
    return true;
}
const momentFromYear = (year) => year
    ? moment()
        .year(parseInt(`${year}`))
        .startOf('year')
    : undefined;
function validateDiagnosticsYear(diagnosticsYear, options) {
    return validateDiagnosticsDate(momentFromYear(diagnosticsYear), {
        dateOfBirth: momentFromYear(options === null || options === void 0 ? void 0 : options.yearOfBirth),
        validIfNull: !!(options === null || options === void 0 ? void 0 : options.validIfNull),
    });
}
export const ValidationService = {
    validateEmail,
    validateDiagnosticsDate,
    validateDiagnosticsYear,
};
