import { Card, Typography, Button } from '@mui/material';
import { PropsWithRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarePrioritiesTooltip from '../carePriorities/CarePrioritiesTooltip';
import { AddCircleOutline } from '@mui/icons-material';
import { SymptomPriority } from 'ecarepd-shared-utilities';
import { AnalyticsService } from 'hive-analytics-react';

export interface CarePriorityCardProps {
  handleClick: () => void;
  priority: SymptomPriority;
}

export default function CarePriorityCard({
  priority,
  handleClick,
}: PropsWithRef<CarePriorityCardProps>): JSX.Element {
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipHeader, setTooltipHeader] = useState('');

  const tooltipClick = (text: string, header: string) => {
    AnalyticsService.logClick('CARE-PRIORITY-CARD-TOOLTIP', {
      priorityId: priority.id,
      priorityName: priority.properties.name,
    });
    setTooltipHeader(header);
    setTooltipText(text);
    setTooltipOpen(true);
  };

  return (
    <>
      <Card key={priority.id}>
        <Typography variant="h6" display="flex">
          {priority.properties.name}
          <AddCircleOutline onClick={handleClick} />
        </Typography>
        <Button
          variant="text"
          onClick={() =>
            tooltipClick(
              priority.properties.information,
              priority.properties.name
            )
          }
        >
          {t('home.care_priorities.more_information')}
        </Button>
      </Card>
      <CarePrioritiesTooltip
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        text={tooltipText}
        priority={tooltipHeader}
      />
    </>
  );
}
