import { useInstancesByQuery } from 'hive-react-utils';
import { useMyPatient } from './useMyPatient';
import { useMemo } from 'react';
import _ from 'lodash';
import { useStartOfDay } from './useStartOfDay';
import moment from 'moment';
export function useMyDailyJournal() {
    const patient = useMyPatient();
    const startOfDay = useStartOfDay();
    const journals = useInstancesByQuery('ecarepd', 'journalsByPatientIDFromDate', 'journal', {}, (patient === null || patient === void 0 ? void 0 : patient.properties.patientID) || '', moment(startOfDay).toISOString(), moment(startOfDay).endOf('day').toISOString());
    // Make sure we ignore pending status
    return useMemo(() => {
        if (!journals ||
            _.get(journals, 'status') !== "done" /* ContentObserver.status.DONE */) {
            return undefined;
        }
        return _.first(journals);
    }, [journals]);
}
