import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography, Card } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { ReactComponent as Note } from '../../assets/note.svg';
import CarePrioritiesTooltip from '../../content/carePriorities/CarePrioritiesTooltip';
import { useState, PropsWithRef, useCallback, useEffect } from 'react';
import {
  COLOURS,
  ScaleIcon,
  TextDialogProps,
  ResponseCareTip,
  TextDialog,
  ResponseCareTipProps,
  useResponseCareTipsByDate,
  SymptomsPriorityWithLatestResponse,
} from 'ecarepd-shared-utilities';
import { SymptomDetailsDialog } from '../../content/history/SymptomDetailsDialog';
import { AnalyticsService } from 'hive-analytics-react';
import { ReactComponent as Tip } from '../../assets/tip.svg';

export interface PriorityProps {
  symptomPriority: SymptomsPriorityWithLatestResponse;
}

export function Priority({
  symptomPriority,
}: PropsWithRef<PriorityProps>): JSX.Element {
  const { t } = useTranslation();

  const [responseCareTip, setResponseCareTip] = useState<
    ResponseCareTipProps | undefined
  >();
  const [showDetails, setShowDetails] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');
  const [tooltipHeader, setTooltipHeader] = useState('');

  const latestResponse = symptomPriority.properties.latestResponse?.properties;

  const lastReportedDate = latestResponse
    ? moment(latestResponse.date2).format('MMMM Do, YYYY')
    : `${t('home.care_priorities.no_report')}`;

  const latestResponseDate = latestResponse ? latestResponse.date2 : moment();

  const responseCareTips = useResponseCareTipsByDate(
    moment(latestResponseDate).startOf('day').toISOString(),
    moment(latestResponseDate).endOf('day').toISOString()
  );

  const [tipDialogProps, setTipDialogProps] = useState<
    TextDialogProps | undefined
  >();

  const showTipDialog = useCallback(
    (tip: string) => {
      setTipDialogProps({
        dialogName: 'priority-tip-dialog',
        open: true,
        onClose: () => setTipDialogProps(undefined),
        title: t('content.tracking.priorities.care_tip_title', {
          symptom: symptomPriority.properties.name,
        }),
        text: tip,
      });
    },
    [symptomPriority.properties.name, t, setTipDialogProps]
  );

  useEffect(() => {
    if (!latestResponse) {
      return;
    }

    const existing = _.find(
      responseCareTips,
      (tip: ResponseCareTip) => tip.properties.priorityID === symptomPriority.id
    );

    if (existing) {
      setResponseCareTip(existing.properties);
    }
  }, [symptomPriority, responseCareTips, latestResponse]);

  const tooltipClick = useCallback(
    (text: string, header: string) => {
      AnalyticsService.logClick('CARE-PRIORITIES-TOOLTIP', {
        priorityId: symptomPriority.id,
        priorityName: symptomPriority.properties.name,
      });

      setTooltipHeader(header);
      setTooltipText(text);
      setTooltipOpen(true);
    },
    [symptomPriority.id, symptomPriority.properties.name]
  );

  const onMoreAbout = useCallback(() => {
    setTipDialogProps(undefined);
    setShowDetails(true);
  }, []);

  return (
    <Card variant="outlined" className="care-priorities-home">
      <Stack>
        <Typography
          variant="h5"
          paddingBottom={2}
          display="flex"
          align-items="center"
          gap={1}
        >
          <b>{symptomPriority.properties?.name}</b>
          {latestResponse?.note && (
            <Note
              onClick={() =>
                tooltipClick(
                  latestResponse!.note!,
                  t('content.history.care_priority_history.note_dialog.title', {
                    symptomName: symptomPriority.properties.name,
                  })
                )
              }
              color={COLOURS.Primary}
            />
          )}
          {responseCareTip && (
            <Tip
              onClick={() => {
                const tip = responseCareTip.tip || '';
                AnalyticsService.logClick('TRACKING-TIP-CLICK', { tip });
                showTipDialog(tip);
              }}
              height={26}
              width={26}
            />
          )}
        </Typography>
        <Button
          onClick={() =>
            tooltipClick(
              symptomPriority.properties.information,
              symptomPriority.properties.name
            )
          }
          variant="text"
        >
          {t('home.care_priorities.more_information')}
        </Button>
        <Typography variant="body2">
          {t('home.care_priorities.last_report')}
          <b>{lastReportedDate}</b>
        </Typography>
      </Stack>
      {Number(latestResponse?.response) > -1 && (
        <Stack justifyContent="center">
          <ScaleIcon response={latestResponse!.response} highlighted={false} />
        </Stack>
      )}
      <CarePrioritiesTooltip
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        text={tooltipText}
        priority={tooltipHeader}
      />
      <TextDialog
        open={!!tipDialogProps}
        actions={[
          <Button key="more-about" onClick={onMoreAbout}>
            {t('content.tracking.priorities.more_about', {
              symptom: symptomPriority.properties.name,
            })}
          </Button>,
          <Button key="continue" onClick={() => setTipDialogProps(undefined)}>
            {t('common.continue')}
          </Button>,
        ]}
        {...tipDialogProps}
      />
      <SymptomDetailsDialog
        open={showDetails}
        symptomPriority={symptomPriority}
        onClose={() => setShowDetails(false)}
      />
    </Card>
  );
}
