import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { SymptomPriority } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import { ChangeEvent, PropsWithRef, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../contexts/DataContext';

export interface CarePrioritySelectionProps {
  priority?: string;
  setPriority: (priorityID: string) => void;
}

export function CarePrioritySelection({
  priority,
  setPriority,
}: PropsWithRef<CarePrioritySelectionProps>): JSX.Element {
  const { t } = useTranslation();
  const { patientSymptomPriorities } = useContext(DataContext);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLElement>, newValue: string) => {
      event.preventDefault();
      setPriority(newValue || '');
    },
    [setPriority]
  );

  return (
    <Stack spacing={4} className="call-request-content">
      <Typography variant="body1">
        {t('call_request.priority.header')}
      </Typography>

      <Typography variant="body2">
        {t('call_request.priority.please_select')}
      </Typography>

      <Stack alignItems="center">
        <RadioGroup value={priority || ''} onChange={onChange}>
          {_.map(patientSymptomPriorities, (p: SymptomPriority) => (
            <FormControlLabel
              key={p.id}
              value={p.id}
              control={<Radio />}
              label={p.properties.name}
            />
          ))}
        </RadioGroup>
      </Stack>
    </Stack>
  );
}
