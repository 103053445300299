import { ComponentsOverrides } from '@mui/material';
import { spacing } from 'ecarepd-shared-utilities';

const styleOverrides: ComponentsOverrides['MuiTabs'] = {
  root: {
    '@media (max-height: 900px)': {
      '& .MuiTab-labelIcon': {
        padding: `${spacing * 1.5}px ${spacing * 2}px`,
        margin: `0px ${spacing * 4}px`,
        marginLeft: `${spacing * 5}px`,
        '> svg': {
          maxWidth: `${spacing * 12}px`,
        },
      },
    },
  },
};

export const MuiTabs = {
  styleOverrides,
};
