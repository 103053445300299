import {
  PatientService,
  useApiCallWithMessageBar,
  StepDialog,
  StepDialogStep,
  useResponseCareTipsByDate,
  ResponseCareTip,
  ResponseCareTipProps,
} from 'ecarepd-shared-utilities';
import moment from 'moment';
import {
  PropsWithRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DataContext } from '../../contexts/DataContext';
import { CarePrioritySelection } from './CarePrioritySelection';
import { CareTipRating } from './CareTipRating';
import { Comment as CommentTab } from './Comment';
import { Done as DoneTab } from './Done';
import { CallRequestDialogNoData } from './CallRequestNoData';
import { TFunction } from 'i18next';

const MINIMUM_RESPONSE_AGE_IN_DAYS = 7;

interface CallRequestStepParameters {
  priority?: string;
  setPriority: (newPriority: string) => void;

  comment?: string;
  setComment: (newComment: string) => void;
  responseCareTips: ResponseCareTip[];
  noResponses: boolean;
}

const STEPS: StepDialogStep<CallRequestStepParameters>[] = [
  {
    stepId: 'Selection',
    isFirst: true,
    isLast: false,
    nextLabel: (t: TFunction) => t('common.next'),
    isNextDisabled: ({ priority, noResponses }: CallRequestStepParameters) =>
      !priority || noResponses,
    component: ({ priority, setPriority }: CallRequestStepParameters) => (
      <CarePrioritySelection priority={priority} setPriority={setPriority} />
    ),
  },

  {
    stepId: 'Rating',
    isFirst: false,
    isLast: false,
    nextLabel: (t: TFunction) => t('common.next'),
    isNextDisabled: () => false,
    component: ({ priority, responseCareTips }: CallRequestStepParameters) => (
      <CareTipRating
        priority={priority || ''}
        responseCareTips={responseCareTips}
      />
    ),
  },

  {
    stepId: 'Comment',
    isFirst: false,
    isLast: false,
    nextLabel: (t: TFunction) => t('common.next'),
    isNextDisabled: () => false,
    component: ({ comment, setComment }: CallRequestStepParameters) => (
      <CommentTab comment={comment} setComment={setComment} />
    ),
  },

  {
    stepId: 'Done',
    isFirst: false,
    isLast: true,
    nextLabel: (t: TFunction) => t('common.continue'),
    isNextDisabled: () => false,
    component: ({ priority }: CallRequestStepParameters) => (
      <DoneTab priority={priority || ''} />
    ),
  },
];

export interface CallRequestDialogProps {
  open: boolean;
  onClose: () => void;
}

export function CallRequestDialog({
  open,
  onClose,
}: PropsWithRef<CallRequestDialogProps>): JSX.Element {
  const { t } = useTranslation();

  const { patient, patientPriorities, patientSymptomPriorities } =
    useContext(DataContext);

  const [priority, setPriority] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const [noResponses, setNoResponses] = useState<boolean>(false);

  const symptom = _.filter(
    patientPriorities?.properties.priorities,
    (p: any) => p.id === priority
  );

  const latestResponse = symptom[0]?.properties?.latestResponse?.created;

  const latestResponseDate = latestResponse ? latestResponse : moment();

  let responseCareTips = useResponseCareTipsByDate(
    moment(latestResponseDate).startOf('day').subtract(1, 'year').toISOString(),
    moment(latestResponseDate).endOf('day').toISOString()
  );

  responseCareTips = useMemo(
    () =>
      _.filter(
        responseCareTips,
        (ct: { properties: ResponseCareTipProps }) =>
          ct.properties.priorityID === priority
      ),
    [priority, responseCareTips]
  );

  // Permit requesting a call only if the older entry satisfies the minimum age
  const onPriorityChanged = useCallback(
    async (newPriority: string) => {
      setPriority(newPriority);
      if (!patient?.properties.patientID || !newPriority) {
        return;
      }

      const oldestResponse = await PatientService.getOldestPatientResponse(
        patient?.properties.patientID,
        newPriority
      );

      const diff = moment().diff(oldestResponse?.created, 'days');
      setNoResponses(diff < MINIMUM_RESPONSE_AGE_IN_DAYS);
    },
    [patient?.properties.patientID]
  );

  const stepParameters = useMemo(
    () => ({
      priority,
      setPriority: onPriorityChanged,
      comment,
      setComment,
      responseCareTips,
      noResponses,
    }),
    [priority, comment, responseCareTips, onPriorityChanged, noResponses]
  );

  const steps = useMemo(() => {
    let stepsToReturn = [...STEPS];
    if (!responseCareTips?.length) {
      stepsToReturn = _.remove(stepsToReturn, (s) => {
        return s.stepId !== 'Rating';
      });
    }
    return stepsToReturn;
  }, [responseCareTips]);

  useEffect(() => {
    // Reset values when opening
    if (open) {
      setNoResponses(false);
      setPriority('');
      setComment('');
    }
  }, [open]);

  const { progress: submitting, callback: onContinue } =
    useApiCallWithMessageBar({
      canExecute: () => !!patient,
      execute: () =>
        PatientService.createCallRequest(
          patient!,
          priority || '',
          moment().toISOString(),
          comment
        ).then(onClose),
    });

  if (
    _.size(patientSymptomPriorities) < 3 ||
    (!_.isEmpty(priority) && noResponses)
  ) {
    return <CallRequestDialogNoData onClose={onClose} open={open} />;
  }

  return (
    <StepDialog
      open={open}
      onClose={onClose}
      disableEscape={true}
      disableBackdropClick={true}
      dialogTitle={t('call_request.title')}
      steps={steps}
      stepParameters={stepParameters}
      submitting={submitting}
      onContinue={onContinue}
    />
  );
}
