import {
  Button,
  Container,
  Link,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  PatientPriorityResponseProperties,
  ScaleIcon,
  SymptomPriority,
  TextDialog,
  TextDialogProps,
  ScaleValueResponses,
  ResponseCareTip,
  ResponseCareTipProps,
  useStartOfDay,
} from 'ecarepd-shared-utilities';
import _ from 'lodash';
import {
  MouseEvent,
  PropsWithRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NoteDialog } from '../../components/dialog';
import { SymptomDetailsDialog } from '../history/SymptomDetailsDialog';
import { ReactComponent as Tip } from '../../assets/tip.svg';
import { DataContext } from '../../contexts/DataContext';
import { AnalyticsService } from 'hive-analytics-react';

const SCALE_DISPLAY_ORDER = [
  ScaleValueResponses.VeryHappy,
  ScaleValueResponses.Happy,
  ScaleValueResponses.Meh,
  ScaleValueResponses.Sad,
  ScaleValueResponses.VerySad,
];

interface ScaleIconWithTipProps {
  value: ScaleValueResponses;
  selectedValue?: ScaleValueResponses;
  showTipDialog: (tip: string) => void;
  responseCareTip?: ResponseCareTipProps;
}

function ScaleIconWithTip({
  value,
  selectedValue,
  showTipDialog,
  responseCareTip,
}: PropsWithRef<ScaleIconWithTipProps>): JSX.Element {
  const handleTipClick = useCallback(
    (event: MouseEvent<Element>) => {
      event.stopPropagation();
      const tip = responseCareTip?.tip || '';
      AnalyticsService.logClick('TRACKING-TIP-CLICK', { tip });
      showTipDialog(tip);
    },
    [responseCareTip?.tip, showTipDialog]
  );

  return (
    <Container>
      <ScaleIcon highlighted={selectedValue === value} response={value} />

      {responseCareTip?.response === value && (
        <div onClick={handleTipClick}>
          <Tip />
        </div>
      )}
    </Container>
  );
}

export interface PriorityProps {
  priority: SymptomPriority;
  response: PatientPriorityResponseProperties;
  changeResponse: (
    response?: number,
    note?: string,
    responseCareTipProps?: ResponseCareTipProps
  ) => void;
}

export function Priority({
  priority,
  response,
  changeResponse,
}: PropsWithRef<PriorityProps>): JSX.Element {
  const { t } = useTranslation();

  const { responseCareTips } = useContext(DataContext);

  const [responseCareTip, setResponseCareTip] = useState<
    ResponseCareTipProps | undefined
  >();

  const startOfDay = useStartOfDay();
  const defaultCareTip = useMemo(
    () => ({
      date: startOfDay.toISOString(),
      priorityID: priority.id,
      response: -1,
      responseID: '',
      tip: '',
    }),
    [priority.id, startOfDay]
  );

  useEffect(() => {
    const existing = _.find(
      responseCareTips,
      (tip: ResponseCareTip) => tip.properties.priorityID === priority.id
    );

    if (existing) {
      setResponseCareTip(existing.properties);
    }
  }, [responseCareTips, priority]);

  const [tipDialogProps, setTipDialogProps] = useState<
    TextDialogProps | undefined
  >();

  const showTipDialog = useCallback(
    (tip: string) => {
      setTipDialogProps({
        dialogName: 'priority-tip-dialog',
        open: true,
        onClose: () => setTipDialogProps(undefined),
        title: t('content.tracking.priorities.care_tip_title', {
          symptom: priority.properties.name,
        }),
        text: tip,
      });
    },
    [priority.properties.name, t]
  );

  const selectedValue = useMemo<ScaleValueResponses | undefined>(
    () => response?.response,
    [response]
  );

  const onValueChanged = useCallback(
    (_event: MouseEvent<HTMLElement>, newValue: number) => {
      let careTip = responseCareTip;

      // Check if we can show the tip pop-up
      if (
        // We have a actual selection and not a deselection
        newValue != null &&
        // We have Sad or verySade
        Number(newValue) < ScaleValueResponses.Meh &&
        // No tips were previously displayed
        responseCareTip === undefined
      ) {
        const tip =
          careTip?.tip || _.sample(priority.properties.careTips) || '';

        careTip = {
          ...defaultCareTip,
          ...careTip,
          response: Number(newValue) as any,
          tip,
        };
        setResponseCareTip(careTip);

        showTipDialog(tip);
      }

      // nil means unselected
      changeResponse(newValue == null ? -1 : newValue, undefined, careTip);
    },
    [
      responseCareTip,
      changeResponse,
      priority.properties.careTips,
      defaultCareTip,
      showTipDialog,
    ]
  );

  const note = useMemo(() => response?.note || '', [response]);
  const changeNote = useCallback(
    (newNote: string) => {
      changeResponse(undefined, newNote);
    },
    [changeResponse]
  );

  const [noteDialogOpen, setNoteDialogOpen] = useState(false);

  const [infoAnchor, setInfoAnchor] = useState<HTMLButtonElement | null>(null);

  const onMoreInfo = (event: any) => {
    setInfoAnchor(event.target);
  };

  const onCloseInfo = () => {
    setInfoAnchor(null);
  };

  const [showDetails, setShowDetails] = useState(false);

  const onMoreAbout = useCallback(() => {
    setTipDialogProps(undefined);
    setShowDetails(true);
  }, []);

  return (
    <>
      <NoteDialog
        reason="add"
        symptomPriority={priority}
        open={noteDialogOpen}
        onClose={() => setNoteDialogOpen(false)}
        note={note}
        setNote={changeNote}
      />

      <TextDialog
        open={!!tipDialogProps}
        actions={[
          <Button key="more–about" onClick={onMoreAbout}>
            {t('content.tracking.priorities.more_about', {
              symptom: priority.properties.name,
            })}
          </Button>,
          <Button key="continue" onClick={() => setTipDialogProps(undefined)}>
            {t('common.continue')}
          </Button>,
        ]}
        {...tipDialogProps}
      />

      <SymptomDetailsDialog
        open={showDetails}
        symptomPriority={priority}
        onClose={() => setShowDetails(false)}
      />

      <Popover
        id={priority?.id}
        open={!!infoAnchor}
        anchorEl={infoAnchor}
        onClose={onCloseInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography variant="body1">
          {priority?.properties?.information}
        </Typography>
      </Popover>

      <Stack
        direction="row"
        spacing={0}
        alignItems="center"
        padding={4}
        // See index.css about this
        className="patient-priority-background"
      >
        {/* Using a min-width of 0 ensure that the toggle button group does not misalign in small screens */}
        <Stack flexGrow="1" minWidth="0">
          <Typography variant="h5">{priority.properties.name}</Typography>
          <Link variant="body2" onClick={onMoreInfo}>
            {t('content.tracking.priorities.more_info')}
          </Link>
        </Stack>

        <ToggleButtonGroup
          exclusive
          value={selectedValue}
          onChange={onValueChanged}
        >
          {_.map(SCALE_DISPLAY_ORDER, (value: ScaleValueResponses) => (
            <Tooltip
              key={value}
              title={t(`content.tracking.tooltips.${value}`)}
            >
              <ToggleButton value={value}>
                <ScaleIconWithTip
                  value={value}
                  selectedValue={selectedValue}
                  showTipDialog={showTipDialog}
                  responseCareTip={responseCareTip}
                />
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>

        <Stack>
          <Button onClick={() => setNoteDialogOpen(true)}>
            {t('content.tracking.priorities.add_note')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
