import { SvgImage } from './SvgImage';
import VerySadIcon from './icons/0 Very Sad_default.svg';
import SadIcon from './icons/1 Sad_default.svg';
import MehIcon from './icons/2 Meh_default.svg';
import HappyIcon from './icons/3 Happy_default.svg';
import VeryHappyIcon from './icons/4 Very Happy_default.svg';
export function ScaleSvgImage({ index, x, y, width, height, transform, }) {
    let href;
    switch (index) {
        case 0:
        case '0':
            href = VerySadIcon;
            break;
        case 1:
        case '1':
            href = SadIcon;
            break;
        case 2:
        case '2':
            href = MehIcon;
            break;
        case 3:
        case '3':
            href = HappyIcon;
            break;
        case 4:
        case '4':
            href = VeryHappyIcon;
            break;
    }
    width = width || 32;
    height = height || 32;
    transform = transform || `translate(-${width}, -${height / 2})`;
    return SvgImage({ href, x, y, width, height, transform });
}
