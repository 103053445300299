import { Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, PropsWithRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export interface CommentProps {
  comment?: string;
  setComment: (comment: string) => void;
}

export function Comment({
  comment,
  setComment,
}: PropsWithRef<CommentProps>): JSX.Element {
  const { t } = useTranslation();

  const onChange = useCallback(
    (event: ChangeEvent<any>) => setComment(event.target.value || ''),
    [setComment]
  );
  return (
    <Stack spacing={4} className="call-request-content">
      <Typography variant="body1">
        {t('call_request.comment.header')}
      </Typography>

      <TextField
        fullWidth
        margin="normal"
        multiline
        rows={5}
        value={comment || ''}
        onChange={onChange}
      />
    </Stack>
  );
}
