import { createContext, useContext, useMemo } from 'react';
import {
  CommonDataContext,
  CommonDataContextProps,
  Patient,
  PatientPriority,
  ResourcesByType,
  ResponseCareTip,
  SymptomPriority,
  useMyPatient,
  useMyPatientPriorities,
  useResourcesByType,
  useResponseCareTipsByDate,
  useStartOfDay,
} from 'ecarepd-shared-utilities';

import _ from 'lodash';
import _fp from 'lodash/fp';
import moment from 'moment';

export interface DataContextProps {
  patient?: Patient;
  patientPriorities?: PatientPriority;
  patientSymptomPriorities: SymptomPriority[];
  responseCareTips: ResponseCareTip[];
  resourcesByType: ResourcesByType;
}

export const DataContext = createContext<DataContextProps>({
  patientSymptomPriorities: [],
  responseCareTips: [],
  resourcesByType: {},
});

export function DataContextProvider({
  children,
}: {
  children: null | JSX.Element;
}) {
  // IMPORTANT: just keep data that does NOT change often and that can be used often.

  const resourcesByType = useResourcesByType();

  const patient = useMyPatient();
  const patientPriorities = useMyPatientPriorities(patient);

  const { symptomPrioritiesById } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const patientSymptomPriorities = useMemo(() => {
    const priorityIDs = patientPriorities?.properties.priorityIDs || [];
    return _fp.flow([
      _fp.map((id: string) => _.get(symptomPrioritiesById, id)),
      _fp.compact,
    ])(priorityIDs);
  }, [patientPriorities, symptomPrioritiesById]);

  const today = useStartOfDay();
  const responseCareTips = useResponseCareTipsByDate(
    today.toISOString(),
    moment(today).endOf('day').toISOString()
  );

  const value = useMemo<DataContextProps>(
    () => ({
      patient,
      patientPriorities,
      patientSymptomPriorities,
      responseCareTips: responseCareTips || [],
      resourcesByType,
    }),
    [
      patient,
      patientPriorities,
      patientSymptomPriorities,
      resourcesByType,
      responseCareTips,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export function useDataContext() {
  return useContext(DataContext);
}
