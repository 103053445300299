import { Stack } from '@mui/material';
import { Fragment, PropsWithRef, useContext, useMemo } from 'react';
import { DataContext } from '../../contexts/DataContext';
import {
  CommonDataContext,
  CommonDataContextProps,
  ResponseCareTipProps,
  SymptomPriority,
} from 'ecarepd-shared-utilities';
import { Priority } from './Priority';

import _ from 'lodash';
import _fp from 'lodash/fp';
import { ResponseMap } from './Tracking';

export interface PrioritiesProps {
  responses: ResponseMap;
  changeResponse: (
    id: string,
    response?: number,
    note?: string,
    responseCareTip?: ResponseCareTipProps
  ) => void;
}
export function Priorities({
  responses,
  changeResponse,
}: PropsWithRef<PrioritiesProps>): JSX.Element {
  const { symptomPrioritiesById } =
    useContext<CommonDataContextProps>(CommonDataContext);
  const { patientPriorities } = useContext(DataContext);

  const priorities = useMemo(
    () =>
      _fp.flow([
        _fp.get('properties.priorityIDs'),
        _fp.map((id: string) => symptomPrioritiesById[id]),
        _fp.compact,
      ])(patientPriorities),
    [symptomPrioritiesById, patientPriorities]
  );

  return (
    <Stack
      direction="column"
      flexGrow="1"
      spacing={2}
      className="patient-priority-survey"
    >
      {_.map(priorities, (p: SymptomPriority) => (
        <Fragment key={p.id}>
          <Priority
            priority={p}
            response={responses[p.id]}
            changeResponse={(
              response?: number,
              note?: string,
              responseCareTip?: ResponseCareTipProps
            ) => changeResponse(p.id, response, note, responseCareTip)}
          />
        </Fragment>
      ))}
    </Stack>
  );
}
