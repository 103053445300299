import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const SURVEY_VERSION = 1.2;

// must ensure this map is in sync with the columns of the 'How Often' question element from Survey_JSON above
const HOW_OFTEN_VALUE_TO_SCORE = [
  {
    value: 'Not At All',
    score: 0,
  },
  {
    value: 'Several Days',
    score: 1,
  },
  {
    value: 'More Than Half',
    score: 2,
  },
  {
    value: 'Almost Every Day',
    score: 3,
  },
];

export const getTotalPHQScore = (data: any) => {
  // ensure that this property matches the question element name, i.e name: 'How Often' from Survey_JSON above
  if (!data['How Often']) {
    console.error('Missing data for "How Often" question element');
    return 0;
  }
  const valuesFromHowOften = Object.values(data['How Often']) as string[];
  let maxScore = 0;

  valuesFromHowOften.forEach((val: string) => {
    const columnVal = HOW_OFTEN_VALUE_TO_SCORE.find(
      (item) => item.value === val
    );
    maxScore += columnVal?.score ? columnVal.score : 0;
  });

  return maxScore;
};

export function usePHQ9SurveyModel() {
  const { t } = useTranslation();

  // generated from outside library
  const SURVEY_JSON = useMemo(() => {
    return {
      title: t('content.depression_survey.title'),
      logoPosition: 'right',
      pages: [
        {
          name: 'page1',
          elements: [
            {
              type: 'text',
              name: 'Name',
              title: t('content.depression_survey.name'),
              hideNumber: true,
              isRequired: true,
            },
            {
              type: 'text',
              name: 'Date',
              startWithNewLine: false,
              title: t('content.depression_survey.date'),
              hideNumber: true,
              isRequired: true,
              inputType: 'date',
            },
            {
              type: 'matrix',
              name: 'How Often',
              title: t('content.depression_survey.how_often'),
              hideNumber: true,
              isRequired: true,
              alternateRows: true,
              columns: [
                {
                  value: 'Not At All',
                  text: t('content.depression_survey.column1'),
                },
                {
                  value: 'Several Days',
                  text: t('content.depression_survey.column2'),
                },
                {
                  value: 'More Than Half',
                  text: t('content.depression_survey.column3'),
                },
                {
                  value: 'Almost Every Day',
                  text: t('content.depression_survey.column4'),
                },
              ],
              rows: [
                {
                  value: 'Little Interest',
                  text: t('content.depression_survey.row1'),
                },
                {
                  value: 'Depressed',
                  text: t('content.depression_survey.row2'),
                },
                {
                  value: 'Sleeping Problems',
                  text: t('content.depression_survey.row3'),
                },
                {
                  value: 'Little Energy',
                  text: t('content.depression_survey.row4'),
                },
                {
                  value: 'Poor Appetite',
                  text: t('content.depression_survey.row5'),
                },
                {
                  value: 'Low Self Esteem',
                  text: t('content.depression_survey.row6'),
                },
                {
                  value: 'Concentration',
                  text: t('content.depression_survey.row7'),
                },
                {
                  value: 'Slow',
                  text: t('content.depression_survey.row8'),
                },
                {
                  value: 'Suicidal Thoughts',
                  text: t('content.depression_survey.row9'),
                },
              ],
              isAllRowRequired: true,
            },
            {
              type: 'radiogroup',
              name: 'Difficulty',
              title: t('content.depression_survey.difficulty'),
              hideNumber: true,
              isRequired: true,
              choices: [
                {
                  value: 'Not difficult',
                  text: t('content.depression_survey.item1'),
                },
                {
                  value: 'Somewhat difficult',
                  text: t('content.depression_survey.item2'),
                },
                {
                  value: 'Very difficult',
                  text: t('content.depression_survey.item3'),
                },
                {
                  value: 'Extremely difficult',
                  text: t('content.depression_survey.item4'),
                },
              ],
              colCount: 4,
            },
          ],
        },
      ],
    };
  }, [t]);

  const COLUMNS = useMemo(
    () => [
      {
        id: 'score',
        label: t('content.depression_survey.table_column0'),
        align: 'center',
      },
      {
        id: 'severity',
        label: t('content.depression_survey.table_column1'),
        minWidth: 100,
        align: 'center',
      },
      {
        id: 'action',
        label: t('content.depression_survey.table_column2'),
        minWidth: 170,
        align: 'left',
      },
    ],
    [t]
  );

  const ROWS = useMemo(
    () => [
      {
        score: '0 - 4',
        severity: t('content.depression_survey.table_rows.severity0'),
        action: t('content.depression_survey.table_rows.action0'),
      },
      {
        score: '5 - 9',
        severity: t('content.depression_survey.table_rows.severity1'),
        action: t('content.depression_survey.table_rows.action1'),
      },
      {
        score: '10 - 14',
        severity: t('content.depression_survey.table_rows.severity2'),
        action: t('content.depression_survey.table_rows.action2'),
      },
      {
        score: '15 - 19',
        severity: t('content.depression_survey.table_rows.severity3'),
        action: t('content.depression_survey.table_rows.action3'),
      },
      {
        score: '20 - 27',
        severity: t('content.depression_survey.table_rows.severity4'),
        action: t('content.depression_survey.table_rows.action4'),
      },
    ],
    [t]
  );
  return { SURVEY_JSON, COLUMNS, ROWS };
}
