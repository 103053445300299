import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { DialogTitleWithClose } from 'ecarepd-shared-utilities';
import { LoggedDialog } from 'hive-analytics-react';
import { PropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';

export interface JournalProps {
  open: boolean;
  onClose: () => void;
}
export function JournalDialog({
  open,
  onClose,
}: PropsWithRef<JournalProps>): JSX.Element {
  const { t } = useTranslation();

  return (
    <LoggedDialog dialogName="JournalDialog" open={open} onClose={onClose}>
      <DialogTitleWithClose onClose={onClose}>
        {t('content.journal.dialog.title')}
      </DialogTitleWithClose>

      <DialogContent dividers>
        <Typography>{t('content.journal.dialog.content')}</Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.continue')}</Button>
      </DialogActions>
    </LoggedDialog>
  );
}
