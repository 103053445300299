import { Box, Button } from '@mui/material';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { useTranslation } from 'react-i18next';

export interface SurveyPopupButtonProp {
  onClick: () => void;
  surveyCompleted: boolean;
}
export default function SurveyPopupButton({
  onClick,
  surveyCompleted,
}: SurveyPopupButtonProp): JSX.Element | null {
  const { t } = useTranslation();

  if (surveyCompleted) {
    return null;
  }

  return (
    <Box className="survey-popover-box">
      <Button
        className="survey-popover"
        variant="contained"
        onClick={onClick}
        startIcon={<RateReviewIcon />}
      >
        {t('home.survey.label')}
      </Button>
    </Box>
  );
}
