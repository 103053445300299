import { assertLoggableConnection } from 'hive-analytics-react';
import { utils } from 'hive-client-utils';
function addSurvey(body, version) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('ecarepd_survey.addSurvey', body, version)
        .then(utils.waitForInvocationReaction(connection.bee, (r) => r.details.message));
}
function updateSurvey(surveyID, body, version) {
    const connection = assertLoggableConnection();
    return connection.bee.actions
        .invoke('ecarepd_survey.updateSurvey', surveyID, body, version)
        .then(utils.waitForInvocationReaction(connection.bee, (r) => r.details.message));
}
export const SurveyService = {
    addSurvey,
    updateSurvey,
};
