import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { ReactComponent as Sleep } from './legends/SleepPath.svg';
import { ReactComponent as Activity } from './legends/ActivityPath.svg';
import { useTranslation } from 'react-i18next';
const SHAPES_BY_WEARABLE_DATA = {
    activity: () => _jsx(Activity, {}),
    sleep: () => _jsx(Sleep, {}),
};
const NAME_BY_WEARABLE_DATA = {
    activity: 'content.patient_chart.activity',
    sleep: 'content.patient_chart.sleep',
};
export function WearableDataToggle({ wearableData, visible, toggle, }) {
    const { t } = useTranslation();
    const shapeEl = useMemo(() => SHAPES_BY_WEARABLE_DATA[wearableData](), [wearableData]);
    return (_jsxs(Stack, Object.assign({ direction: "row", spacing: 2, alignItems: "center", className: "priority-toggle", whiteSpace: "nowrap", paddingRight: 4 }, { children: [shapeEl, _jsx(Typography, Object.assign({ variant: "body2" }, { children: t(NAME_BY_WEARABLE_DATA[wearableData]) })), _jsx(Box, Object.assign({ width: 70 }, { children: _jsx(Button, Object.assign({ name: wearableData, color: "primary", variant: "text", onClick: toggle, fullWidth: true }, { children: visible
                        ? t('content.patient_chart.hide')
                        : t('content.patient_chart.show') })) }))] })));
}
