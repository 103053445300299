import { DialogContent, CircularProgress, Stack } from '@mui/material';
import { DialogTitleWithClose, useBlobById } from 'ecarepd-shared-utilities';
import { LoggedDialog } from 'hive-analytics-react';
import _ from 'lodash';
import {
  MouseEvent,
  PropsWithRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Document, Page } from 'react-pdf';
import { PDF_HEIGHT, PDF_WIDTH } from '../../theme/dialog';

// Needed for links (annotation) to work.
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// PDFDocumentProxy is from the `pdfjs-dist` dependency of `react-pdf`,
// We can load it and adding pdfjs-dist to this project is update trouble
// (They will NOT match at some point).
// Therefore, we have a simple interface just for the numPages we need
interface IPDFDocumentProxy {
  numPages: number;
}

export interface PdfDialogProps {
  open: boolean;
  onClose: () => void;
  blobId: string;
  title?: string;
}

export function PdfDialog({
  open,
  onClose,
  blobId,
  title,
}: PropsWithRef<PdfDialogProps>): JSX.Element {
  const blob = useBlobById(blobId);
  const [numPages, setNumPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const onLoadSuccess = useCallback((pdf: IPDFDocumentProxy) => {
    setIsLoading(false);
    setNumPages(pdf.numPages);
  }, []);

  useEffect(() => {
    if (!open) {
      setNumPages(0);
      setIsLoading(true);
    }
  }, [open]);

  const onClick = useCallback((event: MouseEvent<Element>) => {
    // We do NOT want to let the browser open the link, since it will be in the same window
    event.preventDefault();

    // Instead open in a new tab/window
    const href = _.get(event, 'target.href');
    if (href) {
      window.open(href, '_blank');
    }
  }, []);

  return (
    <LoggedDialog
      dialogName="pdf-dialog"
      extraData={{ title }}
      fullWidth
      open={open}
      onClose={onClose}
      className="patient-resources"
    >
      {title && (
        <DialogTitleWithClose onClose={onClose}>{title}</DialogTitleWithClose>
      )}

      <DialogContent>
        {isLoading && (
          <Stack margin={4} alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        <Document
          file={blob}
          onLoadSuccess={onLoadSuccess}
          onLoadError={console.log}
        >
          {_.times(numPages, (pageIndex: number) => (
            // The width MUST match that of maxWidth in dialog.ts '.patient-resources &'
            <Page
              width={PDF_WIDTH}
              height={PDF_HEIGHT}
              key={pageIndex}
              pageIndex={pageIndex}
              onGetAnnotationsSuccess={console.log}
              onClick={onClick}
            />
          ))}
        </Document>
      </DialogContent>
    </LoggedDialog>
  );
}
