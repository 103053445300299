import { Stack, Card, Typography, Button } from '@mui/material';
import { AddCircleOutline, InfoOutlined } from '@mui/icons-material';
import { PropsWithRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { COLOURS, SymptomPriority } from 'ecarepd-shared-utilities';
import _ from 'lodash';
import TabPanel from '../tutorial/TabPanel';
import { COMPONENTS } from './components';
import { ReactComponent as Other } from '../../assets/Care Priorities - Other.svg';

export interface SelectCarePrioritiesProps {
  value: number;
  selectPriority: (v: string) => void;
  selectedPriorities: string[];
  tooltipClick: (priorityID: string, v: string, n: string) => void;
  priorities: SymptomPriority[];
}

export default function SelectCarePriorities({
  value,
  selectPriority,
  selectedPriorities,
  priorities,
  tooltipClick,
}: PropsWithRef<SelectCarePrioritiesProps>): JSX.Element | null {
  const { t } = useTranslation();

  const categoryName = _.get(priorities, [
    0,
    'properties',
    'categoryName',
  ])?.replace(/ /g, '');

  const ImageComponent = COMPONENTS[categoryName] || Other;

  return (
    <TabPanel value={value} index={1}>
      <Stack
        className="select-priorities-title"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap={2}
      >
        <ImageComponent />
        <Typography variant="h4">
          {t(`content.care_priorities.${categoryName}`)}
        </Typography>
      </Stack>

      <Stack
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        padding={5}
      >
        {_.map(priorities, (priority) => {
          const isSelected = selectedPriorities.includes(priority.id);
          const className = isSelected ? 'selected-card' : '';
          return (
            <Card
              key={priority.id}
              onClick={() => selectPriority(priority.id)}
              className={className}
            >
              <Typography variant="h6" padding={2} color={COLOURS.DarkBlue}>
                <b>{priority.properties.name}</b>
              </Typography>
              <Button
                disableRipple={isSelected}
                onClick={(event: MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation();
                  if (isSelected) {
                    selectPriority(priority.id);
                  } else {
                    tooltipClick(
                      priority.id,
                      priority.properties.information,
                      priority.properties.name
                    );
                  }
                }}
              >
                {isSelected ? (
                  <AddCircleOutline />
                ) : (
                  <InfoOutlined color="primary" />
                )}
              </Button>
            </Card>
          );
        })}
      </Stack>
    </TabPanel>
  );
}
